import React from "react";
import {Outlet} from "react-router-dom";


export interface AddLayoutProps {

}

const AddLayout = (props: AddLayoutProps) => {
    return (
        <div className="add-layout">
            <div className="add-layout-content-holder">
                <Outlet/>
            </div>
        </div>
    );
};


export default AddLayout;




