import {useNavigate, useParams} from "react-router-dom";
import "./InternalHcpDetailsComponent.scss";
import React, {useCallback, useEffect, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
// import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../constants";
// import "./HcpDetailsComponent.scss";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditInternalHcpBasicDetailsComponent
    from "./edit-internal-hcp-basic-details/EditInternalHcpBasicDetailsComponent";
import DetailsHeaderComponent from "../../../shared/components/details-header-component/DetailsHeaderComponent";
import {INTERNAL_HCP_MANAGEMENT} from "../../../constants/RoutesConfig";
import HcpDocumentTabComponent from "./hcp-document-tab/HcpDocumentTabComponent";

const InternalHcpDetailsComponent = () => {
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const {internalHcpId} = useParams();
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [isHcpDetailsLoadingFailed, setIsHcpDetailsLoadingFailed] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<any>("basic-details");
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>([]);
    const navigate = useNavigate();


    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (internalHcpId) {
            CommonService._hcp.HcpDetailsAPICall(internalHcpId, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [internalHcpId]);


    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (internalHcpId) {
            CommonService._hcp.HcpDocumentAPICall(internalHcpId, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [internalHcpId]);

    useEffect((): void => {
        getHcpDocuments();
    }, [getHcpDocuments]);


    // const handleRedirect = () => {
    //     // Open the URL in a new browser window/tab
    //     window.open(`${hcpDetails?.location_url}`, '_blank');
    // };

    // const downloadHcpDocument = useCallback((hcpDocumentFileKey: string) => {
    //     const payload: any = {};
    //     payload.file_key = hcpDocumentFileKey;
    //     CommonService._hcp.DownloadHcpDocumentAPICall(payload)
    //         .then((response: IAPIResponseType<any>) => {
    //             const link = document.createElement('a');
    //             link?.setAttribute('href', response?.data?.data);
    //             document.body.appendChild(link);
    //             link.click();
    //             CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
    //         }).catch((error: any) => {
    //         CommonService._alert.showToast(error?.error || "Failed To download Document", "error");
    //     });
    // }, []);


    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    // const getHcpDocuments = useCallback(() => {
    //     const payload = {};
    //     if (hcp_id) {
    //         CommonService._hcp.HcpDocumentAPICall(hcp_id, payload)
    //             .then((response: IAPIResponseType<any>) => {
    //                 setHcpDocumentsList(response?.data);
    //             }).catch((error: any) => {
    //         }).finally(() => {
    //         });
    //     }
    // }, [hcp_id]);
    //
    // useEffect((): void => {
    //     getHcpDocuments();
    // }, [getHcpDocuments]);

    const downloadHcpDocument = useCallback((hcpDocumentFileKey: string) => {
        const payload: any = {};
        payload.file_key = hcpDocumentFileKey;
        CommonService._hcp.DownloadHcpDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                const link = document.createElement('a');
                link?.setAttribute('href', response?.data?.data);
                document.body.appendChild(link);
                link.click();
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To download Document", "error");
        });
    }, []);

    const deleteHcpDocument = useCallback((id: any, fileType: any) => {
        const payload: any = {};
        // fileType && (payload.file_type = fileType);
        // payload.file_key = hcpDocumentFileKey;
        CommonService._hcp.DeleteHcpDocumentAPICall(internalHcpId, id, payload)
            .then((response: IAPIResponseType<any>) => {
                setFilterData({...filterData, page: 1});
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getHcpDetails();
                getHcpDocuments();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To delete Document", "error");

        });
    }, [filterData, getHcpDetails, getHcpDocuments, internalHcpId]);

    const onHandleDeleteDocument = useCallback((document: string, id?: string) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete ${document}`,
                image: ImageConfig.DeleteIcon,
                confirmationDescription: `Are you sure you want to delete the
    ${document}?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: true,
                yes: {
                    color: "error",
                    text: "Yes, Delete",
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            if (id) {
                deleteHcpDocument(id,"document");
            } else {
                deleteHcpDocument(hcpDetails?.contract?.key, "contract");
            }
        });
    }, [hcpDetails, deleteHcpDocument]);


    return (
        <>
            <DrawerComponent isOpen={isEditDrawerOpen} onClose={closeEditDrawer}>
                <EditInternalHcpBasicDetailsComponent hcpDetails={hcpDetails} closeEditDrawer={closeEditDrawer}
                                                      getFacilityDetails={getHcpDetails}/>
            </DrawerComponent>
            <div className={'internal-hcp-details-component-screen'}>
                {
                    isHcpDetailsLoading && <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                }
                {
                    isHcpDetailsLoadingFailed &&
                    <div className="h-v-center">
                        <StatusCardComponent title={'Failed to Load'}/>
                    </div>
                }
                {
                    isHcpDetailsLoaded &&
                    <>
                        <DetailsHeaderComponent navigate={navigate} details={hcpDetails}
                                                backAddress={INTERNAL_HCP_MANAGEMENT}
                                                detailsModule={'Internal-hcp'}/>
                        <TabsWrapperComponent className={'mrg-top-25'}>
                            <TabsComponent
                                value={activeTab}
                                allowScrollButtonsMobile={false}
                                onUpdate={(e: any, v: any) => {
                                    setActiveTab(v);
                                }}
                            >
                                <TabComponent label="Internal Staff Details" value={"basic-details"}/>
                                <TabComponent label="Documents" value={"hcp-document"}/>
                            </TabsComponent>
                            <TabContentComponent selectedTab={activeTab} value={"basic-details"}>
                                <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100 mrg-top-25"}>
                                    <div className={'details-screen'}>
                                        <div className="details-screen-title-with-image-wrapper pdd-10">
                                            <div
                                                className={'details-screen-title-wrapper center-right-element pdd-right-30 pdd-left-30'}>
                                                <div className={'details-screen-title'}>InternalHCP
                                                    Details
                                                </div>
                                                <div onClick={openEditDrawer} className={'icon-button'}>
                                                <span
                                                    className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                    className={'mrg-right-10'}/>Edit Internal Staff Details</span>
                                                </div>
                                            </div>
                                        </div>
                                        <HorizontalLineComponent/>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}
                                                     style={{
                                                         display: "flex",
                                                         justifyContent: "flex-start",
                                                         columnGap: "14px",
                                                         marginBottom: "16px",
                                                         alignItems: "center"
                                                     }}>
                                                    <div style={{
                                                        height: "56px",
                                                        width: "56px",
                                                        borderRadius: "50px",
                                                    }}>
                                                        {hcpDocumentsList && Array.isArray(hcpDocumentsList) && hcpDocumentsList.length > 0 ? (
                                                                (() => {
                                                                    const profilePictures = hcpDocumentsList.filter((attachment) => attachment.attachment_type === "profile_picture");

                                                                    return profilePictures.length > 0 ? (
                                                                        profilePictures.map((profilePic, index) => (
                                                                            <img
                                                                                key={index}
                                                                                src={profilePic.url}
                                                                                alt="profile pic"
                                                                                style={{
                                                                                    height: "56px",
                                                                                    width: "56px",
                                                                                    borderRadius: "50px",
                                                                                }}
                                                                            />
                                                                        ))
                                                                    ) : (
                                                                        hcpDetails?.gender === "male" ? (
                                                                            <ImageConfig.MaleIcon height={56} width={56}/>
                                                                        ) : (
                                                                            <ImageConfig.FemaleIcon height={56} width={56}/>
                                                                        ));
                                                                })()
                                                            ) :
                                                            (
                                                                hcpDetails?.gender === "male" ? (
                                                                    <ImageConfig.MaleIcon height={56} width={56}/>
                                                                ) : (
                                                                    <ImageConfig.FemaleIcon height={56} width={56}/>
                                                                ))
                                                        }


                                                    </div>

                                                    <div>
                                                        <div className={'HCPName'}>
                                                            {hcpDetails?.first_name + " " + hcpDetails?.last_name || '-'}
                                                        </div>
                                                        <div className={'HCPType'}>
                                                            {hcpDetails?.hcp_type_details?.title || '-'}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <HorizontalLineComponent className={'mrg-bottom-20'}/>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Phone Number'}>
                                                        {hcpDetails?.contact_number || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Email ID'}>
                                                        {hcpDetails?.email || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Gender'}>
                                                        {hcpDetails?.gender || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Address'}>
                                                        {`${hcpDetails?.address?.street}, ${hcpDetails?.address?.city}, ${hcpDetails?.address?.state}, ${hcpDetails?.address?.zip_code}, ${hcpDetails?.address?.region}, ${hcpDetails?.address?.country}` || '-'}
                                                    </DataLabelValueComponent>
                                                </div>


                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Timezone'}>
                                                        {CommonService.getTimezoneTitleByCode(hcpDetails?.timezone) || '-'}
                                                    </DataLabelValueComponent>
                                                </div>

                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Region'}>
                                                        {
                                                            hcpDetails?.address?.region
                                                        }
                                                    </DataLabelValueComponent>
                                                </div>

                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Staff ID'}>
                                                        {
                                                            hcpDetails?.hcp_uniqueId || "-"
                                                        }
                                                    </DataLabelValueComponent>
                                                </div>

                                                {/*<div className={'ts-col-lg-3'}>*/}
                                                {/*    <DataLabelValueComponent label={'Pool'}>*/}
                                                {/*        {*/}
                                                {/*            hcpDetails?.pool || '-'*/}
                                                {/*        }*/}
                                                {/*    </DataLabelValueComponent>*/}
                                                {/*</div>*/}

                                                {/*<div className={'ts-col-lg-3'}>*/}
                                                {/*    <DataLabelValueComponent label={'Maps Location Link'}>*/}
                                                {/*        <div onClick={handleRedirect}*/}
                                                {/*             className={'underline-text'}>*/}
                                                {/*            View Location*/}
                                                {/*        </div>*/}
                                                {/*    </DataLabelValueComponent>*/}
                                                {/*</div>*/}
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-6'}>
                                                    <DataLabelValueComponent label={'About the Internal Staff'}>
                                                        {hcpDetails?.about || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </CardComponent>
                                {/*{hcpDetails?.contract &&*/}
                                {/*    <CardComponent size={"sm"} className={"mrg-left-100 mrg-right-100"}>*/}
                                {/*        <div className={'details-screen'}>*/}
                                {/*            <div className="details-screen-title-with-image-wrapper pdd-10">*/}
                                {/*                <div className={'details-screen-title-wrapper center-right-element'}>*/}
                                {/*                    <div className={'details-screen-title'}>Contract</div>*/}
                                {/*                    /!*<div onClick={openEditDrawer} className={'icon-button'}>*!/*/}
                                {/*                    /!*<span*!/*/}
                                {/*                    /!*    className={'center-element button-font-text'}><ImageConfig.EditIcon*!/*/}
                                {/*                    /!*    className={'mrg-right-10'}/>Edit HCP Details</span>*!/*/}
                                {/*                    /!*</div>*!/*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*            <HorizontalLineComponent/>*/}
                                {/*            <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>*/}
                                {/*                <div className={'ts-row'}>*/}
                                {/*                    <div className={'ts-col-lg-6'}>*/}
                                {/*                        <DataLabelValueComponent*/}
                                {/*                            label={'Contract Signed on'}>*/}
                                {/*                            {CommonService.convertDateFormat2(*/}
                                {/*                                hcpDetails?.contract?.contract_signed_on,*/}
                                {/*                                'MM-DD-YYYY'*/}
                                {/*                            )}*/}
                                {/*                        </DataLabelValueComponent>*/}
                                {/*                    </div>*/}
                                {/*                    <div className={'ts-col-lg-6'}>*/}
                                {/*                        <DataLabelValueComponent*/}
                                {/*                            label={'Contract'}>*/}
                                {/*                            <div className={'action-button-trigger-wrapper d-flex'}>*/}
                                {/*                                <div*/}
                                {/*                                    className={'center-element contract-action-text icon-button'}*/}
                                {/*                                    onClick={() => {*/}
                                {/*                                        CommonService.previewPDF(hcpDetails?.contract?.url);*/}
                                {/*                                    }}>*/}
                                {/*                                    <ImageConfig.ViewContractIcon/><span*/}
                                {/*                                    className={'mrg-left-10'}>View</span></div>*/}
                                {/*                                {hcpDetails?.contract?.key && <div*/}
                                {/*                                    className={'center-element mrg-left-25 contract-action-text icon-button'}*/}
                                {/*                                    onClick={() => {*/}
                                {/*                                        downloadHcpDocument(hcpDetails?.contract?.key);*/}
                                {/*                                    }}>*/}
                                {/*                                    <ImageConfig.DownloadIconPreview/><span*/}
                                {/*                                    className={'mrg-left-10'}>Download</span>*/}
                                {/*                                </div>}*/}
                                {/*                            </div>*/}
                                {/*                        </DataLabelValueComponent>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </CardComponent>*/}
                                {/*}*/}
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"hcp-document"}>
                                <div
                                    className={' mrg-top-25 mrg-left-100 mrg-right-100'}>
                                    <HcpDocumentTabComponent
                                        onDownload={downloadHcpDocument}
                                        onDelete={onHandleDeleteDocument}
                                        documentList={hcpDocumentsList}
                                        getDocumentLists={getHcpDocuments}
                                    />
                                </div>
                            </TabContentComponent>
                        </TabsWrapperComponent>
                    </>
                }

            </div>

        </>
    );
};
export default InternalHcpDetailsComponent;
