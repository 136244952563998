import "./AddHcpComponent.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import {CommonService} from "../../../shared/services";
import AddHcpDetailsComponent from "./add-hcp-details/AddHcpDetailsComponent";


const AddHcpComponent = () => {
    const navigate = useNavigate();
    const [formTab, setFormTab] = useState<any>("Hcp");
    const location: any = useLocation();
    const path = location.pathname;


    const handleBack = useCallback(() => {
        if (path.includes('/hcp/add')) {
            navigate(CommonService._routeConfig.HcpManagementRoute());
        }
    }, [navigate, path]);


    return (
        <>
            <div className={'add-hcp-component'}>
                <>
                    <AddHcpDetailsComponent
                        prev={handleBack}
                        formTab={formTab}
                        setFormTab={setFormTab}
                    />
                </>
            </div>
        </>
    );
};
export default AddHcpComponent;
