import "./HcpWorkExperienceStepComponent.scss";
import React, {useEffect, useRef} from "react";
import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import AccordionComponent from "../../../../../shared/components/accordion/AccordionComponent";
import {ImageConfig} from "../../../../../constants";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikRadioButtonGroupComponent
    from "../../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent";
import LabelComponent from "../../../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";


interface HcpWorkExperienceStepComponentProps {
    onSubmit: (values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => void;
    setFormTab: any;
    setHcpAddActiveStep: any;
}

interface workExperienceFormInitialValuesFormSchema {
    facility_name: string,
    position: string,
    location: string,
    still_working: boolean,
    start_date: string,
    end_date: string
}

const workExperienceValidationObjSchema = Yup.object({
    facility_name: Yup.string().required("Facility Name is required"),
    position: Yup.string().required("Position is required"),
    location: Yup.string().required("Location is required"),
    still_working: Yup.boolean(),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().when('still_working', {
        is: false,
        then: Yup.date().required("End Date is required").test('is-greater', 'End Date must be greater than Start Date', function (end_date) {
            const {start_date} = this.parent; // Access the value of start_date

            // Perform the comparison
            if (start_date && end_date) {
                return new Date(end_date) > new Date(start_date);
            }

            // If either value is missing, assume validation passes
            return true;
        }),
        // otherwise: Yup.mixed(),
    }),
});
const workExperienceFormInitialValues: workExperienceFormInitialValuesFormSchema = {
    facility_name: "",
    position: "",
    location: "",
    still_working: false,
    start_date: "",
    end_date: ""
};
const workExperienceValidationSchema: any = Yup.object({
    work: Yup.array().of(workExperienceValidationObjSchema).required("Work Experience is required"),
});

const workExperienceFormArrayInitialValues: any = {
    work: [workExperienceFormInitialValues]
};

const HcpWorkExperienceStepComponent = (props: HcpWorkExperienceStepComponentProps) => {
    const {
        onSubmit,
        setFormTab,
        setHcpAddActiveStep
        // onPrevStep,
    } = props;
    const addWorkRef: any = useRef(null);
    return (

            <Formik
                validationSchema={workExperienceValidationSchema}
                initialValues={workExperienceFormArrayInitialValues}
                validateOnChange={false}
                validateOnBlur={true}
                enableReinitialize={true}
                validateOnMount={true}
                onSubmit={onSubmit}
            >
                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    useEffect(() => {
                        validateForm();
                    }, [validateForm, values]);
                    return (
                        <Form className="t-form scroll-content" noValidate={true}>
                            <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                            <div className="form-header-body-wrapper">
                                <div className="add-component-header-form d-flex">
                                    <div
                                        className={'form-top-header-text justify-content-space-between width-100 align-items-center'}>Work
                                        Experience
                                    </div>
                                        <ButtonComponent
                                            onClick={() => {
                                                if (addWorkRef.current) {
                                                    addWorkRef.current.unshift(workExperienceFormInitialValues);
                                                }
                                            }}
                                        >
                                            Add Experience
                                        </ButtonComponent>
                                </div>
                                <>
                                    <FieldArray
                                        name={"work"}
                                        render={(arrayHelpers) => {
                                            addWorkRef.current = arrayHelpers;
                                            return (
                                                <>
                                                    <div className="add-component-content">
                                                        {values.work.map((work: any, index: any) => (
                                                            <AccordionComponent
                                                                className={"accordion-action-button"}
                                                                title={""}
                                                                actions={<div className="action-delete"
                                                                              onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <ImageConfig.DeleteIcon color={"red"}/>
                                                                </div>}
                                                            >
                                                                <>
                                                                    <Field name={`work[${index}].facility_name`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Facility Name'}
                                                                                    placeholder={'Enter Facility Name'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <div className="ts-row">
                                                                        <div className="ts-col-6">
                                                                            <Field name={`work[${index}].location`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            label={'Location'}
                                                                                            placeholder={'Enter Location'}
                                                                                            type={"text"}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-6">
                                                                            <Field name={`work[${index}].position`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            label={'Position'}
                                                                                            placeholder={'Enter Position'}
                                                                                            type={"text"}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ts-row">
                                                                        <div className="ts-col-6">
                                                                            <Field
                                                                                name={`work[${index}].still_working`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikRadioButtonGroupComponent
                                                                                            formikField={field}
                                                                                            label={"Still Working"}
                                                                                            required={true}
                                                                                            displayWith={(option: any) => option.label}
                                                                                            options={[
                                                                                                {
                                                                                                    label: "Yes",
                                                                                                    value: true,
                                                                                                },
                                                                                                {
                                                                                                    label: "No",
                                                                                                    value: false,
                                                                                                },
                                                                                            ]}
                                                                                            valueExtractor={(option: any) => option.value}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-6"></div>
                                                                    </div>
                                                                    <div className="ts-row">
                                                                        <div className="ts-col-6">
                                                                            <LabelComponent title={"Start Date*"}/>
                                                                            <Field name={`work[${index}].start_date`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikDatePickerComponent
                                                                                            // minDate={new Date()}
                                                                                            placeholder={'Select Date'}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-6">
                                                                            {!values.work[index].still_working && (
                                                                                <>
                                                                                    <LabelComponent title={"End Date*"}/>
                                                                                    <Field
                                                                                        name={`work[${index}].end_date`}>
                                                                                        {
                                                                                            (field: FieldProps) => (
                                                                                                <FormikDatePickerComponent
                                                                                                    // minDate={new Date()}
                                                                                                    placeholder={'Select Date'}
                                                                                                    required={true}
                                                                                                    formikField={field}
                                                                                                    fullWidth={true}
                                                                                                />
                                                                                            )
                                                                                        }
                                                                                    </Field>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </AccordionComponent>
                                                        ))}
                                                    </div>
                                                </>
                                            );
                                        }}
                                    />
                                </>
                            </div>
                            </div>
                            <div className={'add-hcp-form-action-element'}>
                                <ButtonComponent
                                    onClick={() => {
                                        setFormTab('Documents-upload');
                                        setHcpAddActiveStep(4);
                                    }}
                                    className={"skip-button mrg-left-10"}
                                >
                                    Skip
                                </ButtonComponent>
                                {
                                    <ButtonComponent
                                        className={"next-button"}
                                        type={"submit"}
                                    >
                                        Next
                                    </ButtonComponent>
                                }

                            </div>
                        </Form>
                    );
                }}
            </Formik>
    );

};

export default HcpWorkExperienceStepComponent;
