import "./HcpManagementComponent.scss";
import SearchComponent from "../../../shared/components/search/SearchComponent";
import LinkComponent from "../../../shared/components/link/LinkComponent";
import {CommonService} from "../../../shared/services";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {APIConfig, ImageConfig, Misc} from "../../../constants";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ITableColumn} from "../../../shared/models/table.model";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {setCurrentNavParams} from "../../../store/actions/navigation.action";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
// import {Checkbox, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {IAPIResponseType} from "../../../shared/models/api.model";
import TextWithIconComponent from "../../../shared/components/text-with-icon/TextWithIconComponent";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import FormControl from "@mui/material/FormControl";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";

interface HcpManagementListComponentProps {

}

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };


const HcpManagementListComponent = (props: HcpManagementListComponentProps) => {
    const dispatch = useDispatch();
    const [filterData, setFilterData] = useState<any>({
        search: '',
        is_internal_hcp: false,
        is_float: false,
        // sort:{},
    });
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const {HcpTypesList} = useSelector((state: IRootReducerState) => state.meta);


    const onHCPActivateDeactivate = useCallback((item: any) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `${item?.is_active ? 'Deactivate' : 'Activate'}  HCP`,
                image: item?.is_active ? ImageConfig.UserDeactivateIcon : ImageConfig.UserActivateIcon,
                confirmationDescription: `Are you sure you want to ${item?.is_active ? 'deactivate' : 'activate'}  the
    HCP?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: item?.is_active,
                yes: {
                    color: "error",
                    text: `Yes, ${item?.is_active ? 'Deactivate' : 'Activate'}`,
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            const payload: any = {};
            payload.is_active = !item?.is_active;
            CommonService._hcp.ActivateDeactivateHCP(item?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                    setFilterData({
                        ...filterData
                    });
                }).catch((error: any) => {
                CommonService._alert.showToast(error?.error || "Error", "error");
            });
        });
    }, [filterData]);

    const HCPListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.AgencySidebarIcon/>}>Agency
                    Name</TextWithIconComponent>,
                key: 'agency_name',
                dataIndex: 'name',
                fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.agency_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.agency_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.agency_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.agency_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.HcpNameIcon/>}>Staff
                    Name</TextWithIconComponent>,
                key: 'hcp_name',
                dataIndex: 'hcp_name',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.first_name + " " + item?.last_name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.first_name + " " + item?.last_name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.first_name + " " + item?.last_name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent>Staff
                    ID</TextWithIconComponent>,
                key: 'hcp_uniqueId',
                dataIndex: 'hcp_uniqueId',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.hcp_uniqueId)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.hcp_uniqueId}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.hcp_uniqueId}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.hcp_uniqueId || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.EmailIcon/>}>Email
                    ID</TextWithIconComponent>,
                key: 'email_id',
                dataIndex: 'email_id_name',
                // fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.email)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.email}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.email}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.email || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.SchedulerSidebarIcon/>}>Onboarded
                    On</TextWithIconComponent>,
                key: 'onboarded_on',
                dataIndex: 'onboarded_on',
                width: 137,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    if (item?.created_at) {
                        return <>{CommonService.convertDateFormat2(item?.created_at, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.HcpType/>}>Staff Type</TextWithIconComponent>,
                key: 'hcp-type',
                dataIndex: 'hcp-type',
                render: (item: any) => {
                    return <>
                        {item?.hcp_type_details?.title
                            ? item?.hcp_type_details?.title
                            : "-"}
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.PhoneIcon/>}>Phone
                    Number</TextWithIconComponent>,
                key: 'phone-number',
                dataIndex: 'phone_number',
                width: 180,
                render: (item: any) => {
                    return <>
                        {item?.contact_number}
                    </>;
                }
            },
            {
                title: () => <TextWithIconComponent
                    prefix={<ImageConfig.LocationIcon/>}>Location</TextWithIconComponent>,
                key: 'location',
                dataIndex: 'location',
                width: 180,
                render: (item: any) => {
                    return <>
                        {item?.address?.city}
                    </>;
                }
            },
            {
                title: 'Activate/Deactivate',
                key: 'activate-deactivate',
                dataIndex: 'activate',
                align: 'center',
                width: 150,
                render: (item: any) => {
                    return <>
                        <CustomToggleComponent defaultOn={item?.is_active} toggleHandler={() => {
                            onHCPActivateDeactivate(item);
                        }}/>
                    </>;
                }
            },
            {
                title: "View Details",
                dataIndex: "actions",
                align: 'center',
                key: "actions",
                width: 100,
                fixed: 'right',
                render: (item: any) => {
                    if (item?._id) {
                        return <div>
                            <LinkComponent route={CommonService._routeConfig.hcpViewDetailsRoute(item?._id)}>
                                <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                            </LinkComponent>
                        </div>;
                    }
                }
            }
        ];
    }, [onHCPActivateDeactivate]);
    const [selectedHcpTypeData, setSelectedHcpTypeData] = React.useState<Array<string>>([]);

    useEffect(() => {
        dispatch(setCurrentNavParams('HCP') as any);
    }, [dispatch]);

    const handleHcpTypeSelection = (selectedOptions:any) => {
            // @ts-ignore
            setSelectedHcpTypeData(selectedOptions);
    };

    useEffect(() => {
        const hcpTypeIdsArray = selectedHcpTypeData.map((item: any) => item?.code);
        if (selectedHcpTypeData?.length > 0) {
            setFilterData({
                ...filterData,
                hcp_types: hcpTypeIdsArray,
            });
        } else {
            delete filterData?.hcp_types;
            setFilterData({
                ...filterData
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHcpTypeData]);


    const handleDownloadHCPs = useCallback(() => {
        setIsDownloading(true);
        const payload: any = {};
        const {status} = filterData;
        payload.status = [status];
        let apiCall: any = {};
        apiCall = CommonService._hcp.downloadHCPDataCSVAPICall(filterData);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                const link = document.createElement("a");
                link?.setAttribute("href", response?.data);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setIsDownloading(false);
            }

        })
            .catch((error: any) => {
                setIsDownloading(false);
            }).finally(() => {

        });
    }, [filterData]);

    return (
        <>
            <div className={'facility-list-component header-screen mrg-top-25 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-header-wrapper">
                    <div className="list-screen-header">
                        {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                        <div className="list-screen-header-title-and-count d-flex">
                            <div className={'mrg-right-10'}>
                                <ImageConfig.HcpModuleHeaderIcon/>
                            </div>
                            <div className="list-screen-header-title">Staff</div>
                        </div>
                    </div>
                    <div className="list-options">
                        <div className={"mrg-top-20"}>
                            <SearchComponent
                                size={'medium'}
                                placeholder="Search Staff Name"
                                value={filterData.search}
                                onSearchChange={(value) => {
                                    setFilterData({...filterData, search: value, page: 1});
                                }}
                            />
                        </div>
                        <FormControl sx={{width:260}}>
                            <SearchCheckboxComponent
                                options={HcpTypesList}
                                placeholder={"Select Staff Type"}
                                selectedOptions={selectedHcpTypeData}
                                noDataText={"No Staff Type"}
                                onChange={handleHcpTypeSelection}
                            />
                        </FormControl>
                        <div>
                            <ButtonComponent color={'download-button'} variant={"contained"} isLoading={isDownloading}
                                             onClick={handleDownloadHCPs}>
                                Download CSV
                            </ButtonComponent>
                        </div>
                        <LinkComponent route={CommonService._routeConfig.AddHcpRoute()}>
                            <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>
                                Add New Staff
                            </ButtonComponent>
                        </LinkComponent>

                    </div>
                </div>
            </div>
            <div className={'facility-list-component list-screen mrg-top-10 mrg-left-25 mrg-right-30'}>
                <div className="list-screen-content">
                    <TableWrapperComponent url={APIConfig.HCP_MANAGEMENT_LIST.URL}
                                           method={APIConfig.HCP_MANAGEMENT_LIST.METHOD}
                                           isPaginated={true}
                                           extraPayload={filterData}
                                           noDataText={filterData.search ? 'Oops! It seems like there are no Staff available for the name you have searched.' :
                                               filterData.hcp_types ? "Oops! It seems like there are no Staff available for the selected staff types.":
                                               'No Staffs Data Found.'}
                                           columns={HCPListColumn}
                    />
                </div>
            </div>
        </>
    );
};

export default HcpManagementListComponent;
