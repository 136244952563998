import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const LoginAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.LOGIN.METHOD](APIConfig.LOGIN.URL, payload);
};

const ResumeSessionAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.VERIFY_PASSWORD.METHOD](APIConfig.VERIFY_PASSWORD.URL, payload);
};

const CheckLoginAPICall = (token: string) => {
    // @ts-ignore
    return ApiService[APIConfig.CHECK_LOGIN.METHOD](APIConfig.CHECK_LOGIN.URL, {}, {Authorization: "Bearer " + token});
};

const LogoutAPICall = () => {
    // @ts-ignore
    return ApiService[APIConfig.LOGOUT.METHOD](APIConfig.LOGOUT.URL);
};

const forgotPasswordAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.FORGOT_PASSWORD.METHOD](APIConfig.FORGOT_PASSWORD.URL, payload);
};

const sendOtpApiCall=(payload:any)=>{
    return ApiService[APIConfig.SEND_OTP.METHOD](APIConfig.SEND_OTP.URL, payload);
}

const verifyOTPAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.VERIFY_OTP.METHOD](APIConfig.VERIFY_OTP.URL, payload);
};

const setNewPasswordAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SET_NEW_PASSWORD.METHOD](APIConfig.SET_NEW_PASSWORD.URL, payload);
}

const AccountService = {
    LoginAPICall,
    CheckLoginAPICall,
    LogoutAPICall,
    ResumeSessionAPICall,
    forgotPasswordAPICall,
    verifyOTPAPICall,
    setNewPasswordAPICall,
    sendOtpApiCall
};

export default AccountService;
