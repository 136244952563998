import "./AddFacilityComponent.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import {CommonService} from "../../../shared/services";
import FacilityDetailsComponent from "./facility-details/FacilityDetailsComponent";


interface AddFacilityComponentProps {

}

const AddFacilityComponent = (props: AddFacilityComponentProps) => {

    const navigate = useNavigate();
    const [formTab, setFormTab] = useState<any>("Facility");
    const location: any = useLocation();
    const path = location.pathname;


    const handleBack = useCallback(() => {
        if (path.includes('/facility/add')) {
            navigate(CommonService._routeConfig.FacilityRoute());
        }
    }, [navigate, path]);


    return (
        <div className={'add-facility-component'}>
            <>
                <FacilityDetailsComponent
                    prev={handleBack}
                    formTab={formTab}
                    setFormTab={setFormTab}
                />
            </>
        </div>
    );
};

export default AddFacilityComponent;
