import "./BulkUploadFileComponent.scss"
import {ImageConfig, Misc} from "../../../../../constants";
import HorizontalLineComponent
    from "../../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import FilePickerComponent from "../../../../../shared/components/file-picker/FilePickerComponent";
import _ from "lodash";
import ErrorComponent from "../../../../../shared/components/error/ErrorComponent";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import FormikSelectDropdownComponent
    from "../../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../../store/reducers";
import RadioButtonGroupComponent
    from "../../../../../shared/components/form-controls/radio-button-group/RadioButtonGroupComponent";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import * as Yup from "yup";

interface BulkUploadFileComponentProps {
    setBulkUploadDialog?: any
    setFilterData?: any
    filterData?: any
}

interface contractFormInitialValuesFormSchema {
    file: any;
    facility_id: any;
}

const contractFormInitialValues: contractFormInitialValuesFormSchema = {
    file: "",
    facility_id: ""
};

const BulkUploadShiftFormValidationSchema = Yup.object({
    facility_id: Yup.string().required("Facility Selection is required")
});


const BulkUploadFileComponent = ((props: BulkUploadFileComponentProps) => {
    const {setBulkUploadDialog, setFilterData, filterData} = props;
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const {facilityList} = useSelector((state: IRootReducerState) => state.staticData);
    const [selectedShiftRequirementType, setSelectedShiftRequirementType] =
        useState<any>('open-shift');

    const onFileUpload = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const formData = CommonService.getFormDataFromJSON(values);
        let apiCall: any = {};
        if (selectedShiftRequirementType === "open-shift") {
            apiCall = CommonService._shift_management.bulkUploadShiftRequirementAPICallOpenShift(formData);
        } else if (selectedShiftRequirementType === "home-health-hospice") {
            apiCall = CommonService._shift_management.bulkUploadShiftRequirementAPICallHomeShift(formData);
        } else if (selectedShiftRequirementType === "contract-travel") {
            apiCall = CommonService._shift_management.bulkUploadShiftRequirementAPICallContractTravelShift(formData);
        }
        apiCall.then((response: IAPIResponseType<any>) => {
            if (response?.data) {
                setSubmitting(false);
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                setBulkUploadDialog(false)
                setFilterData({...filterData});
            } else {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "error");
                setBulkUploadDialog(false)

            }
        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error, "error");
                setSubmitting(false);
            }).finally(() => {

        });
    }, [setBulkUploadDialog, selectedShiftRequirementType, filterData, setFilterData]);

    return (
        <>
            <div className={'bulk-upload-file-component'}>
                <div className={'header-bulk-upload d-flex justify-content-space-between align-items-center'}>
                    <div className={'bulk-upload-shift-text'}>
                        Bulk Upload Shift
                    </div>
                    <div className={'cursor-pointer'} onClick={() => {
                        setBulkUploadDialog(false)
                    }}>
                        <img alt={'cancel-bulk-icon'} height={25} width={25} src={ImageConfig.CancelIcon}/>
                    </div>
                </div>
                <HorizontalLineComponent/>
                <div className={'bulk-upload-file-content pdd-top-20 pdd-right-30 pdd-left-30 pdd-bottom-20'}>
                    <div className="edit-facility-form-wrapper">
                        <Formik
                            validationSchema={BulkUploadShiftFormValidationSchema}
                            initialValues={contractFormInitialValues}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={onFileUpload}
                        >
                            {({
                                  values,
                                  errors,
                                  validateForm,
                                  touched,
                                  isValid,
                                  setFieldValue,
                                  isSubmitting
                              }) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                    if (values.file) {
                                        setIsFileSelected(true);
                                    } else {
                                        setIsFileSelected(false);
                                    }
                                }, [validateForm, values]);
                                return (
                                    <Form className={"t-form  form-holder scroll-content"} noValidate={true}>
                                        <div className={'add-form-holder'}>
                                            <div className={'ts-row'}>
                                                <div className="ts-col-12">
                                                    <Field name={'facility_id'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikSelectDropdownComponent
                                                                    options={facilityList}
                                                                    displayWith={(option: any) => option.title}
                                                                    valueExtractor={(option: any) => option.code}
                                                                    label={'Facility*'}
                                                                    placeholder={'Select'}
                                                                    formikField={field}
                                                                    searchable={true}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={'mrg-top-5'}>
                                                <RadioButtonGroupComponent
                                                    disabled={isFileSelected}
                                                    options={[
                                                        {
                                                            title: 'Per Diem',
                                                            code: 'open-shift',
                                                        },
                                                        {title: 'Per Job/Visit', code: 'home-health-hospice'},
                                                        {title: 'Contract / Travel', code: 'contract-travel'},
                                                    ]}
                                                    valueExtractor={(option: any) => option.code}
                                                    onChange={(e) => {
                                                        setSelectedShiftRequirementType(e);
                                                    }}
                                                    value={selectedShiftRequirementType}
                                                />
                                            </div>
                                            {values?.facility_id &&
                                                <div className={'add-form-input-container'}>
                                                    <div className="edit-component-content">
                                                        {
                                                            (!values.file) && <>
                                                                <FilePickerComponent maxFileCount={1}
                                                                                     height={"230px"}
                                                                                     id={"sc_upload_btn"}
                                                                                     onFilesDrop={(acceptedFiles, rejectedFiles) => {
                                                                                         if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                             const file = acceptedFiles[0];
                                                                                             setFieldValue('file', file);
                                                                                         }
                                                                                     }}
                                                                                     acceptedFileTypes={["xlsx"]}
                                                                                     acceptedFilesText={"Only .xlsx files are supported"}
                                                                />
                                                                {
                                                                    (_.get(touched, "file") && !!(_.get(errors, "file"))) &&
                                                                    <ErrorComponent
                                                                        errorText={(_.get(errors, "file"))}/>
                                                                }
                                                            </>
                                                        }
                                                        <>
                                                            {
                                                                (values.file) && <>
                                                                    <div className="file-preview-wrapper">
                                                                        <div
                                                                            className="file-name d-flex ts-justify-content-center ts-align-items-center">
                                                                            <div className={'mrg-right-10'}>
                                                                                <ImageConfig.BulkExcelIcon/>
                                                                            </div>
                                                                            <div>
                                                                                {values.file.name}
                                                                            </div>
                                                                        </div>
                                                                        <div className="action-button">
                                                                            <div
                                                                                className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                                onClick={() => {
                                                                                    setFieldValue('file', '');
                                                                                }}
                                                                            >
                                                                                <ImageConfig.DeleteOutlineIcon/><span
                                                                                className={'mrg-left-10'}></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/*<HorizontalLineComponent/>*/}
                                                                </>
                                                            }
                                                        </>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className="facility-edit-save-button d-flex mrg-top-10">
                                            {
                                                values?.facility_id && (
                                                    <>
                                                        <ButtonComponent
                                                            color={'download-button'}
                                                            isLoading={isSubmitting}
                                                            disabled={isSubmitting || !isValid || !isFileSelected}
                                                            className={"save-btn"}
                                                            type={"submit"}
                                                        >
                                                            Save
                                                        </ButtonComponent>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>

        </>
    )
})

export default BulkUploadFileComponent;
