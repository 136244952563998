import "./AddAgencyScreenComponent.scss";
import {ImageConfig, Misc} from "../../../../constants";
import {CommonService} from "../../../../shared/services";
import {useNavigate} from "react-router-dom";
import SelectDropdownComponent
    from "../../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import FormikTextAreaComponent
    from "../../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";
import * as Yup from "yup";
import FacilityHcpRateComponent from "../../hcp-facility-rate/FacilityHcpRateComponent";
import {getAgencyListLite} from "../../../../store/actions/static-data.action";
import OnboardingCompletedComponent from "../../onboarding-completed/OnboardingCompletedComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import PhoneInputComponent from "../../../../shared/components/phoneInput/PhoneInputComponent";
import FormikSelectDropdownComponent
    from "../../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
import {addAgencySteps, americanTimeZone} from "../../../../data";
import {AGENCY_LIST} from "../../../../constants/RoutesConfig";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import ModuleCardDetailsComponent
    from "../../../../shared/components/module-details-card-component/ModuleCardDetailsComponent";
import StepperComponent from "../../../../shared/components/stepper-component/StepperComponent";
import {AgencyAddStepType, RateFormAddStepType} from "../../../../shared/models/common.model";

const AgencyValidationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Name should be at least 3 characters')
        .max(50, 'Name should not exceed 50 characters')
        .required('Name is required'),
    contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    about: Yup.string(),
    admin_first_name: Yup.string().required('Admin first name is required'),
    admin_last_name: Yup.string().required('Admin last name is required'),
    admin_contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
    admin_email: Yup.string().email('Invalid admin email address').required('Admin email is required'),
    timezone: Yup.string().required('Timezone is required'),
    address: Yup.object().shape({
        street: Yup.string().required('Street is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        region: Yup.string().required('Region is required'),
        country: Yup.string().required('Country is required'),
        zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
    }),

});

interface formInitialValuesFormSchema {
    name: string;
    contact_number: any;
    email: string;
    about: string;
    admin_first_name: any;
    admin_last_name: any;
    admin_contact_number: any;
    admin_email: any;
    // location_url: any;
    timezone: any;
    address: {
        street: any,
        city: any,
        state: any,
        region: any,
        country: any,
        zip_code: any,
    };
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };
}

const formInitialValues: formInitialValuesFormSchema = {
    name: "",
    contact_number: "",
    email: "",
    about: "",
    admin_first_name: "",
    admin_last_name: "",
    // location_url: "",
    admin_contact_number: "",
    admin_email: "",
    timezone: "",
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "",
        zip_code: "",
    },
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};

const AddAgencyScreenComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {agencyList, facilityList} = useSelector((state: IRootReducerState) => state.staticData);
    const [filterData, setFilterData] = useState<any>({
        selectedFacility: null,
        selectedAgency: null,
    });
    const [isSelectedFacilityDetailsLoading, setIsSelectedFacilityDetailsLoading] = useState<boolean>(false);
    const [isSelectedFacilityDetailsLoaded, setIsSelectedFacilityDetailsLoaded] = useState<boolean>(false);
    const [selectedFacilityDetails, setSelectedFacilityDetails] = useState<any>(null);
    const [isSelectedAgencyDetailsLoading, setIsSelectedAgencyDetailsLoading] = useState<boolean>(false);
    const [isSelectedAgencyDetailsLoaded, setIsSelectedAgencyDetailsLoaded] = useState<boolean>(false);
    const [selectedAgencyDetails, setSelectedAgencyDetails] = useState<any>(null);
    const [isHcpRatesAgencyDetailsLoading, setIsHcpRatesAgencyDetailsLoading] = useState<boolean>(false);
    const [isHcpRatesAgencyDetailsLoaded, setIsHcpRatesAgencyDetailsLoaded] = useState<boolean>(false);
    const [HcpRatesAgencyDetails, setHcpRatesAgencyDetails] = useState<any>(null);
    const [isAgencyLinking, setIsAgencyLinking] = useState<boolean>(false);
    const [formScreenNavigation, setFormScreenNavigation] = useState<AgencyAddStepType>("facility-selection");
    const [ratesInnerNavigation, setRateInnerNavigation] = useState<RateFormAddStepType>("hcp-rate-form");
    const [formTab, setFormTab] = useState<any>("agency-details");
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);
    const [agencyAddActiveStep, setAgencyAddActiveStep] = React.useState(0);
    const {currentUser} = useSelector((state: IRootReducerState) => state.account);

    const handleNext = useCallback(() => {
        setAgencyAddActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, [])

    const handleBack = useCallback(() => {
        setAgencyAddActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    const getSelectedAgencyHcpRates = useCallback(() => {
        if (filterData?.selectedAgency && filterData?.selectedFacility) {
            setIsHcpRatesAgencyDetailsLoading(true);
            const payload = {};
            CommonService._agency.AgencyDetailsHCPRatesAPICall(filterData?.selectedFacility, filterData?.selectedAgency, payload)
                .then((response: IAPIResponseType<any>) => {
                    console.log(response.data);
                    setIsHcpRatesAgencyDetailsLoaded(true);
                    // setSelectedAgencyDetails(response?.data);
                    setHcpRatesAgencyDetails(response?.data);
                }).catch((error: any) => {
                setIsHcpRatesAgencyDetailsLoaded(false);
                setIsHcpRatesAgencyDetailsLoading(true);
            }).finally(() => {
                setIsHcpRatesAgencyDetailsLoading(false);
            });
        }
    }, [filterData?.selectedAgency, filterData?.selectedFacility]);

    const getSelectedFacilityDetails = useCallback(() => {
        if (filterData?.selectedFacility) {
            setIsSelectedFacilityDetailsLoading(true);
            const payload = {};
            CommonService._facility.FacilityDetailsAPICall(filterData?.selectedFacility, payload)
                .then((response: IAPIResponseType<any>) => {
                    console.log(response.data);
                    setIsSelectedFacilityDetailsLoaded(true);
                    setSelectedFacilityDetails(response?.data);
                }).catch((error: any) => {
                setIsSelectedFacilityDetailsLoaded(false);
                setIsSelectedFacilityDetailsLoading(true);
            }).finally(() => {
                setIsSelectedFacilityDetailsLoading(false);
            });
        } else {
            setSelectedFacilityDetails(null);
        }
    }, [filterData?.selectedFacility]);

    const getSelectedAgencyDetails = useCallback(() => {
        if (filterData?.selectedAgency) {
            setIsSelectedAgencyDetailsLoading(true);
            const payload = {};
            CommonService._facility.AgencyDetailsAPICall(filterData?.selectedAgency, payload)
                .then((response: IAPIResponseType<any>) => {
                    console.log(response.data);
                    setIsSelectedAgencyDetailsLoaded(true);
                    setSelectedAgencyDetails(response?.data);
                }).catch((error: any) => {
                setIsSelectedAgencyDetailsLoaded(false);
                setIsSelectedAgencyDetailsLoading(true);
            }).finally(() => {
                setIsSelectedAgencyDetailsLoading(false);
            });
        } else {
            setSelectedAgencyDetails(null);
        }
    }, [filterData?.selectedAgency]);


    useEffect(() => {
        getSelectedFacilityDetails();
    }, [getSelectedFacilityDetails]);

    useEffect(() => {
        getSelectedAgencyDetails();
    }, [getSelectedAgencyDetails]);

    useEffect(() => {
        getSelectedAgencyHcpRates();
    }, [getSelectedAgencyHcpRates]);


    const handleAgencyFacilityLinking = useCallback((agencyId:any) => {
        if (filterData?.selectedFacility) {
            setIsAgencyLinking(true);
            const payload = {};
            CommonService._agency.LinkAgencyToFacilityAPICall(filterData?.selectedFacility,agencyId, payload)
                .then((response: IAPIResponseType<any>) => {
                    setIsAgencyLinking(false);

                    if (response?.message === "Agency already linked to facility") {
                        CommonService._alert.showToast("Agency already linked to this facility", "error");
                        setIsAgencyLinking(false);
                    } else {
                        CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                        setFormScreenNavigation("facility-hcp-rate");
                        setAgencyAddActiveStep(4);
                        setIsAgencyLinking(false);
                    }
                }).catch((error: any) => {
                // CommonService._alert.showToast(error[Misc.API_ERROR_MESSAGE_KEY], "error");

            }).finally(() => {
                setIsAgencyLinking(false);
                // setFormScreenNavigation("facility-hcp-rate");
                // setAgencyAddActiveStep(4);
            });
        }
    }, [filterData]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        if (formTab === "agency-details") {
            setFormTab("Address");
            setSubmitting(false);
            return;
        } else if (formTab === "Address") {
            setSubmitting(true);
            const payload = {
                ...values,
                notification_preferences: {
                    isSmsRequired: true,
                    isEmailRequired: true,
                    isPush_notificationRequired: true,
                }
            };
            let apiCall: any = {};
            apiCall = CommonService._agency.AddAgencyAPICall(filterData?.selectedFacility, payload);
            apiCall.then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                //  navigate(CommonService._routeConfig.FacilityDetailsRoute(response.data._id));
                // setFormScreenNavigation("agency-selection");
                // setAgencyAddActiveStep(1);
                dispatch(getAgencyListLite([]));
                setFilterData({
                    ...filterData,
                    selectedAgency: response?.data?._id,
                });
                let agencyId=response?.data?._id;
                handleAgencyFacilityLinking(agencyId);
            })
                .catch((error: any) => {
                    CommonService.handleErrors(setErrors, error);
                    // setFormTab("Facility");
                }).finally(() => {
                setSubmitting(false);
            });
        }
        // eslint-disable-next-line
    }, [navigate, formTab,handleAgencyFacilityLinking]);

    useEffect(() => {
        console.log(isHcpRatesAgencyDetailsLoading, isHcpRatesAgencyDetailsLoaded);
    }, [isHcpRatesAgencyDetailsLoaded, isHcpRatesAgencyDetailsLoading]);

    const isAgencyFormValidated=useCallback((errors:any)=>{
        const fieldsToValidate=[
            "name",
            "contact_number",
            "email",
            "admin_first_name",
            "admin_last_name",
            "admin_contact_number",
            "admin_email"
        ]
        return fieldsToValidate.every((field:any)=>!errors[field])
    },[])

    return (<>

        {formScreenNavigation !== "onboarded-screen-completed" &&
            <>
                <div className={"add-agency-details-component"}>
                    <div className={'add-agency-sidebar-steper'}>
                        <div className={'add-agency-sidebar-steper-wrapper pdd-20'}>
                            <div className={'flex-1'}>
                                <div className={'adding-module-branding-wrapper'}>
                                    <div className="menu-item-icon">
                                        {currentUser?.role !== "super_admin" &&
                                            <img className={'logo-customer-container'} width={25} height={25}
                                                 src={currentUser?.doc_details?.url} alt=""/>}
                                        {currentUser?.role === "super_admin" &&
                                            <ImageConfig.VarsShortLogoIcon/>}
                                    </div>

                                    <div className={'vars-health-logo-text'}>
                                        {currentUser?.role !== "super_admin" &&
                                            <div>
                                                {currentUser?.logo_name}
                                            </div>}
                                        {currentUser?.role === "super_admin" &&
                                            <div>
                                                Work OS
                                            </div>}
                                    </div>
                                </div>
                                <div className={'form-top-header-text mrg-top-40'}>
                                    Agency Onboarding
                                </div>
                                <StepperComponent activeStep={agencyAddActiveStep} steps={addAgencySteps}/>
                            </div>
                            <div>
                                <ButtonComponent
                                    id={"exit_btn"}
                                    color={"exit-onboarding-navigation"}
                                    suffixIcon={<ImageConfig.ExitIcon/>}
                                    onClick={() => {
                                        CommonService.onExitFlowHandling(navigate, AGENCY_LIST);
                                    }}
                                >
                                    Exit Agency Onboarding
                                </ButtonComponent>
                            </div>
                        </div>
                    </div>
                    <div className={'add-agency-formik-wrapper mrg-top-20'}>
                        {formScreenNavigation === "facility-selection" &&
                            <div className={"facility-selection-component"}>
                                <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                    <div>
                                        <div className={'form-top-header-text'}>Select The Facility</div>
                                        <SelectDropdownComponent noDataFoundText={"No Facility Found"}
                                                                 navigation={() => {
                                                                 }} buttonRequired={false}
                                                                 placeholder={"Select Facility"}
                                                                 searchMode={"clientSide"}
                                                                 label={'Select The Facility*'}
                                                                 value={filterData.selectedFacility || null}
                                                                 options={facilityList}
                                                                 valueExtractor={item => item.code}
                                                                 searchable={true}
                                                                 onUpdate={
                                                                     (value: any) => {
                                                                         setFilterData({
                                                                             ...filterData,
                                                                             selectedFacility: value,
                                                                         });
                                                                     }
                                                                 }/>
                                    </div>
                                    <div>
                                        {
                                            isSelectedFacilityDetailsLoading && <LoaderComponent color={'primary'}/>
                                        }
                                    </div>
                                    {
                                        !isSelectedFacilityDetailsLoading && isSelectedFacilityDetailsLoaded && selectedFacilityDetails && <>
                                            <div>
                                                <HorizontalLineComponent/>
                                                <div className={'mrg-top-20 mrg-bottom-10 card-details-header'}>Facility
                                                    Details
                                                </div>
                                                <ModuleCardDetailsComponent
                                                    title={selectedFacilityDetails?.name}
                                                    headerIcon={<ImageConfig.AgencyDetailsIcon/>}
                                                    addressIcon={<ImageConfig.AddressDetailsIcon/>}
                                                    emailIcon={<ImageConfig.EmailDetailsIcon/>}
                                                    phoneIcon={<ImageConfig.PhoneDetailsIcon/>}
                                                    moduleName={'Facility'}
                                                    moduleDetails={selectedFacilityDetails}/>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={'add-agency-form-action-element'}>
                                    <div></div>
                                    <ButtonComponent
                                        disabled={!filterData.selectedFacility}
                                        style={{color: "black"}}
                                        onClick={() => {
                                            setFormScreenNavigation("agency-selection");
                                            handleNext();
                                            setAgencyAddActiveStep(1);
                                        }}
                                        suffixIcon={<ImageConfig.NextButtonIcon/>}
                                    >
                                        Next
                                    </ButtonComponent>
                                </div>
                            </div>}
                        {formScreenNavigation === "agency-selection" &&
                            <div className={"agency-selection-component"}>
                                <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                    <div>
                                        <div className={'d-flex justify-content-space-between align-items-center'}>
                                            <div className={'form-top-header-text'}>Select The Agency</div>
                                            {
                                                formScreenNavigation === "agency-selection" && !selectedAgencyDetails && !isSelectedAgencyDetailsLoading &&
                                                <div className={'add-agency-button'}
                                                    // style={{alignSelf: "center"}}
                                                     onClick={() => {
                                                         setFormScreenNavigation("add-new-agency");
                                                         handleNext();
                                                     }}>
                                                    <ButtonComponent
                                                        prefixIcon={<ImageConfig.AddOutlinedIcon/>}
                                                        className={"center-element add-button-text "}>Add New
                                                        Agency</ButtonComponent>
                                                </div>
                                            }
                                        </div>
                                        <SelectDropdownComponent noDataFoundText={"No Agency Found"} navigation={() => {
                                            setFormScreenNavigation("add-new-agency");
                                        }} buttonRequired={false} placeholder={"Select Agency"}
                                                                 label={'Select The Agency*'}
                                                                 searchMode={"clientSide"}
                                                                 value={filterData.selectedAgency || null}
                                                                 options={agencyList}
                                                                 valueExtractor={item => item.code} searchable={true}
                                                                 onUpdate={
                                                                     (value: any) => {
                                                                         setFilterData({
                                                                             ...filterData,
                                                                             selectedAgency: value,
                                                                         });
                                                                     }
                                                                 }/>
                                    </div>
                                    <div>
                                        {
                                            isSelectedAgencyDetailsLoading && <LoaderComponent color={'primary'}/>
                                        }
                                    </div>
                                    {
                                        !isSelectedAgencyDetailsLoading && isSelectedAgencyDetailsLoaded && selectedAgencyDetails && <>
                                            <div>
                                                <HorizontalLineComponent/>
                                                <div className={'mrg-top-20 mrg-bottom-10 card-details-header'}>Agency
                                                    Details
                                                </div>
                                                <ModuleCardDetailsComponent
                                                    title={selectedAgencyDetails?.name}
                                                    headerIcon={<ImageConfig.AgencyDetailsIcon/>}
                                                    addressIcon={<ImageConfig.AddressDetailsIcon/>}
                                                    emailIcon={<ImageConfig.EmailDetailsIcon/>}
                                                    phoneIcon={<ImageConfig.PhoneDetailsIcon/>}
                                                    moduleName={'Agency'}
                                                    moduleDetails={selectedAgencyDetails}/>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={'add-agency-form-action-element'}>
                                    <div className={"mrg-left-20"}>
                                        <ButtonComponent
                                            className={'back-button'}
                                            suffixIcon={<ImageConfig.BackArrow/>}
                                            onClick={() => {
                                                setFormScreenNavigation("facility-selection");
                                                handleBack();
                                            }}
                                        >
                                            Back
                                        </ButtonComponent>
                                    </div>
                                    <ButtonComponent
                                        disabled={!filterData.selectedAgency}
                                        isLoading={isAgencyLinking}
                                        onClick={()=>handleAgencyFacilityLinking(filterData?.selectedAgency)}
                                        style={{color: "black"}}
                                        suffixIcon={<ImageConfig.NextButtonIcon/>}
                                    >
                                        Next
                                    </ButtonComponent>
                                </div>
                            </div>
                        }
                        {
                            formScreenNavigation === "add-new-agency" &&
                            <Formik
                                validationSchema={AgencyValidationSchema}
                                initialValues={initialValues}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize={true}
                                validateOnMount={true}
                                onSubmit={onSubmit}
                            >
                                {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                    // eslint-disable-next-line react-hooks/rules-of-hooks
                                    useEffect(() => {
                                        validateForm();
                                    }, [validateForm, values]);
                                    return (
                                        <Form noValidate={true} className={"add-agency-from"}>
                                            <div className="add-component-content">
                                                <div
                                                    className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                                    {formTab === "agency-details" && <>
                                                        <div className={"flex-1"}>
                                                            <div className={'form-top-header-text'}>Agency Details</div>
                                                            <Field name={'name'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Agency Name'}
                                                                            placeholder={'Enter Agency Name'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                            <div className="ts-row">
                                                                <div className="ts-col-6">
                                                                    <LabelComponent title={'Phone Number*'}/>
                                                                    <Field name={'contact_number'}>
                                                                        {(field: FieldProps) => {
                                                                            return <PhoneInputComponent field={field}
                                                                                                        placeholder={"Enter Phone number"}/>;
                                                                        }}
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-6">
                                                                    <Field name={'email'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Email Address'}
                                                                                    placeholder={'example@xyz.com'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className={"mrg-top-10"}>
                                                                <Field name={'about'}>
                                                                    {
                                                                        (field: FieldProps) => (
                                                                            <FormikTextAreaComponent
                                                                                label={'About The Agency'}
                                                                                rows={6}
                                                                                placeholder={''}
                                                                                //required={true}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                            </div>
                                                            <div className="add-component-header-admin">
                                                                <div
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "14px"
                                                                    }}
                                                                    className="header-text mrg-bottom-20 mrg-top-20">

                                                                    Admin Details
                                                                </div>
                                                            </div>
                                                            <div className="ts-row">
                                                                <div className="ts-col-6">
                                                                    <Field name={'admin_first_name'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'First Name'}
                                                                                    placeholder={'ex : John'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-6">
                                                                    <Field name={'admin_last_name'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Last Name'}
                                                                                    placeholder={'ex : Doe'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className="ts-row">
                                                                <div className="ts-col-6">
                                                                    <LabelComponent title={'Phone Number*'}/>
                                                                    <Field name={'admin_contact_number'}>
                                                                        {(field: FieldProps) => {
                                                                            return <PhoneInputComponent field={field}
                                                                                                        placeholder={"Enter Phone number"}/>;
                                                                        }}
                                                                    </Field>
                                                                </div>
                                                                <div className="ts-col-6">
                                                                    <Field name={'admin_email'}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Email Address'}
                                                                                    placeholder={'example@xyz.com'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                    {
                                                        formTab === "Address" && <>
                                                            <div className={'form-top-header-text'}>Agency Address Details
                                                            </div>
                                                            <div className={"flex-1"}>
                                                                <Field name={'address.street'}>
                                                                    {
                                                                        (field: FieldProps) => (
                                                                            <FormikInputComponent
                                                                                label={'Address Line'}
                                                                                placeholder={'Enter address here'}
                                                                                type={"text"}
                                                                                required={true}
                                                                                formikField={field}
                                                                                fullWidth={true}
                                                                            />
                                                                        )
                                                                    }
                                                                </Field>
                                                                <div className="ts-row">
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.country'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'Country'}
                                                                                        placeholder={'Enter the Country'}
                                                                                        type={"text"}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.state'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'State'}
                                                                                        placeholder={'Enter State'}
                                                                                        type={"text"}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="ts-row">
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.zip_code'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'ZIP Code'}
                                                                                        placeholder={'Enter ZIP Code'}
                                                                                        type={"number"}
                                                                                        maxLength={6}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.city'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikInputComponent
                                                                                        label={'City'}
                                                                                        placeholder={'Enter City'}
                                                                                        type={"text"}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <div className="ts-row">
                                                                    <div className="ts-col-6">
                                                                        <Field name={'timezone'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikSelectDropdownComponent
                                                                                        options={americanTimeZone}
                                                                                        displayWith={(option: any) => option.title}
                                                                                        valueExtractor={(option: any) => option.code}
                                                                                        label={'Timezone'}
                                                                                        placeholder={'Select Timezone'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                        searchable={true}
                                                                                        noDataMessage={"No timezone available"}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                    <div className="ts-col-6">
                                                                        <Field name={'address.region'}>
                                                                            {
                                                                                (field: FieldProps) => (
                                                                                    <FormikSelectDropdownComponent
                                                                                        options={regionList}
                                                                                        displayWith={(option: any) => option.title}
                                                                                        valueExtractor={(option: any) => option.code}
                                                                                        label={'Region'}
                                                                                        placeholder={'Select Region'}
                                                                                        required={true}
                                                                                        formikField={field}
                                                                                        fullWidth={true}
                                                                                        searchable={true}
                                                                                        noDataMessage={"No region available"}
                                                                                    />
                                                                                )
                                                                            }
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className={'add-agency-form-action-element'}>
                                                    {formTab === "agency-details" &&
                                                        <div className={"mrg-left-20"}>
                                                            <ButtonComponent
                                                                className={'back-button'}
                                                                suffixIcon={<ImageConfig.BackArrow/>}
                                                                onClick={() => {
                                                                    //  setFormTab("Address");
                                                                    setFormScreenNavigation('agency-selection');
                                                                    handleBack();
                                                                }}
                                                            >
                                                                Back
                                                            </ButtonComponent>
                                                        </div>
                                                    }
                                                    {formTab === "agency-details" &&
                                                        <div>
                                                            <ButtonComponent
                                                                disabled={!isAgencyFormValidated(errors)}
                                                                style={{color: "black"}}
                                                                onClick={() => {
                                                                    setFormTab("Address");
                                                                    handleNext();
                                                                    setAgencyAddActiveStep(3);
                                                                }}
                                                                suffixIcon={<ImageConfig.NextButtonIcon/>}
                                                            >
                                                                Next
                                                            </ButtonComponent>
                                                        </div>
                                                    }

                                                    {formTab === "Address" &&
                                                        <div className={"mrg-left-20"}>
                                                            <ButtonComponent
                                                                className={'back-button'}
                                                                suffixIcon={<ImageConfig.BackArrow/>}
                                                                onClick={() => {
                                                                    setFormTab("agency-details");
                                                                    handleBack();
                                                                }}
                                                            >
                                                                Back
                                                            </ButtonComponent>
                                                        </div>
                                                    }
                                                    {formTab === "Address" &&
                                                        <div>
                                                            <div
                                                                className={"ts-row center-element"}>
                                                                <ButtonComponent
                                                                    disabled={isSubmitting || !isValid}
                                                                    isLoading={isSubmitting}
                                                                    style={{color: "black"}}
                                                                    type={'submit'}
                                                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                                >
                                                                    Save Details
                                                                </ButtonComponent>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        }
                        {
                            formScreenNavigation === "facility-hcp-rate" &&
                            <>
                                <div className={'facility-hcp-rate-screen-box'}>
                                    <FacilityHcpRateComponent setRateInnerNavigation={setRateInnerNavigation}
                                                              ratesInnerNavigation={ratesInnerNavigation}
                                                              setFormScreenNavigation={setFormScreenNavigation}
                                                              getSelectedAgencyHcpRates={getSelectedAgencyHcpRates}
                                                              filterData={filterData}
                                                              HcpRatesAgencyDetails={HcpRatesAgencyDetails}
                                                              selectedFacilityDetails={selectedFacilityDetails}
                                                              getSelectedAgencyDetails={getSelectedAgencyDetails}
                                                              selectedAgencyDetails={selectedAgencyDetails}
                                                              setAgencyAddActiveStep={setAgencyAddActiveStep}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </>
        }
        {formScreenNavigation === "onboarded-screen-completed" && <>
            <OnboardingCompletedComponent/>
        </>}

    </>);
};

export default AddAgencyScreenComponent;
