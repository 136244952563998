import "./EditAgencyDetails.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {ImageConfig, Misc} from "../../../constants";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import {useParams} from "react-router-dom";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import PhoneInputComponent from "../../../shared/components/phoneInput/PhoneInputComponent";
import FormControl from "@mui/material/FormControl";
import {ListItemText, MenuItem, Select} from "@mui/material";
import {americanTimeZone} from "../../../data";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import FormikTextAreaComponent from "../../../shared/components/form-controls/formik-text-area/FormikTextAreaComponent";

interface EditAgencyComponentProps {
    AdminDetails: any;
    closeEditDrawer: any;
    getAgencyDetails: any;
}

const EditAgencyValidationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'Name should be at least 3 characters')
            .max(50, 'Name should not exceed 50 characters')
            .required('Name is required'),
        contact_number: Yup.string().min(12, "min 10 digits").required("Required"),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        about: Yup.string(),
        address: Yup.object({
            street: Yup.string()
                .min(1, 'Address Line should be at least 1 character')
                .required('Address Line  is required'),
            zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
            state: Yup.string()
                .min(1, 'State should be at least 1 character')
                .max(30, 'State should not exceed 30 characters')
                .required('State is required'),
            city: Yup.string()
                .min(1, 'City should be at least 1 character')
                .max(30, 'City should not exceed 30 characters')
                .required('City is required'),
            country: Yup.string()
                .min(1, 'Country should be at least 1 character')
                .max(30, 'Country should not exceed 30 characters')
                .required('Country is required'),
            region: Yup.string()
                .min(1, 'Country should be at least 1 character')
                .max(30, 'Country should not exceed 30 characters')
                .required('Country is required'),
        }),
    }
);

interface EditAgencyFormInitialValuesFormSchema {
    name: string;
    contact_number: string;
    email: string;
    about: string;
    location_url: string,
    timezone: string,
    address: {
        region: any,
        zip_code: any,
        street: any,
        state: string,
        city: string,
        country: string,

    };
}

const EditFacilityFormInitialValues: EditAgencyFormInitialValuesFormSchema = {
    name: "",
    contact_number: "",
    email: "",
    about: "",
    location_url: "",
    timezone: "",
    address: {
        region: "",
        zip_code: "",
        street: "",
        state: "",
        city: "",
        country: "",
    }
};


const EditAgencyBasicDetailsComponent = (props: EditAgencyComponentProps) => {

    const {AdminDetails, closeEditDrawer, getAgencyDetails} = props;
    const {agencyId} = useParams();
    const [initialValues, setInitialValues] = useState<EditAgencyFormInitialValuesFormSchema>(EditFacilityFormInitialValues);
    const [selectedRegion, setSelectedRegion] = useState<any>(null);
    const [selectedTimeZone, setSelectedTimeZone] = useState<any>(null);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);

    useEffect(() => {
        const basicDetails = {
            name: AdminDetails?.name,
            contact_number: AdminDetails?.contact_number,
            email: AdminDetails?.email,
            about: AdminDetails?.about,
            location_url: AdminDetails?.location_url,
            timezone: AdminDetails?.timezone,
            address: {
                region: AdminDetails?.address?.region,
                zip_code: AdminDetails?.address?.zip_code,
                street: AdminDetails?.address?.street,
                state: AdminDetails?.address?.state,
                city: AdminDetails?.address?.city,
                country: AdminDetails?.address?.country,
            }
        };
        setInitialValues(basicDetails);
        setSelectedTimeZone(AdminDetails?.timezone);
        setSelectedRegion(AdminDetails?.address?.region);
    }, [AdminDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };
        let apiCall: any = {};
        apiCall = CommonService._agency.EditAgencyAPICall(agencyId, payload);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            getAgencyDetails();
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                closeEditDrawer();
            }).finally(() => {
            setSubmitting(false);
        });
    }, [closeEditDrawer, getAgencyDetails, agencyId]);

    return (
        <>
            <div className={'edit-facility-component'}>
                <div className={'edit-facility-header'}>
                    <div className={'form-header-text'}>Edit Agency Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <div className={'edit-facility-form-wrapper'}>
                    <Formik
                        validationSchema={EditAgencyValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container'}>
                                            <div className="edit-component-content">
                                                <Field name={'name'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Agency Name'}
                                                                placeholder={'Enter Agency Name'}
                                                                type={"text"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <LabelComponent title={'Phone Number*'}/>
                                                        <Field name={'contact_number'}>
                                                            {(field: FieldProps) => {
                                                                return <PhoneInputComponent field={field}
                                                                                            placeholder={"Enter Phone number"}/>;
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'email'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Email Address'}
                                                                        placeholder={'example@xyz.com'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <Field name={'about'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikTextAreaComponent
                                                                label={'About the Agency'}
                                                                //  placeholder={'Enter Facility Name'}
                                                                rows={5}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>
                                            <div className="edit-component-content">
                                                <Field name={'address.street'}>
                                                    {
                                                        (field: FieldProps) => (
                                                            <FormikInputComponent
                                                                label={'Address Line'}
                                                                placeholder={'Enter Address Line here'}
                                                                type={"text"}
                                                                required={true}
                                                                formikField={field}
                                                                fullWidth={true}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <Field name={'address.country'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Country'}
                                                                        placeholder={'Enter Country Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'address.state'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'State'}
                                                                        placeholder={'Enter State Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <Field name={'address.zip_code'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'ZIP Code'}
                                                                        placeholder={'Enter ZIP Code'}
                                                                        type={"number"}
                                                                        maxLength={6}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <Field name={'address.city'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'City'}
                                                                        placeholder={'Enter City Name'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className={'d-flex'}>
                                                    <div className={'mrg-right-25 half-width'}>
                                                        <LabelComponent title={'Timezone*'}/>
                                                        <Field name={'timezone'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormControl sx={{m: 1, width: 236.5}}>
                                                                        {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            inputProps={{
                                                                                name: 'timezone',
                                                                                id: 'uncontrolled-native',
                                                                            }}
                                                                            // input={<BootstrapInput/>}
                                                                            value={selectedTimeZone}
                                                                            onChange={(event: any) => {
                                                                                setSelectedTimeZone(event.target.value);
                                                                                setFieldValue('timezone', event.target.value);
                                                                            }}
                                                                            // label="Select Facility"
                                                                        >
                                                                            {americanTimeZone.map((item: any) => (
                                                                                <MenuItem key={item.code}
                                                                                          value={item?.code}>
                                                                                    <ListItemText
                                                                                        primary={item.title}/>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className={'half-width'}>
                                                        <LabelComponent title={'Region*'}/>
                                                        <Field name={'address.region'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormControl sx={{m: 1, width: 236.5}}>
                                                                        {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            inputProps={{
                                                                                name: 'address.region',
                                                                                id: 'uncontrolled-native',
                                                                            }}
                                                                            // input={<BootstrapInput/>}
                                                                            value={selectedRegion}
                                                                            onChange={(event: any) => {
                                                                                setSelectedRegion(event.target.value);
                                                                                setFieldValue('address.region', event.target.value);
                                                                            }}
                                                                            // label="Select Facility"
                                                                        >
                                                                            {regionList.map((item: any) => (
                                                                                <MenuItem key={item.code}
                                                                                          value={item?.code}>
                                                                                    <ListItemText
                                                                                        primary={item.title}/>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'ts-justify-content-center d-flex mrg-bottom-10 mrg-top-40'}>
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditAgencyBasicDetailsComponent;


