import "./NoDataCardComponent.scss";
import React from 'react';
import {ImageConfig} from "../../../constants";

const NoDataCard: React.FC = () => {
    return (
        <div className="no-data-container">
            <div className={'img'}>
                <ImageConfig.NoDataFoundIcon/>
            </div>
            <p>No Data Available</p>
        </div>
    );
};

export default NoDataCard;