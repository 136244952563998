export const SET_DATE_RANGE="SET_DATE_RANGE";
export const SET_OPEN_SHIFT_STATUS="SET_OPEN_SHIFT_STATUS";
export const SET_SELECTED_FACILITY="SET_SELECTED_FACILITY";
export const SET_SELECTED_HCP_TYPE_DATA="SET_SELECTED_HCP_TYPE_DATA";
export const SET_SELECTED_SHIFT_DATA="SET_SELECTED_SHIFT_DATA";
export const SET_SELECTED_FILTER_COUNT="SET_SELECTED_FILTER_COUNT";
export const SET_SHIFT_FILTER_DATA="SET_SHIFT_FILTER_DATA";

export const setDateRange=(dateRange:any[])=>{
    return {
        type:SET_DATE_RANGE,
        payload:dateRange
    }
}

export const setOpenShiftStatus=(openShiftStatus:any[])=>{
    return {
        type:SET_OPEN_SHIFT_STATUS,
        payload:openShiftStatus,
    }
}

export const setSelectedFacility=(facility:any)=>{
    return {
        type:SET_SELECTED_FACILITY,
        payload:facility
    }
}

export const setSelectedHcpTypeData=(hcpTypeData:any[])=>{
    return {
        type:SET_SELECTED_HCP_TYPE_DATA,
        payload:hcpTypeData
    }
}
export const setSelectedShiftData=(shiftData:any[])=>{
    return {
        type:SET_SELECTED_SHIFT_DATA,
        payload:shiftData
    }
}

export const setSelectedFilterCount=(count:number)=>{
    return {
        type:SET_SELECTED_FILTER_COUNT,
        payload:count
    }
}

export const setShiftFilterData=(data:any)=>{
    return {
        type:SET_SHIFT_FILTER_DATA,
        payload:data
    }
}
