import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./CancelledShiftDetailsComponent.scss";
import {APIConfig, ImageConfig} from "../../../../constants";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import CardComponent from "../../../../shared/components/card/CardComponent";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";
import TimeSheetUploadPreviewFile from "../approved-shift-details/TimeSheetUploadPreviewFile";
import {Tooltip, Typography} from "@mui/material";
import moment from "moment";
import {ITableColumn} from "../../../../shared/models/table.model";
import TextWithIconComponent from "../../../../shared/components/text-with-icon/TextWithIconComponent";
import ToolTipComponent from "../../../../shared/components/tool-tip/ToolTipComponent";
import {shiftTypeLayoutRepresentation} from "../../../../data";
import CommonHcpDetailsCard from "../hcp-details-common-card/CommonHcpDetailsCard";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../../shared/components/tabs/TabsComponent";
import TableWrapperComponent from "../../../../shared/components/table-wrapper/TableWrapperComponent";
import HomeHospiceDetails from "../hospice-home-shift-details/HomeHospiceDetailsComponent";
import ContractTravelDetails from "../contract-travel-details/ContractTravelDetailsComponent";


interface CancelledShiftDetailsComponentProps {
    setOpenDrawer?: any;
    shiftDetails: any;
    setFilterData: any;
    filterData: any;
    isOnCalender?: any;
    dayMonthView?: any
}

const CancelledShiftDetailsComponent = (props: CancelledShiftDetailsComponentProps) => {
    const {setOpenDrawer, shiftDetails, filterData, setFilterData, isOnCalender, dayMonthView} = props;
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    //  const [isHcpDetailsLoadingFailed, setIsHcpDetailsLoadingFailed] = useState<boolean>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>(null);
    const [innerShiftDetails, setInnerShiftDetails] = useState<any>(null);
    const [activeTab, setActiveTab] = useState<any>("cancelled-shift-requirement");
    const [hcpFacilityShiftFilterData, setHcpFacilityShiftFilterData] = useState({});
    const [isShiftDetailsLoaded, setIsInnerShiftDetailsLoaded] = useState<any>(false);

    const getShiftDetails = useCallback(() => {
        setIsInnerShiftDetailsLoaded(false);
        const payload = {};
        if (shiftDetails?._id) {
            CommonService._shift_management.getAssignedShiftDetailsAPICall(shiftDetails?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setInnerShiftDetails(response.data);
                    setIsInnerShiftDetailsLoaded(true);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [shiftDetails?._id]);

    useEffect(() => {
        getShiftDetails();
    }, [getShiftDetails]);

    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (dayMonthView) {
            CommonService._hcp.HcpDetailsAPICall(shiftDetails?.hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    //   setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                //  setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        } else {
            CommonService._hcp.HcpDetailsAPICall(shiftDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    // setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                //  setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [dayMonthView, shiftDetails?.hcp_details?._id, shiftDetails?.hcp_id]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (dayMonthView) {
            CommonService._hcp.HcpDocumentAPICall(shiftDetails?.hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        } else {
            CommonService._hcp.HcpDocumentAPICall(shiftDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [dayMonthView, shiftDetails?.hcp_details?._id, shiftDetails?.hcp_id]);


    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    useEffect(() => {
        getHcpDocuments();
    }, [getHcpDocuments]);

    const previewPDF = useCallback(() => {
        window.open(innerShiftDetails?.timesheet_details?.url, '_blank');
    }, [innerShiftDetails?.timesheet_details?.url]);

    const shiftTimingsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>Shift
                    Date</TextWithIconComponent>,
                key: 'shift_date',
                dataIndex: 'shift_date',
                width: 150,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return <>{CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>Shift
                    Time</TextWithIconComponent>,
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 150,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return <>{CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.end_time)}</>;
                    } else {
                        return <>{CommonService.convertMinutesToTime(item?.expected?.shift_start_time) + "-" + CommonService.convertMinutesToTime(item?.expected?.shift_end_time)}</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.Wing/>}>Wing</TextWithIconComponent>,
                key: 'wing',
                dataIndex: 'wing',
                width: 100,
                align: 'left',
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.facility_wing_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.facility_wing_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>Shift
                    Type</TextWithIconComponent>,
                key: 'shift_type',
                dataIndex: 'shift_type',
                width: 200,
                render: (item: any) => {
                    // console.log(item);
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}

                        {
                            (() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === item?.shift_type);
                                if (foundShiftType) {
                                    return <>
                                        <div
                                            className={'mrg-right-5 mrg-top-5'}>
                                            {foundShiftType.icon}
                                        </div>
                                        <div className={foundShiftType.color}>
                                            {item?.shift_type}
                                        </div>
                                    </>;
                                } else {
                                    return null; // or some default icon or message if shift type is not found
                                }
                            })()
                        }
                    </div>;
                }
            },

        ];
    }, []);

    useEffect(() => {
        if (isHcpDetailsLoaded) {
            setHcpFacilityShiftFilterData({
                hcp_id: hcpDetails?._id,
                facility_id: shiftDetails?.facility_id
            });
        }
    }, [hcpDetails, shiftDetails, isHcpDetailsLoaded]);
    return (
        <>
            <div className={"cancelled-shift-view-details"}>
                <div className={"approved-header d-flex justify-content-space-between pdd-20"} style={{
                    backgroundImage: `url(${ImageConfig.CancelledGradient}`,
                    backgroundRepeat: "no-repeat"
                }}>
                    <div className={'d-flex ts-align-items-center'}>
                        <div className={'form-header-text'}>Shift Details
                        </div>
                        <div className={'mrg-left-15 canceled-wrapper'}>Canceled</div>
                    </div>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                {isHcpDetailsLoading && <>
                    <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                </>}
                {isHcpDetailsLoaded && <>
                    <div className={"non-header-container"}>
                        {
                            innerShiftDetails?.timesheet_details &&
                            <div className={"mrg-bottom-15"}>
                                <TimeSheetUploadPreviewFile
                                    timesheetFile={innerShiftDetails?.timesheet_details}
                                    previewPDF={previewPDF}
                                    getShiftDetails={getShiftDetails}
                                    setFilterData={setFilterData}
                                    filterData={filterData}
                                    shiftType={'approved'}
                                    isonCalender={isOnCalender}
                                />
                            </div>
                        }
                        <div className={'cancelled-card'}
                        >
                            <div className={""}>
                                <div className={'ts-row'}>
                                    <div className={'ts-col-12'}>
                                        <DataLabelValueComponent label={'Reason for Cancellation'}>
                                            <Typography fontSize={"14px"} fontWeight={500} color={"#797F87"}>
                                                {
                                                    innerShiftDetails?.cancelled_details?.reason || "-"
                                                }</Typography>
                                        </DataLabelValueComponent>
                                    </div>
                                </div>

                                <div className={'ts-row'}>
                                    <div className={'ts-col-6'}>
                                        <DataLabelValueComponent label={'Cancelled By'}>
                                            <Typography fontSize={"14px"} fontWeight={500} color={"#797F87"}>
                                                {
                                                    (innerShiftDetails?.cancelled_details?.user_details?.first_name || "-") + " " + (innerShiftDetails?.cancelled_details?.user_details?.last_name || "-")
                                                }
                                            </Typography>
                                        </DataLabelValueComponent>
                                    </div>
                                    <div className={'ts-row'}>
                                        <DataLabelValueComponent label={'Cancelled On'}>
                                            <Typography fontSize={"14px"} fontWeight={500} color={"#797F87"}>
                                                {
                                                    moment(innerShiftDetails?.cancelled_details?.date).format("DD-MM-YYYY | h:mm A")
                                                }</Typography>


                                        </DataLabelValueComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'hcp-details-wrapper'}>
                            <>
                                <div className={'shift-requirement-day-week-details-wrapper'}>
                                    <div className={'d-flex d-flex-space-between ts-align-items-center'}>
                                        <div className={'hcp-details-header-text-drawer'}>Staff Details</div>
                                    </div>

                                    <div className={'mrg-top-15'}>
                                        <CommonHcpDetailsCard shiftDetails={shiftDetails} hcpDetails={hcpDetails}
                                                              innerShiftDetails={innerShiftDetails}/>
                                    </div>
                                    <div className={'drawer-tab-wrapper mrg-top-20'}>
                                        <TabsWrapperComponent>
                                            <TabsComponent
                                                value={activeTab}
                                                allowScrollButtonsMobile={false}
                                                onUpdate={(e: any, v: any) => {
                                                    setActiveTab(v);
                                                }}
                                            >
                                                <TabComponent label="Shift Requirement details"
                                                              value={"cancelled-shift-requirement"}/>
                                                <TabComponent label="Documents" value={"document"}/>
                                                <TabComponent label="Shifts in this facility" value={"facility-shift"}/>
                                            </TabsComponent>
                                            <TabContentComponent value={activeTab}
                                                                 selectedTab={"cancelled-shift-requirement"}>
                                                <CardComponent size={"sm"}
                                                               className={"mrg-top-10 shift-details-card-hcp"}>
                                                    {isShiftDetailsLoaded &&
                                                        <>
                                                            {innerShiftDetails?.requirementType === "Open Shift" &&
                                                                <div
                                                                    className={"pdd-left-15 pdd-right-25 pdd-top-25"}>
                                                                    <div
                                                                        className={'shift-requirement-week-day-header mrg-bottom-15 form-header-text '}>Shift
                                                                        Requirement Details
                                                                    </div>

                                                                    <div
                                                                        className={'shift-requirement-week-day-details-card'}>
                                                                        <div className={'pdd-10'}>
                                                                            <div className={'d-flex mrg-bottom-20'}>
                                                                                <div
                                                                                    className={'d-flex flex-2 ts-align-items-center'}>
                                                                                    <div
                                                                                        className={"mrg-right-15 d-flex ts-align-items-center"}>
                                                                                        <ImageConfig.FacilityIcon/>
                                                                                    </div>
                                                                                    <Tooltip
                                                                                        title={innerShiftDetails?.facilityDetails?.name || '-'}
                                                                                        placement={"top"}>
                                                                                        <div
                                                                                            className={'ellipses-for-calender-card-data'}> {innerShiftDetails?.facilityDetails?.name || '-'}
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                <div
                                                                                    className={'d-flex flex-2 ts-align-items-center'}>
                                                                                    <ImageConfig.TimeIcon/>
                                                                                    <div
                                                                                        className={'shift-details-field-text mrg-left-15'}>
                                                                                        {(CommonService.convertMinutesToTime(innerShiftDetails?.expected
                                                                                            ?.shift_start_time) + "-" + CommonService.convertMinutesToTime(innerShiftDetails?.expected
                                                                                            ?.shift_end_time)) || "-"}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={'d-flex mrg-bottom-20'}>
                                                                                <div
                                                                                    className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                    <ImageConfig.DateIcon/>
                                                                                    <div
                                                                                        className={'shift-details-field-text mrg-left-15'}>
                                                                                        {/*{CommonService.convertDateFormat(innerShiftDetails?.shift_date) || "-"}*/}
                                                                                        {CommonService.convertDateFormat2(innerShiftDetails?.shift_date, 'MM-DD-YYYY') || '-'}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                    <ImageConfig.HCPTypeIcon/>
                                                                                    <div
                                                                                        className={'shift-details-field-text mrg-left-15'}>
                                                                                        {innerShiftDetails?.hcpTypeDetails?.title || "-"}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className={'d-flex mrg-bottom-20'}>
                                                                                <div
                                                                                    className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                    <ImageConfig.HCPCountIcon/>
                                                                                    <div
                                                                                        className={'shift-details-field-text'}>
                                                                                        {innerShiftDetails?.required_hcp_count || '-'} Staff
                                                                                        Required
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                    <ImageConfig.RateIcon/>
                                                                                    <div
                                                                                        className={'shift-details-field-text mrg-left-15'}>
                                                                                        ${innerShiftDetails?.shift_rate || '-'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {innerShiftDetails?.facility_wing_details?.name &&
                                                                                <div className={'d-flex'}>
                                                                                    <div
                                                                                        className={'d-flex  flex-2 ts-align-items-center'}>
                                                                                        <ImageConfig.WingIcon/>
                                                                                        <div
                                                                                            className={'shift-details-field-text mrg-left-15'}>
                                                                                            {innerShiftDetails?.facility_wing_details?.name || '-'}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>}
                                                            {
                                                                innerShiftDetails?.requirementType === "Home Health" &&
                                                                <HomeHospiceDetails
                                                                    hospiceHomeShiftDetails={innerShiftDetails}/>
                                                            }
                                                            {
                                                                innerShiftDetails?.requirementType === "Contract Travel" &&
                                                                <ContractTravelDetails
                                                                    contractTravelShiftDetails={innerShiftDetails}/>
                                                            }
                                                        </>
                                                    }
                                                </CardComponent>
                                            </TabContentComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"document"}>
                                                {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                                                    <CardComponent size={"sm"}
                                                                   className={"mrg-top-10 shift-details-card-hcp"}>
                                                        <div className={'document-box'}>
                                                            <div
                                                                className={'document-text mrg-left-20 mrg-top-20 mrg-bottom-5'}>Document
                                                            </div>
                                                            {
                                                                hcpDocumentsList?.map((document: any, index: any) => {

                                                                    let transformedName = "";
                                                                    if (document?.attachment_type === "tb_test") {
                                                                        transformedName = "TB Test";
                                                                    } else if (document?.attachment_type === "cpr_bls_card") {
                                                                        transformedName = "CPR BLS Card";
                                                                    } else {
                                                                        transformedName = CommonService.transformName(document?.attachment_type);
                                                                    }
                                                                    return (
                                                                        <>
                                                                            <div
                                                                                className={'hcp-document-list-wrapper cursor-pointer'}
                                                                                onClick={() => {
                                                                                    window.open(document?.url, '_blank');
                                                                                }}>
                                                                                <div className={'icon-button'}>
                                                                                    <ImageConfig.FileUploadHcp/>
                                                                                </div>
                                                                                <div
                                                                                    className={'underline-text mrg-left-10'}>
                                                                                    {transformedName}
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </CardComponent>}
                                                {(hcpDocumentsList?.length === 0 && isHcpDetailsLoaded) &&
                                                    <CardComponent size={"sm"} className={"mrg-top-10"}>
                                                        <div className={'details-screen'}>
                                                            <div
                                                                className="details-screen-title-with-image-wrapper pdd-10">
                                                                <div
                                                                    className={'details-screen-title-wrapper center-right-element'}>
                                                                    <div className={'details-screen-title'}>Documents
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <HorizontalLineComponent/>
                                                            <div className={'no-content differential-hcp-rate'}>
                                                                No Document Uploaded yet..
                                                            </div>
                                                        </div>
                                                    </CardComponent>}
                                            </TabContentComponent>
                                            <TabContentComponent value={activeTab} selectedTab={"facility-shift"}>
                                                <div className={'table-wrapper-box'}>
                                                    <TableWrapperComponent url={APIConfig.FACILITY_HCP_SHIFTS.URL}
                                                                           method={APIConfig.FACILITY_HCP_SHIFTS.METHOD}
                                                                           isPaginated={true}
                                                                           extraPayload={hcpFacilityShiftFilterData}
                                                                           columns={shiftTimingsColumn}
                                                    />
                                                </div>
                                            </TabContentComponent>
                                        </TabsWrapperComponent>
                                    </div>
                                </div>
                            </>

                        </div>
                    </div>
                </>}
            </div>
        </>
    );
};

export default CancelledShiftDetailsComponent;
