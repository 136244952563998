import {IActionModel} from "../../shared/models/action.model";
import {
    SET_DATE_RANGE,
    SET_SELECTED_FACILITY,
    SET_OPEN_SHIFT_STATUS,
    SET_SELECTED_HCP_TYPE_DATA,
    SET_SELECTED_SHIFT_DATA,
    SET_SELECTED_FILTER_COUNT,
    SET_SHIFT_FILTER_DATA
} from "../actions/filter.action"


export interface IFilterDataReducerState {
    dateRange:any[];
    selectedFacility:any;
    selectedOpenShiftStatus:any;
    selectedHcpTypeData:any;
    selectedShiftData:any;
    filterCount:number;
    shiftFilterData:any;
}

const initialData: IFilterDataReducerState = {
    dateRange:[null,null],
    selectedFacility:null,
    selectedHcpTypeData:[],
    selectedOpenShiftStatus:[],
    selectedShiftData:[],
    filterCount:0,
    shiftFilterData:{
        status: 'open',
        open_status_type: 'open',
        requirementType: 'Open Shift',
    }
};


const FilterDataReducer =(state = initialData, action: IActionModel):IFilterDataReducerState=>{
    switch(action.type){
        case SET_DATE_RANGE:
            return {
                ...state,
                dateRange:action.payload
            };
        case SET_SELECTED_FACILITY:
            return {
                ...state,
                selectedFacility:action.payload
            };
        case SET_OPEN_SHIFT_STATUS:
            return {
                ...state,
                selectedOpenShiftStatus:action.payload
            }
        case SET_SELECTED_HCP_TYPE_DATA:
            return {
                ...state,
                selectedHcpTypeData:action.payload
            }
        case SET_SELECTED_SHIFT_DATA:
            return {
                ...state,
                selectedShiftData:action.payload
            }
        case SET_SELECTED_FILTER_COUNT:
            return {
                ...state,
                filterCount:action.payload
            }
        case SET_SHIFT_FILTER_DATA:
            return {
                ...state,
                shiftFilterData:action.payload
            }
        default:
            return state;
    }

}

export default FilterDataReducer;
