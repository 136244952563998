import {IUserRole} from "../../shared/models/common.model";


export const GET_USER_ROLE_LIST = 'GET_USER_ROLE_LIST';
export const SET_USER_ROLE_LIST = 'SET_USER_ROLE_LIST';


export const GET_FACILITY_LIST_LITE = 'GET_FACILITY_LIST_LITE';
export const SET_FACILITY_LIST_LITE = 'SET_FACILITY_LIST_LITE';

export const GET_AGENCY_LIST_LITE = 'GET_AGENCY_LIST_LITE';
export const SET_AGENCY_LIST_LITE = 'SET_AGENCY_LIST_LITE';

export const GET_FACILITY_AGENCY_LIST_LITE = 'GET_FACILITY_AGENCY_LIST_LITE';
export const SET_FACILITY_AGENCY_LIST_LITE = 'SET_FACILITY_AGENCY_LIST_LITE';
export const GET_WINGS_LIST_LITE = 'GET_WINGS_LIST_LITE';
export const SET_WINGS_LIST_LITE = 'SET_WINGS_LIST_LITE';

export const GET_REGION_LIST_API = 'GET_REGION_LIST_API';
;
export const SET_REGION_LIST_API = 'SET_REGION_LIST_API';

export const getFacilityListLite = () => {
    return {type: GET_FACILITY_LIST_LITE};
};

export const setFacilityListLite = (facilityList: []) => {
    return {
        type: SET_FACILITY_LIST_LITE, payload: {
            facilityList
        }
    };
};

export const getAgencyListLite = (selectedAgencies: any) => {
    return {type: GET_AGENCY_LIST_LITE, selectedAgencies};
};

export const setAgencyListLite = (agencyList: []) => {
    return {
        type: SET_AGENCY_LIST_LITE, payload: {
            agencyList
        }
    };
};

export const getFacilityAgencyListLite = (facilityId: any) => {
    return {type: GET_FACILITY_AGENCY_LIST_LITE, facilityId};
};

export const setFacilityAgencyListLite = (agencyList: []) => {
    return {
        type: SET_FACILITY_AGENCY_LIST_LITE, payload: {
            agencyList
        }
    };
};
export const getRegionList = () => {
    return {type: GET_REGION_LIST_API};
};

export const setRegionList = (regionList: []) => {
    return {
        type: SET_REGION_LIST_API, payload: {
            regionList
        }
    };
};
export const getWingsListLite = (facilityId: any) => {
    return {
        type: GET_WINGS_LIST_LITE, payload: {
            facilityId
        }
    };
};

export const setWingsListLite = (wingsList: []) => {
    return {
        type: SET_WINGS_LIST_LITE, payload: {
            wingsList
        }
    };
};

export const getUserRoleList = () => {
    return {type: GET_USER_ROLE_LIST};
};

export const setUserRoleList = (userRoleList: IUserRole[]) => {
    return {
        type: SET_USER_ROLE_LIST, payload: {
            userRoleList
        }
    };
};

