import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {PropsWithChildren, useCallback, useEffect} from "react";
// import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import FormikTimePickerComponent
    from "../../../shared/components/form-controls/formik-time-picker/FormikTimePickerComponent";
import "./ApproveApplicationComponent.scss";
import {CommonService} from "../../../shared/services";
import {ImageConfig, Misc} from "../../../constants";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePickers from "react-multi-date-picker";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import moment from "moment";

export interface ApproveShiftProps {
    selectedApplication?: any;
    selectedApplicationForApproval?: any;
    setIsApproveBoxOpen?: any;
    setActiveTab?: any;
    setPendingFilterData?: any;
    recall?: any;
    pendingFilterData?: any;
    status?: any;
    setOpenDrawer?: any
    openFilterData?: any
    setOpenFilterData?: any
    pendingListFilterData?: any
    setPendingListFilterData?: any
    getShiftRequirementDetails?: any
    setSchedulerOpenFilterData?: any
    schedulerOpenFilterData?: any
    setSchedulingListFilterState?: any
    schedulingListFilterState?: any
    visitPatientType?: any;
    isOnCalender?: any
    shiftRequirementDetails?: any
}

const ApproveApplicationComponent = (props: PropsWithChildren<ApproveShiftProps>) => {
    const {
        selectedApplicationForApproval,
        setIsApproveBoxOpen,
        setActiveTab,
        setPendingFilterData,
        pendingFilterData,
        recall,
        status,
        setOpenDrawer,
        openFilterData,
        setOpenFilterData,
        pendingListFilterData,
        setPendingListFilterData,
        getShiftRequirementDetails,
        setSchedulerOpenFilterData,
        schedulerOpenFilterData,
        setSchedulingListFilterState,
        schedulingListFilterState,
        visitPatientType,
        isOnCalender,
        shiftRequirementDetails
    } = props;
    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setIsLoading(true)
        const payload = {
            application_ids: values.applications.map((app: any, index: number) => ({
                application_id: selectedApplicationForApproval[index]?._id,
                differential: app.differential,
                shift_dates: app.shift_dates.map((date: any) =>
                    CommonService.convertDateFormat(date, 'YYYY-MM-DD')
                ),
                start_time: CommonService.convertToAdjustedMinutes(app.start_time),
                end_time: CommonService.convertToAdjustedMinutes(app.end_time),
            })),
        };


        let apiCall: any = {};
        apiCall = CommonService._shift_management.approveHomeHealthApplications(payload);

        apiCall.then((response: IAPIResponseType<any>) => {
            if (status === "pending-shift") {
                setOpenDrawer(false);
                if (isOnCalender) {
                    setPendingFilterData({...pendingFilterData});
                } else {
                    setPendingFilterData({...pendingFilterData, status: "pending"});
                }
                setIsApproveBoxOpen(false);
            } else if (status === "open-details") {
                recall();
                setActiveTab("approved");
                setOpenFilterData({...openFilterData, status: "approved"});
                setIsApproveBoxOpen(false);
            } else if (status === "list") {
                setPendingListFilterData({...pendingListFilterData});
                setIsApproveBoxOpen(false);
            } else if (status === "scheduler-open-details") {
                getShiftRequirementDetails();
                setActiveTab("approved");
                setSchedulerOpenFilterData({...schedulerOpenFilterData, status: "approved"});
                setIsApproveBoxOpen(false);
                setSchedulingListFilterState({...schedulingListFilterState});
            }
            setIsLoading(false);

            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                CommonService._alert.showToast(error?.error, "error");
                setIsLoading(false);
            }).finally(() => {
            setSubmitting(false);
        });

    }, [isOnCalender, openFilterData, setOpenFilterData, pendingFilterData, pendingListFilterData, setPendingListFilterData, recall, status, setActiveTab, selectedApplicationForApproval, setPendingFilterData, setIsApproveBoxOpen, setOpenDrawer, setIsLoading, getShiftRequirementDetails, setSchedulerOpenFilterData, schedulerOpenFilterData, schedulingListFilterState, setSchedulingListFilterState]);
    useEffect(() => {
        console.log(selectedApplicationForApproval);
    }, [selectedApplicationForApproval]);
    // console.log(payload);
    return (
        <div className="create-shift mrg-10">
            <div>
                <div className="approves-popup-open-details-header">
                    <div className="form-header-text">Approve Home Health Shifts
                    </div>
                    <div
                        className="icon-button"
                        onClick={() => {
                            setIsApproveBoxOpen(false);
                        }}
                    >
                        <ImageConfig.CrossIcon/>
                    </div>
                </div>
            </div>
            <div className="mrg-bottom-20">
                <HorizontalLineComponent/>
            </div>
            <Formik
                initialValues={{
                    applications: selectedApplicationForApproval.map(() => ({
                        differential: '',
                        shift_dates: [],
                        start_time: '',
                        end_time: '',
                    })),
                }}
                validateOnChange={true}
                // validationSchema={shiftsFormValidation}
                onSubmit={onSubmit}
            >
                {({setFieldValue, values}) => (
                    <Form>
                        <FieldArray name="applications">
                            {({push, remove}) => (
                                <div>
                                    {selectedApplicationForApproval?.map((perDiem: any, index: any) => (
                                        <div className="approval-shift-card" key={index}>
                                            <div className={'d-flex mrg-bottom-5'}>

                                                <div
                                                    className={'hcp-name-for-approval '}>{perDiem?.hcp_details?.first_name + " " + perDiem?.hcp_details?.last_name} |&nbsp;</div>
                                                <div
                                                    className={'hcp-other-details-for-approval-text '}> {perDiem?.facility_details?.name || shiftRequirementDetails?.facility_details?.data?.name} |&nbsp;</div>
                                                <div className={'hcp-other-details-for-approval-text '}>
                                                    {shiftRequirementDetails?.hcp_type_details?.title || perDiem?.hcp_type_details?.title} |&nbsp;
                                                </div>
                                                <div className={'hcp-other-details-for-approval-text '}>
                                                    {perDiem?.requirement_details?.shift_type || perDiem?.shift_type}
                                                </div>


                                            </div>
                                            <div className="mrg-top-5">
                                                <div className={'d-flex'}>
                                                    <LabelComponent title="Visit Patient By: "/>
                                                    <div className="visit-patient-by-text" onClick={() => {
                                                        console.log(perDiem);
                                                    }}>
                                                        {CommonService.convertDateFormat2(perDiem?.requirement_details?.shift_date || perDiem?.shift_date, 'MM-DD-YYYY')} |&nbsp;
                                                        {CommonService?.convertMinutesToTime(visitPatientType || perDiem?.requirement_details?.hospice_details?.visit_patient_time || perDiem?.hospice_details?.visit_patient_time || perDiem?.requirement_details?.shift_timings?.end_time || shiftRequirementDetails?.hospice_details?.visit_patient_time)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="shift-requirement-divider-line mrg-bottom-10"/>
                                            <div className="ts-row">
                                                <div className="ts-col-4">
                                                    <LabelComponent title="Date(s)"/>
                                                    <div className="shift-calender mrg-bottom-20">
                                                        <Field
                                                            inputClass="custom-input"
                                                            className="rmdp-mobile"
                                                            plugins={[<DatePanel eachDaysInRange/>]}
                                                            format="MM/DD/YYYY"
                                                            multiple
                                                            onChange={(dates: any) => {
                                                                const shift_dates = dates.map((item: any) => {
                                                                    let mm = item?.month?.number.toString().padStart(2, '0');
                                                                    let dd = item?.day.toString().padStart(2, '0');
                                                                    let yyyy = item?.year;

                                                                    // Format using moment with explicit UTC handling
                                                                    return moment.utc(`${yyyy}-${mm}-${dd}`).format('YYYY-MM-DD');
                                                                });

                                                                // Update the Formik state with the formatted dates
                                                                setFieldValue(`applications[${index}].shift_dates`, shift_dates);
                                                            }}
                                                            value={values.applications[index]?.shift_dates.map((date: any) => new Date(date))} // Display selected dates correctly
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            placeholder="Select"
                                                            name={`applications[${index}].shift_dates`}
                                                            component={DatePickers}
                                                            minDate={new Date()}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="ts-col-4">
                                                    <LabelComponent title="Start Time"/>
                                                    <Field name={`applications[${index}].start_time`}>
                                                        {(field: FieldProps) => (
                                                            <FormikTimePickerComponent
                                                                placeholder="Start Time"
                                                                formikField={field}
                                                                fullWidth
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                <div className="ts-col-4">
                                                    <LabelComponent title="End Time"/>
                                                    <Field name={`applications[${index}].end_time`}>
                                                        {(field: FieldProps) => (
                                                            <FormikTimePickerComponent
                                                                placeholder="End Time"
                                                                formikField={field}
                                                                fullWidth
                                                            />
                                                        )}
                                                    </Field>
                                                </div>

                                                {/*<div className="ts-col-2">*/}
                                                {/*    <Field name={`applications[${index}].differential`}>*/}
                                                {/*        {(field: FieldProps) => (*/}
                                                {/*            <FormikInputComponent*/}
                                                {/*                label="Differential Rate"*/}
                                                {/*                placeholder="Enter"*/}
                                                {/*                formikField={field}*/}
                                                {/*                type="number"*/}
                                                {/*                fullWidth*/}
                                                {/*            />*/}
                                                {/*        )}*/}
                                                {/*    </Field>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </FieldArray>
                        <div
                            className={'d-flex ts-justify-content-center ts-align-content-center mrg-top-15'}
                        >
                            <ButtonComponent
                                disabled={isLoading}
                                isLoading={isLoading}
                                type={'submit'}
                                style={{backgroundColor: '#192938', color: 'white'}}
                            >
                                Save and Approve
                            </ButtonComponent>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ApproveApplicationComponent;
