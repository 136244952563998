import {call, put, takeEvery} from "redux-saga/effects";
import {CommonService} from "../../shared/services";
import {
    GET_PROPERTY_FM_LIST,
    GET_PROPERTY_LIST_LITE,
    setPropertyFMList,
    setPropertyListLite
} from "../actions/property.action";

function* getAllPropertiesList() {
    try {
// @ts-ignore
        const resp = yield call(CommonService._property.GetPropertyListLiteAPICall, {getTowers: "true"});
        const propertyList = resp?.data;
        const propertyListLite = propertyList.map((property: any) => {
            return {
                _id: property?._id,
                name: property?.name,
                towers: property?.towers.map((tower: any) => {
                    const towerTransformed = tower;
                    const floors = [];
                    for (let i = 0; i < tower?.numberOfOfficeFloors; i++) {
                        floors.push('Floor ' + (i + 1));
                    }
                    for (let i = 0; i < tower?.numberOfBasements; i++) {
                        floors.push('Basement ' + (i + 1));
                    }
                    towerTransformed.floors = floors;
                    return towerTransformed;
                })
            }
        });
        yield put(setPropertyListLite(propertyListLite));
    } catch (error: any) {
        yield put(setPropertyListLite([]));
    }
}


function* getPropertyFMsList(action: any) {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._property.GetPropertyFMList, action?.payload?.propertyId);
        yield put(setPropertyFMList(resp?.data));
    } catch (error: any) {
        yield put(setPropertyFMList([]));
    }
}


export default function* propertySaga() {
    yield takeEvery(GET_PROPERTY_LIST_LITE, getAllPropertiesList);
    yield takeEvery(GET_PROPERTY_FM_LIST, getPropertyFMsList);

}
