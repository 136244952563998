import React, {useCallback, useEffect, useRef, useState} from "react";
import "./SchedulerCalenderComponent.scss";
import FullCalendarComponent from "../../../shared/components/full-calendar/FullCalendarComponent";
import IconButtonComponent from "../../../shared/components/icon-button/IconButtonComponent";
import {CommonService} from "../../../shared/services";
import {ImageConfig} from "../../../constants";
import moment from "moment";
// import LinkComponent from '../../../shared/components/link/LinkComponent';
// import ButtonComponent from '../../../shared/components/button/ButtonComponent'
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import ShiftCardComponent from "./shift-card/ShiftCardComponent";
import DialogComponent from "../../../shared/components/dialog-component/DialogComponent";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
// import AddShiftDetailsInCalenderComponent
//     from "../shift-requirement/add-new-shift/add-shift-details/AddShiftRequirementDetails";
import SchedulerFilterComponent from "../filter-popup-scheduler/SchedulerFilterComponent";
import SelectDropdownComponent from "../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";
import {
    home_shift_type,
    schedulerCalenderRepresentation, shift_type,
    shift_type_filter,
} from "../../../data";
import OpenShiftViewDetailsCreationComponent
    from "./weekly-daily-open-shift-creation-view-details/OpenShiftCreationViewDetailsComponent";
import ApprovedShiftDetailsComponent
    from "../../shift-requirement/shift-details/approved-shift-details/ApprovedShiftDetailsComponent";
// import Lottie from "react-lottie";
import CancelledShiftDetailsComponent
    from "../../shift-requirement/shift-details/cancelled-shift-details/CancelledShiftDetailsComponent";
import PendingShiftDetailsComponent
    from "../../shift-requirement/shift-details/pending-shift-details/PendingShiftDetails";
// import AddApprovedShift from "../shift-requirement/add-new-shift/approved-shift-creation/AddApprovedShift";
import CustomToggleComponent
    from "../../../shared/components/custom-toogle-using-button-component/CustomToggleComponent";
import AddShiftDetailsComponent
    from "../../shift-requirement/add-new-shift/add-shift-details/AddShiftRequirementDetails";
import commonService from "../../../shared/services/common.service";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import CompletedShiftDetailsComponent
    from "../../shift-requirement/shift-details/completed-shift-details/CompletedShiftDetailsComponent";
import ConfirmedShiftDetailsComponent
    from "../../shift-requirement/shift-details/confirmed-shift-details/ConfirmedShiftDetailsComponent";
import SchedulerLegendComponent from "../legend-scheduler/SchedulerLegendComponent";
import ShiftCreationPopupComponent from "../../shift-requirement/shift-creation-popup/ShiftCreationPopupComponent";
import AddPreApprovedShiftDetailsComponent
    from "../../shift-requirement/add-pre-approved-shift/add-pre-approved-shit-details/AddPreApprovedShiftDetailsComponent";
import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";
// import SearchCheckboxComponent from "../../../shared/components/search-checkbox/SearchCheckboxComponent";

const SchedulerCalenderComponent = () => {
    // const classes = useStyles();
    const [schedulingListFilterState, setSchedulingListFilterState] = useState<any>({
        search: "",
        start_date: moment().startOf('week').format('YYYY-MM-DD'),
        end_date: moment().endOf('week').format('YYYY-MM-DD'),
        sort: {},
        mode: "week",
        //  statuses: ["partially_open", "open", "approved", "pending"],
    });
    const [calendarData, setCalendarData] = useState<any>(null);
    const [shiftListDateBasis, setShiftListDateBasis] = useState<any>(null);
    const [shiftBasedOnStatusList, setShiftBasedOnStatusList] = useState<any>(null);
    const [isShiftListByDateIsLoading, setIsShiftListByDateIsLoading] = useState<any>(false);
    const [selectedDate, setSelectedDate] = useState<any>(null);
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    //  const [openCreateApprovedShiftDrawer, setOpenCreateApprovedShiftDrawer] = useState<boolean>(false);
    const [isShiftDetailsDrawerOpen, setIsShiftDetailsDrawerOpen] = useState<boolean>(false);
    const {currentUser} = useSelector((state: IRootReducerState) => state.account);
    const [openOpenShiftRequirementDialog, setOpenOpenShiftRequirementDialog] = useState<boolean>(false);
    const [requirementId, setRequirementId] = useState<any>(null);
    const [openApprovedShiftDrawer, setOpenApprovedShiftDrawer] = useState<boolean>(false);
    const [openPendingShiftDrawer, setOpenPendingShiftDrawer] = useState<boolean>(false);
    const [shiftDetails, setShiftDetails] = useState<any>(null);
    const [pendingShiftDetails, setPendingShiftDetails] = useState<any>(null);
    const [shiftTypeHeader, setShiftTypeHeader] = useState("Create Shift");
    const [isInternalView, setIsInternalView] = useState(false);
    const [selectedDateFromCalender, setSelectedDateFromCalender] = useState<any>(null);
    const [isShiftCreationForSingleDate, setIsShiftCreationForSingleDate] = useState<boolean>(false);
    const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false);
    const [selectedShiftData,setSelectedShiftData]=useState<any>([]);
    const scrollingDivRef = useRef<HTMLDivElement>(null);
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [openDialogForPreApprovedShiftCreation,setOpenDialogForPreApprovedShiftCreation]=useState<boolean>(false)
    const [preApprovedShiftHeader,setPreApprovedShiftHeader]=useState<string>('Create Pre Approved Shift Requirement')
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);


    useEffect(() => {
        if (scrollingDivRef) {
            if (!isCalendarLoading) {
                const scrollingDiv: any = scrollingDivRef?.current;
                const seventyVhInPixels = Math.round(0.75 * window.innerHeight);
                if (scrollingDiv?.clientHeight === seventyVhInPixels) {
                    setIsScrolling(true)
                } else {
                    setIsScrolling(false)
                }
            }
        }
        // setSchedulingListFilterState({...schedulingListFilterState});
    }, [isCalendarLoading, scrollingDivRef, schedulingListFilterState]);

    const dateSwitcher = useCallback(
        (modeType: 'increasing' | 'decreasing' | 'reset', mode: string) => {
            setSchedulingListFilterState((old: any) => {
                const startDate = moment(old.start_date)
                let endDate;
                if (modeType === 'increasing') {
                    if (mode === 'day') {
                        startDate.add(1, 'day');
                        endDate = startDate.clone();
                    } else if (mode === '3day') {
                        startDate.add(3, 'day');
                        endDate = startDate.clone().add(2, 'day');
                    } else if (mode === '5day') {
                        startDate.add(5, 'day');
                        endDate = startDate.clone().add(4, 'day');
                    } else if (mode === 'week') {
                        startDate.add(7, 'day');
                        endDate = startDate.clone().add(6, 'day');
                    } else if (mode === 'month') {
                        startDate.add(1, 'month').startOf('month');
                        endDate = startDate.clone().endOf('month');
                    }
                } else if (modeType === 'decreasing') {
                    if (mode === 'day') {
                        startDate.subtract(1, 'day');
                        endDate = startDate.clone();
                    } else if (mode === '3day') {
                        startDate.subtract(3, 'day');
                        endDate = startDate.clone().add(2, 'day');
                    } else if (mode === '5day') {
                        startDate.subtract(5, 'day');
                        endDate = startDate.clone().add(4, 'day');
                    } else if (mode === 'week') {
                        startDate.subtract(7, 'day');
                        endDate = startDate.clone().add(6, 'day');
                    } else if (mode === 'month') {
                        startDate.subtract(1, 'month').startOf('month');
                        endDate = startDate.clone().endOf('month');
                    }
                } else if (modeType === 'reset') {
                    if (mode === 'day') {
                        startDate.startOf('day');
                        endDate = startDate.clone();
                    } else if (mode === '3day') {
                        endDate = startDate.clone().add(2, 'day');
                    } else if (mode === '5day') {
                        endDate = startDate.clone().add(4, 'day');
                    } else if (mode === 'week') {
                        endDate = startDate.clone().add(6, 'day');
                    } else if (mode === 'month') {
                        startDate.startOf('month');
                        endDate = startDate.clone().endOf('month');
                    }
                }
                const start_date = startDate.format('YYYY-MM-DD');
                const end_date = (endDate || startDate).format('YYYY-MM-DD');
                return {...old, start_date, end_date};
            });
        },
        [],
    );


    const getCalenderList = useCallback((payload: any) => {
        delete payload.sort;
        if (schedulingListFilterState?.shift_types) {
            payload.shift_types = schedulingListFilterState?.shift_types;
        } else {
            delete schedulingListFilterState?.shift_types;
        }
        setCalendarData(null);
        setIsCalendarLoading(true);
        CommonService._shift_management.getShiftsCalendarList(payload)
            .then((response: IAPIResponseType<any>) => {
                const data = response.data || {};
                setCalendarData(data);
                setIsCalendarLoading(false);
            })
            .catch((error: any) => {
                setIsCalendarLoading(false);
            });

    }, [schedulingListFilterState?.shift_types]);


    const getCalenderWeekList = useCallback((payload: any) => {
        delete payload.sort;
        if (schedulingListFilterState?.shift_types) {
            payload.shift_types = schedulingListFilterState?.shift_types;
        } else {
            delete schedulingListFilterState?.shift_types;
        }
        setCalendarData(null);
        setIsCalendarLoading(true);
        CommonService._shift_management.getShiftsCalenderWeekList(payload)
            .then((response: IAPIResponseType<any>) => {
                const data = response.data || {};
                setCalendarData(data);
                setIsCalendarLoading(false);
            })
            .catch((error: any) => {
                setIsCalendarLoading(false);
            });

    }, [schedulingListFilterState?.shift_types]);

    const getCalenderDayList = useCallback((payload: any) => {
        delete payload.sort;
        if (schedulingListFilterState?.shift_types) {
            payload.shift_types = schedulingListFilterState?.shift_types;
        } else {
            delete schedulingListFilterState?.shift_types;
        }
        setCalendarData(null);
        setIsCalendarLoading(true);
        CommonService._shift_management.getShiftsCalenderWeekList(payload)
            .then((response: IAPIResponseType<any>) => {
                const data = response.data || {};
                setCalendarData(data);
                setIsCalendarLoading(false);
            })
            .catch((error: any) => {
                setIsCalendarLoading(false);
            });
    }, [schedulingListFilterState?.shift_types]);


    const getShiftDataByDate = useCallback((payload: any) => {
        setIsShiftListByDateIsLoading(true);
        if (selectedDate) {
            payload.start_date = selectedDate || schedulingListFilterState?.start_date;
            payload.end_date = selectedDate || schedulingListFilterState?.end_date;
        }
        if (schedulingListFilterState?.shift_types) {
            payload.shift_types = schedulingListFilterState?.shift_types;
        } else {
            delete schedulingListFilterState?.shift_types;
        }

        CommonService._shift_management.getShiftListByDate(payload)
            .then((response: IAPIResponseType<any>) => {
                const data = response.data || {};
                setShiftListDateBasis(data);
                setIsShiftListByDateIsLoading(false);
            })
            .catch((error: any) => {
                setIsShiftListByDateIsLoading(false);
            });

    }, [selectedDate, schedulingListFilterState]);

    useEffect(() => {
        if (schedulingListFilterState?.mode === "month") {
            getCalenderList({...schedulingListFilterState});
        } else if (schedulingListFilterState?.mode === "week") {
            getCalenderWeekList({...schedulingListFilterState});
        } else if (schedulingListFilterState?.mode === "day") {
            getCalenderDayList({...schedulingListFilterState});
        }

    }, [schedulingListFilterState, getCalenderList, getCalenderWeekList, getCalenderDayList]);

    useEffect(() => {
        getShiftDataByDate({...schedulingListFilterState});
    }, [getShiftDataByDate, schedulingListFilterState]);

    useEffect(() => {
        if (selectedStatus) {
            const selectedShifts = shiftListDateBasis?.shift_data?.filter((shift: any) => shift?.status === selectedStatus);
            setShiftBasedOnStatusList(selectedShifts);
        } else {
            setShiftBasedOnStatusList(shiftListDateBasis);
        }
    }, [shiftListDateBasis, selectedStatus]);


    const toggleInternalView = useCallback((e: any) => {
        setIsInternalView(prevIsInternalView => !prevIsInternalView);
    }, []);

    useEffect(() => {
        if (isInternalView) {
            setSchedulingListFilterState((prevState: any) => ({
                ...prevState,
                is_internal_hcp: true
            }));
        } else {
            setSchedulingListFilterState((prevState: any) => {
                const {is_internal_hcp, ...rest} = prevState;
                return {...rest};
            });
        }
    }, [isInternalView]);


    const mapShifts = useCallback((approvedShifts: any) => {
        if (!approvedShifts || typeof approvedShifts !== 'object') {
            return []; // Return an empty array or handle the error as needed
        }

        return Object.keys(approvedShifts).map(hcpName => {
            const hcpShifts = approvedShifts[hcpName];

            if (!hcpShifts || typeof hcpShifts !== 'object') {
                return {hcpName, shiftsByDate: []}; // Handle undefined or null hcpShifts
            }

            const shiftsByDate = Object.keys(hcpShifts).map(date => ({
                date,
                shifts: hcpShifts[date]
            }));

            return {hcpName, shiftsByDate};
        });
    }, [])


    const mappedShifts = mapShifts(calendarData);

    const getFilterOptions = useCallback(() => {
        if (schedulingListFilterState?.requirementType === 'Open Shift') {
            return shift_type;
        } else if (schedulingListFilterState?.requirementType === 'Home Health') {
            return home_shift_type;
        } else {
            return shift_type_filter;
        }
    }, [schedulingListFilterState]);

    const handleShiftCreationPopup=useCallback((value:string)=>{
        setIsPopupOpen(false)
        setPreApprovedShiftHeader("Create Pre Approved Shift Requirement");
        setShiftTypeHeader("Create Shift Requirement")
        if(value==='add-shift-requirement'){
            CommonService._staticData.openDialogPopup(setOpenDialog,true)
        }else {
            CommonService._staticData.openDialogPopup(setOpenDialogForPreApprovedShiftCreation,true)
        }
    },[])


    const handleShiftSelection=useCallback((selectedOptions:any)=> {
        setSelectedShiftData(selectedOptions)
        const options=selectedOptions?.map((option:any)=>(option.code)) || []
        console.log(options,"OPTIONS")
        const updatedFilterState={
            ...schedulingListFilterState,
            shift_types:options,
        }

        if(selectedOptions?.length === 0){
            delete updatedFilterState?.shift_types
        }

        setSchedulingListFilterState(updatedFilterState)
    },[schedulingListFilterState]);

    // useEffect(()=>{
    //     if(selectedShiftData?.length === 0){
    //         setSchedulingListFilterState({
    //             ...schedulingListFilterState,
    //             shift_types:[]
    //         })
    //     }
    //     console.log(schedulingListFilterState,"STATE")
    // },[schedulingListFilterState,selectedShiftData])

    return (
        <>
            <DrawerComponent onClose={() => {
                CommonService._staticData.openDialogPopup(setOpenApprovedShiftDrawer, false);
            }} isOpen={openApprovedShiftDrawer}>
                {
                    shiftDetails?.status === "approved" &&
                    <ApprovedShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                   setFilterData={setSchedulingListFilterState}
                                                   shiftDetails={shiftDetails}
                                                   setOpenDrawer={setOpenApprovedShiftDrawer}
                                                   dayMonthView={true}
                    />
                }
                {
                    shiftDetails?.status === "completed" &&
                    <CompletedShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                    setFilterData={setSchedulingListFilterState}
                                                    shiftDetails={shiftDetails}
                                                    setOpenDrawer={setOpenApprovedShiftDrawer}
                                                    dayMonthView={true}
                    />
                }
                {
                    shiftDetails?.status === "confirmed" &&
                    <ConfirmedShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                    setFilterData={setSchedulingListFilterState}
                                                    shiftDetails={shiftDetails}
                                                    setOpenDrawer={setOpenApprovedShiftDrawer}
                                                    dayMonthView={true}
                    />
                }
                {
                    shiftDetails?.status === "cancelled" &&
                    <CancelledShiftDetailsComponent filterData={schedulingListFilterState}
                                                    setFilterData={setSchedulingListFilterState} isOnCalender={true}
                                                    shiftDetails={shiftDetails}
                                                    setOpenDrawer={setOpenApprovedShiftDrawer}
                                                    dayMonthView={true}
                    />


                }
            </DrawerComponent>
            <DrawerComponent onClose={() => {
                CommonService._staticData.openDialogPopup(setOpenPendingShiftDrawer, false);
            }} isOpen={openPendingShiftDrawer}>
                {
                    <PendingShiftDetailsComponent isOnCalender={true} filterData={schedulingListFilterState}
                                                  setFilterData={setSchedulingListFilterState}
                                                  shiftDetails={pendingShiftDetails}
                                                  setOpenDrawer={setOpenPendingShiftDrawer}
                                                  dayMonthView={true}
                    />
                }
            </DrawerComponent>

            <DrawerComponent isOpen={isShiftDetailsDrawerOpen} onClose={() => {
                setIsShiftDetailsDrawerOpen(false);
                setSelectedDate(null);
            }}>
                <div className={'edit-facility-component'}>
                    {isShiftListByDateIsLoading && <>
                        <LoaderComponent/>
                    </>
                    }
                    {/*{selectedDate &&*/}
                    <div className={'edit-facility-header'} style={{
                        backgroundImage: `url(${selectedStatus === "partially_open" || selectedStatus === "open"
                            ? ImageConfig.OpenShiftHeaderGradient
                            : selectedStatus === "approved"
                                ? ImageConfig.ApprovedGradient
                                : selectedStatus === "confirmed"
                                    ? ImageConfig.ConfirmedGradient
                                    : selectedStatus === "cancelled"
                                        ? ImageConfig.CancelledGradient
                                        : selectedStatus === "completed" ? ImageConfig.CompletedGradient : selectedStatus === "filled" ? ImageConfig.CompletedGradient : ""})`,
                        backgroundRepeat: "no-repeat"
                    }}>
                        <div
                            className={'form-header-text'}>
                            {selectedStatus === "partially_open" ? "Partially Open" : CommonService.capitalizeFirstLetter(selectedStatus)} Shifts
                            on {selectedDate && CommonService.convertDateFormat(selectedDate
                            // 'Do MMMM YYYY'
                        )}
                        </div>
                        <div className={'icon-button'} onClick={() => {
                            setIsShiftDetailsDrawerOpen(false);
                            setSelectedDate(null);
                        }}>
                            <ImageConfig.CrossIcon/>
                        </div>
                    </div>
                    {/*}*/}
                    <HorizontalLineComponent/>
                    <div className={'shift-details-holder-calender'}>
                        {!isShiftListByDateIsLoading && shiftBasedOnStatusList?.length > 0 && shiftBasedOnStatusList?.map((shift: any, index: number) => {
                            return (
                                <>
                                    <div className={"pdd-10 "}>
                                        <ShiftCardComponent
                                            schedulingListFilterState={schedulingListFilterState}
                                            setSchedulingListFilterState={setSchedulingListFilterState}
                                            shiftDetails={shift}
                                            pendingShiftDetails={pendingShiftDetails}
                                        />
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            </DrawerComponent>
            <DialogComponent cancel={() => {
                CommonService._staticData.openDialogPopup(setOpenDialog, false);
                setSchedulingListFilterState({...schedulingListFilterState});
            }} open={openDialog} maxWidth={"lg"}>
                <div>
                    <div className={'calender-open-details-header'}>
                        <div className={'form-header-text'}>{shiftTypeHeader}</div>
                        <div className={'icon-button'} onClick={() => {
                            CommonService._staticData.openDialogPopup(setOpenDialog, false);
                            setSchedulingListFilterState({...schedulingListFilterState});
                        }}><ImageConfig.CrossIcon/></div>
                    </div>
                    <HorizontalLineComponent/>
                    <AddShiftDetailsComponent setOpenDialog={setOpenDialog}
                                              isShiftCreationForSingleDate={isShiftCreationForSingleDate}
                                              selectedDateFromCalender={selectedDateFromCalender}
                                              setShiftTypeHeader={setShiftTypeHeader} onCalender={true}
                                              schedulingListFilterState={schedulingListFilterState}
                                              setSchedulingListFilterState={setSchedulingListFilterState}
                        // setOpenDialog={setOpenDialog} schedulingListFilterState={schedulingListFilterState} setSchedulingListFilterState={setSchedulingListFilterState}
                    />
                </div>
            </DialogComponent>
            <DialogComponent cancel={() => {
                CommonService._staticData.openDialogPopup(setOpenDialogForPreApprovedShiftCreation, false);
                setSchedulingListFilterState({...schedulingListFilterState});
            }} open={openDialogForPreApprovedShiftCreation} maxWidth={"lg"}>
                <div>
                    <div className={'calender-open-details-header'}>
                        <div className={'form-header-text'}>{preApprovedShiftHeader}</div>
                        <div className={'icon-button'} onClick={() => {
                            CommonService._staticData.openDialogPopup(setOpenDialogForPreApprovedShiftCreation, false);
                            setSchedulingListFilterState({...schedulingListFilterState});
                        }}><ImageConfig.CrossIcon/></div>
                    </div>
                    <HorizontalLineComponent/>
                    <AddPreApprovedShiftDetailsComponent
                        isShiftCreationForSingleDate={isShiftCreationForSingleDate}
                        selectedDateFromCalender={selectedDateFromCalender}
                        onCalender={true}
                        schedulingListFilterState={schedulingListFilterState}
                        setPreApprovedShiftHeader={setPreApprovedShiftHeader}
                        setOpenDialog={setOpenDialogForPreApprovedShiftCreation}
                    />
                </div>
            </DialogComponent>
            <DialogComponent cancel={() => {
                CommonService._staticData.openDialogPopup(setOpenOpenShiftRequirementDialog, false);
            }} open={openOpenShiftRequirementDialog} maxWidth={'xl'}>
                <OpenShiftViewDetailsCreationComponent setSchedulingListFilterState={setSchedulingListFilterState}
                                                       schedulingListFilterState={schedulingListFilterState}
                                                       setOpenOpenShiftRequirementDialog={setOpenOpenShiftRequirementDialog}
                                                       requirementId={requirementId}/>
            </DialogComponent>
            <div className="scheduler-calender-screen mrg-top-30">
                <div className={'calender-component-wrapper'}>
                    <div className="calender-screen-header mrg-top-20 mrg-left-20 mrg-right-20 d-flex"
                         style={{justifyContent: "space-between"}}>
                        <div>
                            {/*<div className={'mrg-bottom-5 home-text'}>Home</div>*/}
                            <div className="scheduler-header-text-property">
                                <div className={'mrg-right-10 mrg-top-5'}>
                                    <ImageConfig.CalenderHeaderIcon/>
                                </div>
                                <div>
                                    <div className={'good-morning-text mrg-bottom-5'}>
                                        Good Morning, {currentUser?.first_name + " " + currentUser?.last_name || "User"}
                                    </div>
                                    <div className={'shift-info-text'}>
                                        {schedulingListFilterState?.mode === "week" && <>
                                            {`You have ${calendarData?.stats?.approved_shifts_count
                                            || 0} approved shifts this week`}
                                        </>}
                                        {schedulingListFilterState?.mode === "day" && <>
                                            {`You have ${calendarData?.stats?.approved_shifts_count
                                            || 0} approved shifts this day`}
                                        </>}
                                        {schedulingListFilterState?.mode === "month" && <>
                                            {`You have ${shiftListDateBasis?.total_stats?.approved_shifts
                                            || 0} approved shifts this month`}
                                        </>}
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={'d-flex mrg-bottom-15'}>
                            <div className={'d-flex justify-content-center align-items-center mrg-right-15'}>
                                <CustomToggleComponent defaultOn={isInternalView} toggleHandler={toggleInternalView}/>
                                <div className={'mrg-left-10 internal-view-switch'}>
                                    Switch to internal view
                                </div>
                            </div>
                            <div className={'mrg-right-10 d-flex'}>
                                <div className={'mrg-right-10'}>
                                    <SchedulerFilterComponent setFilterData={setSchedulingListFilterState}
                                                              filterData={schedulingListFilterState}/>
                                </div>
                                <ShiftCreationPopupComponent
                                    isPopupOpen={isPopupOpen}
                                    setIsPopupOpen={setIsPopupOpen}
                                    handleShiftCreationPopup={handleShiftCreationPopup}
                                />
                                {/*<LinkComponent*/}
                                {/*    onClick={() => {*/}
                                {/*    CommonService._staticData.openDialogPopup(setOpenDialog, true);*/}
                                {/*    setIsShiftCreationForSingleDate(false)*/}
                                {/*}}>*/}
                                {/*    <ButtonComponent color={'primary'} prefixIcon={<ImageConfig.AddOutlinedIcon/>}>*/}
                                {/*        Create Shift*/}
                                {/*    </ButtonComponent>*/}
                                {/*</LinkComponent>*/}
                            </div>
                        </div>
                    </div>
                    <HorizontalLineComponent/>
                    <div className="scheduling-filter-header-wrapper">
                        <div className="scheduling-filter-header-date-wrapper">
                            <div className="filter-header-date-controls">
                                <div className={'filter-shift-type-box'}>
                                    <SearchCheckboxComponent
                                        options={getFilterOptions()}
                                        placeholder={"Select Shift Type"}
                                        noDataText={"No Shift Type"}
                                        selectedOptions={selectedShiftData}
                                        onChange={handleShiftSelection}
                                        />
                                    {/*<SelectDropdownComponent className={"select-box-width"}*/}
                                    {/*                         options={getFilterOptions()}*/}
                                    {/*                         placeholder={"select shift type"}*/}
                                    {/*                         isClearable={true}*/}
                                    {/*                         label={''}*/}
                                    {/*                         value={schedulingListFilterState.shift_types}*/}
                                    {/*                         valueExtractor={item => item.code}*/}
                                    {/*                         displayWith={item => item.title}*/}
                                    {/*                         onUpdate={*/}
                                    {/*                             (value) => {*/}
                                    {/*                                 setSchedulingListFilterState({*/}
                                    {/*                                     ...schedulingListFilterState,*/}
                                    {/*                                     shift_types: value*/}
                                    {/*                                 });*/}
                                    {/*                             }*/}
                                    {/*                         }*/}
                                    {/*                         fullWidth={true}*/}
                                    {/*/>*/}
                                </div>
                                <div className={'center-element'}>
                                    <div className="filter-header-date-control-item mrg-right-20">
                                        <IconButtonComponent color={'primary'}
                                                             onClick={dateSwitcher.bind(null, 'decreasing', schedulingListFilterState.mode || 'month')}>
                                            <ImageConfig.LeftArrow/>
                                        </IconButtonComponent>
                                    </div>
                                    {schedulingListFilterState.mode !== 'month' ?
                                        <div className={'filter-header-date-text'}>
                                            {CommonService.convertDateFormat(
                                                schedulingListFilterState.start_date
                                                // schedulingListFilterState.mode === 'month'
                                                //     ? 'MMMM YYYY'
                                                //     : 'Do MMMM YYYY'
                                            )}
                                            {schedulingListFilterState.mode !== 'day' &&
                                                <span> - {CommonService.convertDateFormat(
                                                    schedulingListFilterState.end_date
                                                    // 'Do MMMM YYYY'
                                                )}</span>}
                                        </div> : <div className={'filter-header-date-text'}>
                                            {CommonService.convertDateFormat(
                                                schedulingListFilterState.start_date,
                                                schedulingListFilterState.mode === 'month'
                                                    ? 'MMMM YYYY'
                                                    : 'DD-MMM-YYYY'
                                            )}

                                        </div>
                                    }
                                    <div className="filter-header-date-control-item mrg-left-20">
                                        <IconButtonComponent
                                            onClick={dateSwitcher.bind(null, 'increasing', schedulingListFilterState.mode || 'month')}>
                                            <ImageConfig.RightArrow/>
                                        </IconButtonComponent>
                                    </div>
                                </div>
                                <div className={'d-flex'}>
                                    <div>
                                        <SelectDropdownComponent className={"select-box-width"}
                                                                 options={
                                                                     [
                                                                         {value: 'month', label: 'Month'},
                                                                         {value: 'day', label: '1 Day'},
                                                                         {value: 'week', label: '7 Days'}
                                                                     ]
                                                                 }
                                                                 isClearable={false}
                                                                 label={''}
                                                                 value={schedulingListFilterState.mode || 'month'}
                                                                 valueExtractor={item => item.value}
                                                                 displayWith={item => item.label}
                                                                 onUpdate={
                                                                     (value) => {
                                                                         console.log(value)
                                                                         console.log(moment(schedulingListFilterState.start_date).format('llll'));
                                                                         if (moment(schedulingListFilterState.start_date).add(1, 'hour').isBetween(moment().startOf('month'), moment().endOf('month'))) {
                                                                             console.log('is between');
                                                                             setSchedulingListFilterState({
                                                                                 ...schedulingListFilterState,
                                                                                 mode: value,
                                                                                 start_date: value === "week" ? moment().startOf('week').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                                                                             });
                                                                         } else {
                                                                             setSchedulingListFilterState({
                                                                                 ...schedulingListFilterState,
                                                                                 mode: value,
                                                                                 start_date: value === "week" ? moment().startOf('week').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                                                                                 end_date: value === "week" ? moment().add(6, 'days').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),

                                                                             });
                                                                         }
                                                                         dateSwitcher("reset", value);
                                                                     }
                                                                 }
                                                                 fullWidth={true}
                                        />
                                    </div>
                                    <div className="scheduler-tooltip-container">
                                        <div>
                                            <ImageConfig.SchedulerLegendIcon/>
                                        </div>
                                        <div className="scheduler-tooltip">
                                            <SchedulerLegendComponent/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'d-flex'}>
                        <div className={'flex-1'}>
                            {isCalendarLoading && <div className="scheduling-calendar-loader">
                                <LoaderComponent/>
                            </div>}
                            {!isCalendarLoading && schedulingListFilterState.mode === 'month' && <>
                                <FullCalendarComponent
                                    minDate={moment(schedulingListFilterState.start_date).subtract(1, 'months').format('YYYY-MM-DD')}
                                    maxDate={moment(schedulingListFilterState.start_date).add(1, 'year').format('YYYY-MM-DD')}
                                    startDay={schedulingListFilterState.start_date}
                                    disabledDates={[]}
                                    canSelect={false} showControls={false}
                                    onDayRender={(day, dateMoment) => {
                                        const date = dateMoment.format('YYYY-MM-DD');
                                        return (<>
                                                <div key={'row-day-' + day}
                                                     onClick={() => {
                                                     }}
                                                     className={'status-holder-component'}>
                                                    {calendarData && calendarData[date] && <>
                                                        {calendarData[date]?.stats?.open_shifts > 0 &&
                                                            <div
                                                                className={'open icon-button text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("open");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.open_shifts} Open
                                                            </div>
                                                        }
                                                        {
                                                            (schedulingListFilterState?.statuses?.includes("filled") && calendarData[date]?.stats?.filled_shifts > 0) &&
                                                            <div
                                                                className={'filled icon-button text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("filled");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.filled_shifts} Filled
                                                            </div>
                                                        }
                                                        {
                                                            (schedulingListFilterState?.open_status_type?.includes("filled") && calendarData[date]?.stats?.filled_shifts > 0) &&
                                                            <div
                                                                className={'filled icon-button text-resolution mrg-top-5'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("filled");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.filled_shifts} Filled
                                                            </div>
                                                        }
                                                        {
                                                            (schedulingListFilterState?.open_status_type?.includes("unfilled") && calendarData[date]?.stats?.unfilled_shifts > 0) &&
                                                            <div
                                                                className={'unfilled icon-button text-resolution mrg-top-5'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("unfilled");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.unfilled_shifts} Unfilled
                                                            </div>
                                                        }
                                                        {calendarData[date]?.stats?.partially_open_shifts > 0 &&
                                                            <div
                                                                className={'open  mrg-top-5  icon-button text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("partially_open");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.partially_open_shifts} Partially
                                                                Open
                                                            </div>
                                                        }
                                                        {calendarData[date]?.stats?.approved_shifts > 0 &&
                                                            <div
                                                                className={'approved icon-button mrg-top-5 text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("approved");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.approved_shifts} Approved
                                                            </div>}
                                                        {(schedulingListFilterState?.statuses?.includes("pending") && calendarData[date]?.stats?.pending_shifts > 0) &&
                                                            <div
                                                                className={'pending-card icon-button mrg-top-5 text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("pending");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.pending_shifts} Pending
                                                            </div>}
                                                        {calendarData[date]?.stats?.completed_shifts > 0 &&
                                                            <div
                                                                className={'completed icon-button mrg-top-5 text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("completed");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.completed_shifts} Completed
                                                            </div>}
                                                        {calendarData[date]?.stats?.confirmed_shifts > 0 &&
                                                            <div
                                                                className={'confirmed icon-button mrg-top-5 text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("confirmed");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.confirmed_shifts} Confirmed
                                                            </div>}
                                                        {calendarData[date]?.stats?.cancelled_shifts > 0 &&
                                                            <div
                                                                className={'cancelled icon-button mrg-top-5 text-resolution'}
                                                                onClick={() => {
                                                                    setSelectedDate(date);
                                                                    setSelectedStatus("cancelled");
                                                                    CommonService._staticData.openDrawerComponent(setIsShiftDetailsDrawerOpen, true);
                                                                }}>
                                                                {calendarData[date]?.stats?.cancelled_shifts} Cancelled
                                                            </div>}
                                                    </>}
                                                </div>
                                            </>
                                        );
                                    }}
                                />
                            </>
                            }
                            {!isCalendarLoading && schedulingListFilterState.mode !== 'month' &&
                                <div className={'scheduling-calendar-day-wise-holder'}>
                                    <div className={'scheduling-calendar-day-wise-wrapper'}>
                                        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                            {schedulingListFilterState.mode === 'week' &&
                                                <div className={'d-flex'}
                                                     style={{marginRight: `${isScrolling ? "15px" : "0px"}`}}>
                                                    <div
                                                        className={"header-border scheduling-calendar-day-wise-item view-" + (schedulingListFilterState.mode === "week" ? schedulingListFilterState.mode : "new-day")}>
                                                        {schedulingListFilterState.mode !== 'day' &&
                                                            <div className="scheduling-calendar-day-wise-item-header">
                                                            </div>
                                                        }
                                                    </div>
                                                    {Array.from({
                                                        length: (schedulingListFilterState.mode === 'day' ? 1 : schedulingListFilterState.mode === '3day' ? 3 : schedulingListFilterState.mode === '5day' ? 5 : schedulingListFilterState.mode === 'week' ? 7 : 1)
                                                    }, (v, i) => moment(schedulingListFilterState.start_date).add(i, 'days')).map((day, index) => {
                                                        // const date = day.format('YYYY-MM-DD');
                                                        //const shift_key = CommonService?.convertToSnakeCase(schedulingListFilterState?.requirementType)
                                                        return <div key={index}
                                                                    className={"header-border scheduling-calendar-day-wise-item view-" + schedulingListFilterState.mode}
                                                        >
                                                            {schedulingListFilterState.mode !== 'day' &&
                                                                <div
                                                                    className="scheduling-calendar-day-wise-item-header">
                                                                    <div>
                                                                        <span>{day.format('ddd DD')}<span
                                                                            className={'today-text'}>{CommonService?.isToday(day.format('DD-MMM-YYYY')) ? "(Today)" : ""}</span></span>
                                                                    </div>
                                                                    <div className={'icon-button'} onClick={() => {
                                                                        CommonService._staticData.openDialogPopup(setOpenDialog, true);
                                                                        setIsShiftCreationForSingleDate(true)
                                                                        setSelectedDateFromCalender(day);
                                                                    }}>
                                                                        <ImageConfig.CreateShiftHeaderIcon/>
                                                                    </div>
                                                                    {/*<div*/}
                                                                    {/*    className="hover-button">*/}
                                                                    {/*    <ToolTipComponent tooltip={"Create Open Shift"}*/}
                                                                    {/*                      position={"top"}>*/}
                                                                    {/*        <div onClick={() => {*/}
                                                                    {/*            CommonService._staticData.openDialogPopup(setOpenDialog, true);*/}
                                                                    {/*            setIsShiftCreationForSingleDate(true)*/}
                                                                    {/*            setSelectedDateFromCalender(day);*/}
                                                                    {/*        }}*/}
                                                                    {/*             className={'icon-button calender-add-button'}>*/}
                                                                    {/*            <ImageConfig.AddIcon/>*/}
                                                                    {/*        </div>*/}
                                                                    {/*    </ToolTipComponent>*/}
                                                                    {/*</div>*/}
                                                                </div>}
                                                        </div>;
                                                    })
                                                    }
                                                </div>}
                                            <div className={'d-flex'} ref={scrollingDivRef}
                                                 style={{
                                                     maxHeight: "75vh",
                                                     overflow: "auto",
                                                     width: "100%"
                                                 }}>
                                                <div
                                                    className={"scheduling-calendar-day-wise-item view-" + (schedulingListFilterState.mode === "week" ? schedulingListFilterState.mode : "new-day")}>
                                                    <div
                                                        className="scheduling-calendar-hour-block"
                                                    >
                                                        <div>
                                                            <div
                                                                style={{
                                                                    minHeight: "130px",
                                                                }}>
                                                                <div
                                                                    className={'shift-side-bar open-shift-sidebar'}>
                                                                    Open Shifts
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div
                                                        className="scheduling-calendar-hour-block"
                                                    >
                                                        <div className={"d-flex"}>
                                                            <div
                                                                style={{width: "100%"}}
                                                                className={'approved-shift-side-bar approved-shift-sidebar'}>
                                                                <div className={'flex-1'}>
                                                                    {mappedShifts?.map((shift: any) => {
                                                                        const hcpName = CommonService?.convertAndCapitalize(shift?.hcpName)[0] + " " + CommonService?.convertAndCapitalize(shift?.hcpName)[1]
                                                                        return (
                                                                            <>
                                                                                {(shift?.hcpName !== "open_shifts" && shift?.hcpName !== "stats") &&
                                                                                    <div style={{
                                                                                        minHeight: "42px",
                                                                                        maxHeight: "42px",
                                                                                        borderTop: "1px solid #E7E7E7",
                                                                                        borderRight: "1px solid #E7E7E7",
                                                                                    }}
                                                                                         className={"d-flex ts-justify-content-center align-items-center"}>
                                                                                        <div
                                                                                            className={'d-flex ts-justify-content-center align-items-center'}>
                                                                                            <div
                                                                                                className={'scheduler-hcp-name-text flex-1'}>
                                                                                                {
                                                                                                    (hcpName?.length > 12 ?
                                                                                                        <ToolTipComponent
                                                                                                            tooltip={hcpName}
                                                                                                            showArrow={true}
                                                                                                            position={"top"}
                                                                                                        >
                                                                                                            <div
                                                                                                                className={"scheduler-ellipses-for-hcp-name-text property-name"}>
                                                                                                                {hcpName}
                                                                                                            </div>
                                                                                                        </ToolTipComponent> :
                                                                                                        <div
                                                                                                            className="hcp-name"
                                                                                                            style={{
                                                                                                                minWidth: "100px",
                                                                                                                maxWidth: "100px"
                                                                                                            }}>
                                                                                                            {hcpName || '-'}
                                                                                                        </div>)
                                                                                                }
                                                                                            </div>
                                                                                            <div
                                                                                                className={'mrg-left-5'}>
                                                                                                {
                                                                                                    (CommonService?.convertAndCapitalize(shift?.hcpName)[3]?.toUpperCase()) === "AGENCY" &&
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <ImageConfig.AgencyStaff/>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    (CommonService?.convertAndCapitalize(shift?.hcpName)[3]?.toUpperCase()) === "INTERNAL" &&
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <ImageConfig.InternalStaff/>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    (CommonService?.convertAndCapitalize(shift?.hcpName)[3]?.toUpperCase()) === "FLOAT" &&
                                                                                                    <>
                                                                                                        <div>
                                                                                                            <ImageConfig.FloatStaff/>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {Array.from({
                                                    length: (schedulingListFilterState.mode === 'day' ? 1 : schedulingListFilterState.mode === '3day' ? 3 : schedulingListFilterState.mode === '5day' ? 5 : schedulingListFilterState.mode === 'week' ? 7 : 1)
                                                }, (v, i) => moment(schedulingListFilterState.start_date).add(i, 'days')).map((day, index) => {
                                                    const date = day.format('YYYY-MM-DD');
                                                    //const shift_key = CommonService?.convertToSnakeCase(schedulingListFilterState?.requirementType)
                                                    return <div key={index}
                                                                className={"scheduling-calendar-day-wise-item view-" + schedulingListFilterState.mode}>
                                                        <div className="scheduling-calendar-day-wise-item-body">
                                                            <div
                                                                className="scheduling-calendar-hour-block hovering-container "
                                                            >
                                                                <div
                                                                    style={{
                                                                        minHeight: "131px",
                                                                        maxHeight: "131px",
                                                                        borderBottom: "1px solid #E7E7E7",
                                                                        backgroundColor: "#F9F9F9",
                                                                        overflow: "auto"
                                                                    }}>
                                                                    {(calendarData) && <>
                                                                        {
                                                                            calendarData?.open_shifts?.[date]?.map((shift: any) => {
                                                                                let hcpCount = shift?.approved_hcp_count;
                                                                                let exceededHcpCount = 0;
                                                                                if (hcpCount > shift?.required_hcp_count) {
                                                                                    exceededHcpCount = hcpCount - shift?.required_hcp_count;
                                                                                    hcpCount = shift?.required_hcp_count;
                                                                                }
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className={"open-shift-card"}>
                                                                                            <div
                                                                                                className={` ${shift?.requirementType === "Open Shift"
                                                                                                    ? "open-shift-shift-card"
                                                                                                    : shift?.requirementType === "Home Health"
                                                                                                        ? "home-health-shift-card"
                                                                                                        : "contract-travel-shift-card"} scheduler-open-shift-timings-card d-flex cursor-pointer`}
                                                                                                onClick={() => {
                                                                                                    CommonService._staticData.openDialogPopup(setOpenOpenShiftRequirementDialog, true);
                                                                                                    setRequirementId(shift?._id)
                                                                                                }}>
                                                                                                <div
                                                                                                    className={'mrg-right-5'}>
                                                                                                    {
                                                                                                        (() => {
                                                                                                            const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);
                                                                                                            if (foundShiftType) {
                                                                                                                return <>
                                                                                                                    <div
                                                                                                                        className={`scheduler-${shift?.colour_code}-type`}>
                                                                                                                        {
                                                                                                                            (shift?.hcp_type_details?.title)?.length > 3 ?
                                                                                                                                <ToolTipComponent
                                                                                                                                    tooltip={shift?.hcp_type_details?.title}
                                                                                                                                    showArrow={true}
                                                                                                                                    position={"top"}
                                                                                                                                >
                                                                                                                                    <div
                                                                                                                                        className={"scheduler-ellipses-for-week-shift-text property-name"}>
                                                                                                                                        {shift?.hcp_type_details?.title}
                                                                                                                                    </div>
                                                                                                                                </ToolTipComponent> :
                                                                                                                                <div
                                                                                                                                    className="hcp-name">
                                                                                                                                    {shift?.hcp_type_details?.title || '-'}
                                                                                                                                </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </>;
                                                                                                            } else {
                                                                                                                return null; // or some default icon or message if shift type is not found
                                                                                                            }
                                                                                                        })()
                                                                                                    }

                                                                                                </div>
                                                                                                {
                                                                                                    (() => {
                                                                                                        const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);
                                                                                                        if (foundShiftType) {
                                                                                                            return <>
                                                                                                                <div
                                                                                                                    className={"d-flex ts-justify-content-center ts-align-items-center"}>
                                                                                                                    <div>
                                                                                                                        {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.start_time)} - {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.end_time)}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </>;
                                                                                                        } else {
                                                                                                            return null; // or some default icon or message if shift type is not found
                                                                                                        }
                                                                                                    })()
                                                                                                }
                                                                                            </div>
                                                                                            {shift?.applications_request_count > 0 &&
                                                                                                <div
                                                                                                    className="status cursor-pointer"
                                                                                                    onClick={() => {
                                                                                                        CommonService._staticData.openDialogPopup(setOpenOpenShiftRequirementDialog, true);
                                                                                                        setRequirementId(shift?._id)
                                                                                                    }}>{
                                                                                                    shift?.applications_request_count
                                                                                                }</div>}
                                                                                            <div
                                                                                                className="notifications cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    CommonService._staticData.openDialogPopup(setOpenOpenShiftRequirementDialog, true);
                                                                                                    setRequirementId(shift?._id)
                                                                                                }}>
                                                                                                {shift?.approved_hcp_count}/{Number(shift?.required_hcp_count) + Number(exceededHcpCount)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            })
                                                                        }
                                                                    </>}
                                                                    {/*{(calendarData && schedulingListFilterState?.requirementType === "Home Health") && <>*/}
                                                                    {/*    {*/}
                                                                    {/*        calendarData?.home_health?.[date]?.map((shift: any) => {*/}
                                                                    {/*            let hcpCount = shift?.approved_hcp_count;*/}
                                                                    {/*            let exceededHcpCount = 0;*/}
                                                                    {/*            if (hcpCount > shift?.required_hcp_count) {*/}
                                                                    {/*                exceededHcpCount = hcpCount - shift?.required_hcp_count;*/}
                                                                    {/*                hcpCount = shift?.required_hcp_count;*/}
                                                                    {/*            }*/}
                                                                    {/*            return (*/}
                                                                    {/*                <>*/}
                                                                    {/*                    <div*/}
                                                                    {/*                        className={"open-shift-card"}>*/}
                                                                    {/*                        <div*/}
                                                                    {/*                            className={"scheduler-open-shift-timings-card d-flex cursor-pointer"}*/}
                                                                    {/*                            onClick={() => {*/}
                                                                    {/*                                CommonService._staticData.openDialogPopup(setOpenOpenShiftRequirementDialog, true);*/}
                                                                    {/*                                setRequirementId(shift?._id)*/}
                                                                    {/*                            }}>*/}
                                                                    {/*                            <div*/}
                                                                    {/*                                className={'mrg-right-5'}>*/}
                                                                    {/*                                {*/}
                                                                    {/*                                    (() => {*/}
                                                                    {/*                                        const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);*/}
                                                                    {/*                                        if (foundShiftType) {*/}
                                                                    {/*                                            return <>*/}
                                                                    {/*                                                <div*/}
                                                                    {/*                                                    className={`scheduler-${shift?.colour_code}-type`}>*/}
                                                                    {/*                                                    {*/}
                                                                    {/*                                                        (shift?.hcp_type_details?.title)?.length > 3 ?*/}
                                                                    {/*                                                            <ToolTipComponent*/}
                                                                    {/*                                                                tooltip={shift?.hcp_type_details?.title}*/}
                                                                    {/*                                                                showArrow={true}*/}
                                                                    {/*                                                                position={"top"}*/}
                                                                    {/*                                                            >*/}
                                                                    {/*                                                                <div*/}
                                                                    {/*                                                                    className={"scheduler-ellipses-for-week-shift-text property-name"}>*/}
                                                                    {/*                                                                    {shift?.hcp_type_details?.title}*/}
                                                                    {/*                                                                </div>*/}
                                                                    {/*                                                            </ToolTipComponent> :*/}
                                                                    {/*                                                            <div*/}
                                                                    {/*                                                                className="hcp-name">*/}
                                                                    {/*                                                                {shift?.hcp_type_details?.title || '-'}*/}
                                                                    {/*                                                            </div>*/}
                                                                    {/*                                                    }*/}
                                                                    {/*                                                </div>*/}
                                                                    {/*                                            </>;*/}
                                                                    {/*                                        } else {*/}
                                                                    {/*                                            return null; // or some default icon or message if shift type is not found*/}
                                                                    {/*                                        }*/}
                                                                    {/*                                    })()*/}
                                                                    {/*                                }*/}

                                                                    {/*                            </div>*/}
                                                                    {/*                            {*/}
                                                                    {/*                                (() => {*/}
                                                                    {/*                                    const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);*/}
                                                                    {/*                                    if (foundShiftType) {*/}
                                                                    {/*                                        return <>*/}
                                                                    {/*                                            <div*/}
                                                                    {/*                                                className={"d-flex ts-justify-content-center ts-align-items-center"}>*/}
                                                                    {/*                                                <div>*/}
                                                                    {/*                                                    {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.start_time)} - {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.end_time)}*/}
                                                                    {/*                                                </div>*/}
                                                                    {/*                                            </div>*/}
                                                                    {/*                                        </>;*/}
                                                                    {/*                                    } else {*/}
                                                                    {/*                                        return null; // or some default icon or message if shift type is not found*/}
                                                                    {/*                                    }*/}
                                                                    {/*                                })()*/}
                                                                    {/*                            }*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                        <div className="status">{*/}
                                                                    {/*                            shift?.applications_request_count*/}
                                                                    {/*                        }</div>*/}
                                                                    {/*                        <div*/}
                                                                    {/*                            className="notifications">*/}
                                                                    {/*                            {shift?.approved_hcp_count}/{Number(shift?.required_hcp_count) + Number(exceededHcpCount)}*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                    </div>*/}
                                                                    {/*                </>*/}
                                                                    {/*            );*/}
                                                                    {/*        })*/}
                                                                    {/*    }*/}
                                                                    {/*</>}*/}
                                                                    {/*{(calendarData && schedulingListFilterState?.requirementType !== "Home Health" && schedulingListFilterState?.requirementType !== "Open Shift") && <>*/}
                                                                    {/*    {*/}
                                                                    {/*        calendarData?.shift_requirements?.[date]?.map((shift: any) => {*/}
                                                                    {/*            let hcpCount = shift?.approved_hcp_count;*/}
                                                                    {/*            let exceededHcpCount = 0;*/}
                                                                    {/*            if (hcpCount > shift?.required_hcp_count) {*/}
                                                                    {/*                exceededHcpCount = hcpCount - shift?.required_hcp_count;*/}
                                                                    {/*                hcpCount = shift?.required_hcp_count;*/}
                                                                    {/*            }*/}
                                                                    {/*            return (*/}
                                                                    {/*                <>*/}
                                                                    {/*                    <div*/}
                                                                    {/*                        className={"open-shift-card"}>*/}
                                                                    {/*                        <div*/}
                                                                    {/*                            className={"scheduler-open-shift-timings-card d-flex cursor-pointer"}*/}
                                                                    {/*                            onClick={() => {*/}
                                                                    {/*                                CommonService._staticData.openDialogPopup(setOpenOpenShiftRequirementDialog, true);*/}
                                                                    {/*                                setRequirementId(shift?._id)*/}
                                                                    {/*                            }}>*/}
                                                                    {/*                            <div*/}
                                                                    {/*                                className={'mrg-right-5'}>*/}
                                                                    {/*                                {*/}
                                                                    {/*                                    (() => {*/}
                                                                    {/*                                        const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);*/}
                                                                    {/*                                        if (foundShiftType) {*/}
                                                                    {/*                                            return <>*/}
                                                                    {/*                                                <div*/}
                                                                    {/*                                                    className={`scheduler-${shift?.colour_code}-type`}>*/}
                                                                    {/*                                                    {*/}
                                                                    {/*                                                        (shift?.hcp_type_details?.title)?.length > 3 ?*/}
                                                                    {/*                                                            <ToolTipComponent*/}
                                                                    {/*                                                                tooltip={shift?.hcp_type_details?.title}*/}
                                                                    {/*                                                                showArrow={true}*/}
                                                                    {/*                                                                position={"top"}*/}
                                                                    {/*                                                            >*/}
                                                                    {/*                                                                <div*/}
                                                                    {/*                                                                    className={"scheduler-ellipses-for-week-shift-text property-name"}>*/}
                                                                    {/*                                                                    {shift?.hcp_type_details?.title}*/}
                                                                    {/*                                                                </div>*/}
                                                                    {/*                                                            </ToolTipComponent> :*/}
                                                                    {/*                                                            <div*/}
                                                                    {/*                                                                className="hcp-name">*/}
                                                                    {/*                                                                {shift?.hcp_type_details?.title || '-'}*/}
                                                                    {/*                                                            </div>*/}
                                                                    {/*                                                    }*/}
                                                                    {/*                                                </div>*/}
                                                                    {/*                                            </>;*/}
                                                                    {/*                                        } else {*/}
                                                                    {/*                                            return null; // or some default icon or message if shift type is not found*/}
                                                                    {/*                                        }*/}
                                                                    {/*                                    })()*/}
                                                                    {/*                                }*/}

                                                                    {/*                            </div>*/}
                                                                    {/*                            {*/}
                                                                    {/*                                (() => {*/}
                                                                    {/*                                    const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);*/}
                                                                    {/*                                    if (foundShiftType) {*/}
                                                                    {/*                                        return <>*/}
                                                                    {/*                                            <div*/}
                                                                    {/*                                                className={"d-flex ts-justify-content-center ts-align-items-center"}>*/}
                                                                    {/*                                                <div>*/}
                                                                    {/*                                                    {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.start_time)} - {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.end_time)}*/}
                                                                    {/*                                                </div>*/}
                                                                    {/*                                            </div>*/}
                                                                    {/*                                        </>;*/}
                                                                    {/*                                    } else {*/}
                                                                    {/*                                        return null; // or some default icon or message if shift type is not found*/}
                                                                    {/*                                    }*/}
                                                                    {/*                                })()*/}
                                                                    {/*                            }*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                        <div className="status">{*/}
                                                                    {/*                            shift?.applications_request_count*/}
                                                                    {/*                        }</div>*/}
                                                                    {/*                        <div*/}
                                                                    {/*                            className="notifications">*/}
                                                                    {/*                            {shift?.approved_hcp_count}/{Number(shift?.required_hcp_count) + Number(exceededHcpCount)}*/}
                                                                    {/*                        </div>*/}
                                                                    {/*                    </div>*/}
                                                                    {/*                </>*/}
                                                                    {/*            );*/}
                                                                    {/*        })*/}
                                                                    {/*    }*/}
                                                                    {/*</>}*/}
                                                                </div>
                                                            </div>
                                                            {mappedShifts?.length > 0 &&
                                                                <div
                                                                    className="scheduling-calendar-hour-block"
                                                                >
                                                                    <div>
                                                                        {calendarData && <>
                                                                            {
                                                                                // @ts-ignore
                                                                                mappedShifts?.map((item, index: any) => {
                                                                                    return (
                                                                                        <>
                                                                                            {(item?.hcpName !== "open_shifts" && item?.hcpName !== "stats") &&
                                                                                                <div key={index}>
                                                                                                    <div style={{
                                                                                                        minHeight: "42px",
                                                                                                        maxHeight: "42px",
                                                                                                        overflow: "auto",
                                                                                                        borderTop: "1px solid #E7E7E7",
                                                                                                        //borderBottom: "1px solid #E7E7E7",
                                                                                                    }}>
                                                                                                        {
                                                                                                            // @ts-ignore
                                                                                                            calendarData[item?.hcpName][date]?.map((shift: any) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <div
                                                                                                                            className={"pdd-5"}
                                                                                                                        >
                                                                                                                            <div
                                                                                                                                className={"scheduler-approved-shift-timings-card d-flex cursor-pointer"}
                                                                                                                                onClick={() => {
                                                                                                                                    console.log(shift)
                                                                                                                                    if (shift?.status === "pending") {
                                                                                                                                        setPendingShiftDetails(shift)
                                                                                                                                        CommonService._staticData.openDialogPopup(setOpenPendingShiftDrawer, true)
                                                                                                                                    } else {
                                                                                                                                        setShiftDetails(shift)
                                                                                                                                        CommonService._staticData.openDialogPopup(setOpenApprovedShiftDrawer, true)
                                                                                                                                    }
                                                                                                                                }}>
                                                                                                                                {
                                                                                                                                    (() => {
                                                                                                                                        const foundShiftType = schedulerCalenderRepresentation.find(shiftTypeObj => shiftTypeObj.code === shift?.shift_type);
                                                                                                                                        if (foundShiftType) {
                                                                                                                                            //scheduler-${shift?.colour_code}-type
                                                                                                                                            return <>
                                                                                                                                                <div
                                                                                                                                                    className={`scheduler-${shift?.status}-shift`}>
                                                                                                                                                    <div
                                                                                                                                                        className={`scheduler-${shift?.colour_code}-type`}>
                                                                                                                                                        {
                                                                                                                                                            (shift?.hcp_type_details?.name)?.length > 3 ?
                                                                                                                                                                <ToolTipComponent
                                                                                                                                                                    tooltip={(shift?.hcp_type_details?.name)?.toUpperCase()}
                                                                                                                                                                    showArrow={true}
                                                                                                                                                                    position={"top"}
                                                                                                                                                                >
                                                                                                                                                                    <div
                                                                                                                                                                        className={"scheduler-ellipses-for-week-shift-text property-name"}>
                                                                                                                                                                        {(shift?.hcp_type_details?.name)?.toUpperCase()}
                                                                                                                                                                    </div>
                                                                                                                                                                </ToolTipComponent> :
                                                                                                                                                                <div
                                                                                                                                                                    className="hcp-name">
                                                                                                                                                                    {(shift?.hcp_type_details?.name)?.toUpperCase() || '-'}
                                                                                                                                                                </div>
                                                                                                                                                        }
                                                                                                                                                    </div>
                                                                                                                                                    {
                                                                                                                                                        shift?.status === "pending" &&
                                                                                                                                                        <div
                                                                                                                                                            className={"d-flex ts-justify-content-center ts-align-items-center mrg-left-5"}>
                                                                                                                                                            {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.start_time)} - {commonService.convertMinutesToTimeInCalender(shift?.shift_timings?.end_time)}
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                    {
                                                                                                                                                        shift?.status !== "pending" &&
                                                                                                                                                        <div
                                                                                                                                                            className={"d-flex ts-justify-content-center ts-align-items-center mrg-left-5"}>
                                                                                                                                                            {commonService.convertMinutesToTimeInCalender(shift?.expected?.shift_start_time)} - {commonService.convertMinutesToTimeInCalender(shift?.expected?.shift_end_time)}
                                                                                                                                                        </div>
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </>;
                                                                                                                                        } else {
                                                                                                                                            return null; // or some default icon or message if shift type is not found
                                                                                                                                        }
                                                                                                                                    })()
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                );
                                                                                                            })}
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })

                                                                            }
                                                                        </>}
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>;
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};

export default SchedulerCalenderComponent;
