import {ApiService} from "../index";
import {APIConfig} from "../../../constants";


//vars-health
const FacilityDetailsAPICall = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.FACILITY_DETAILS.METHOD](APIConfig.FACILITY_DETAILS.URL(facilityId), payload);
};

const AgencyDetailsAPICall = (agencyId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.AGENCY_DETAILS.METHOD](APIConfig.AGENCY_DETAILS.URL(agencyId), payload);
};

const AddFacilityAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_FACILITY.METHOD](APIConfig.ADD_FACILITY.URL, payload);
};

const AddFacilityAdminApiCall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.ADD_FACILITY_ADMIN_UNDER_USER_MANAGEMENT.METHOD](APIConfig.ADD_FACILITY_ADMIN_UNDER_USER_MANAGEMENT.URL, payload);
};

const AddAgencyAdminApiCall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.ADD_AGENCY_ADMIN_UNDER_USER_MANAGEMENT.METHOD](APIConfig.ADD_AGENCY_ADMIN_UNDER_USER_MANAGEMENT.URL, payload);
};


const AddWingAPICall = (facilityId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_WING.METHOD](APIConfig.ADD_WING.URL(facilityId), payload);
};

const AddShiftTimingsAPICall = (facilityId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_SHIFT_TIMINGS.METHOD](APIConfig.ADD_SHIFT_TIMINGS.URL(facilityId), payload);
};

const EditWingAPICall = (facilityId: any, wingId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_WING.METHOD](APIConfig.EDIT_WING.URL(facilityId, wingId), payload);
};
//vars-health-end


const ActivateDeactivateFacility = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_FACILITY.METHOD](APIConfig.TOGGLE_FACILITY.URL(facilityId), payload);
};
const ActivateDeactivateFacilityAdmin = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_FACILITY_ADMIN.METHOD](APIConfig.TOGGLE_FACILITY_ADMIN.URL(facilityId), payload);
};




const ActivteDeactivateWings = (facilityId: any, wingId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.TOGGLE_WING.METHOD](APIConfig.TOGGLE_WING.URL(facilityId, wingId), payload);
};


const EditFacilityAPICall = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_FACILITY.METHOD](APIConfig.EDIT_FACILITY.URL(facilityId), payload);
};

const EditFacilityAdminAPICall = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_FACILITY_ADMIN_DETAILS.METHOD](APIConfig.EDIT_FACILITY_ADMIN_DETAILS.URL(facilityId), payload);
};


const GetPropertyFMList = (propertyId: string) => {
    // @ts-ignore
    return ApiService[APIConfig.PROPERTY_FM_LIST.METHOD](APIConfig.PROPERTY_FM_LIST.URL(propertyId));
};

const GetWingsList = (facilityId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.WING_LIST.METHOD](APIConfig.WING_LIST.URL(facilityId));
};

const GetLinkedAgencyList = (facilityId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.LINKED_AGENCY_LIST_FOR_FACILITY.METHOD](APIConfig.LINKED_AGENCY_LIST_FOR_FACILITY.URL(facilityId));
};

const GetSelectedWingDetails = (facilityId: any, wingId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.WING_DETAILS.METHOD](APIConfig.WING_DETAILS.URL(facilityId, wingId));
};
const downloadFacilityDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_FACILITY_DATA_CSV.METHOD](APIConfig.DOWNLOAD_FACILITY_DATA_CSV.URL, payload);
};

const deleteShiftTimings = (facilityId: any, shiftTimingId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DELETE_SHIFT_TIMINGS.METHOD](APIConfig.DELETE_SHIFT_TIMINGS.URL(facilityId, shiftTimingId), payload);
};

const deleteWings = (wingId: any,facilityId:any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DELETE_WING.METHOD](APIConfig.DELETE_WING.URL(wingId,facilityId), payload);
};
const FacilityService = {
    ActivateDeactivateFacility,
    EditFacilityAPICall,
    GetPropertyFMList,
    FacilityDetailsAPICall,
    AddFacilityAPICall,
    AgencyDetailsAPICall,
    GetWingsList,
    AddWingAPICall,
    GetSelectedWingDetails,
    ActivteDeactivateWings,
    EditFacilityAdminAPICall,
    AddFacilityAdminApiCall,
    ActivateDeactivateFacilityAdmin, downloadFacilityDataCSVAPICall, EditWingAPICall, AddShiftTimingsAPICall,
    deleteShiftTimings,
    deleteWings, AddAgencyAdminApiCall,
    GetLinkedAgencyList
};

export default FacilityService;
