import DetailsHeaderComponent from "../../../../shared/components/details-header-component/DetailsHeaderComponent";
import {SHIFT_REQUIREMENT_LIST} from "../../../../constants/RoutesConfig";
import React, {useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./ShiftBulkUploadComponent.scss"
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../../shared/components/tabs/TabsComponent";
import {APIConfig, Misc} from "../../../../constants";
import TableWrapperComponent from "../../../../shared/components/table-wrapper/TableWrapperComponent";
import {ITableColumn} from "../../../../shared/models/table.model";
import {CommonService} from "../../../../shared/services";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import DialogComponent from "../../../../shared/components/dialog-component/DialogComponent";
import BulkUploadFileComponent from "./bulk-file-upload/BulkUploadFileComponent";
import SelectDropdownComponent
    from "../../../../shared/components/form-controls/select-dropdown/SelectDropdownComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../../store/reducers";

const ShiftBulkUploadComponent = () => {
    const [filterData, setFilterData] = useState<any>({
        requirementType: "Open Shift",
    });
    const {facilityList} = useSelector((state: IRootReducerState) => state.staticData);
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState<any>("Open Shift");
    const [loadingStates, setLoadingStates] = useState<any>([]);
    const [isDownloadingBulkShiftIsLoading, setIsDownloadingBulkShiftIsLoading] = useState(false);
    const [openBulkUploadDialog, setBulkUploadDialog] = useState(false);

    const downloadBulkShift = useCallback(() => {
        setIsDownloadingBulkShiftIsLoading(true);

        const payload: any = {};

        payload.type = activeTab;

        let apiCall: any = {};

        apiCall = CommonService._shift_management.downloadBulkUploadCSVAPICall(payload);

        apiCall
            .then((response: IAPIResponseType<any>) => {
                if (response?.data) {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'success',
                    );
                    const link = document.createElement('a');
                    link?.setAttribute('href', response?.data?.url);
                    document.body.appendChild(link);
                    link.click();
                    setIsDownloadingBulkShiftIsLoading(false);
                } else {
                    CommonService._alert.showToast(
                        response[Misc.API_RESPONSE_MESSAGE_KEY],
                        'error',
                    );
                    setIsDownloadingBulkShiftIsLoading(false);
                }
            })
            .catch((error: any) => {
                CommonService._alert.showToast(
                    error[Misc.API_ERROR_MESSAGE_KEY],
                    'error',
                );
                setIsDownloadingBulkShiftIsLoading(false);
            })
            .finally(() => {
            });

    }, [activeTab])

    const downloadParticularShift = useCallback((downloadUrl: any, index: any) => {
        let newLoadingStates = [...loadingStates];
        newLoadingStates[index] = true;
        setLoadingStates(newLoadingStates);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'shifts.csv'); // Set a default filename
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link);
        newLoadingStates[index] = false;
        setLoadingStates(newLoadingStates);
    }, [loadingStates]);


    const ShiftBulkUploadListColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: 'Facility',
                key: 'facility_name',
                dataIndex: 'facility',
                render: (item: any) => {
                    return (
                        <>
                            {item?.facility_details?.name}
                        </>
                    );
                },
            },
            {
                title: 'Uploaded On',
                key: 'uploaded_on',
                dataIndex: 'uploaded_on_date_time',
                render: (item: any) => {
                    return (
                        <>
                            {CommonService.formatDateTime(item?.updated_at)}
                        </>
                    );
                },
            },
            {
                title: 'Uploaded By',
                key: "uploaded_by",
                dataIndex: 'shift_uploaded_by',
                render: (item: any) => {
                    return (
                        <>
                            {item?.added_by_details?.first_name ? (item?.added_by_details?.first_name + " " + item?.added_by_details?.last_name) : "-"}</>
                    );
                },
            },

            {
                title: '',
                key: "download_button",
                dataIndex: 'download_button_shift',
                align: "right",
                render: (item: any, index: any) => {
                    return (
                        <>
                            <ButtonComponent className={'mrg-right-25'} color={'download-button'}
                                             isLoading={loadingStates[index]}
                                             onClick={() => {
                                                 downloadParticularShift(item?.url, index)
                                             }}>
                                Download
                            </ButtonComponent>
                        </>
                    );
                },
            },


        ];
    }, [downloadParticularShift, loadingStates]);


    return (
        <>
            <DialogComponent maxWidth={'xl'} open={openBulkUploadDialog} cancel={() => {
                setBulkUploadDialog(false);
            }}>
                <BulkUploadFileComponent setFilterData={setFilterData} filterData={filterData}
                                         setBulkUploadDialog={setBulkUploadDialog}/>
            </DialogComponent>
            <div className={'element-one'}>
                <DetailsHeaderComponent navigate={navigate}
                                        backAddress={SHIFT_REQUIREMENT_LIST}
                                        detailsModule={'Shift Bulk Upload'}/>

            </div>
            <div className={'element-two bulk-upload-content'}>
                <div className={'bulk-upload-tab-wrapper'}>
                    <div>
                        <TabsWrapperComponent>
                            <TabsComponent
                                value={activeTab}
                                allowScrollButtonsMobile={false}
                                onUpdate={(e: any, v: any) => {
                                    setActiveTab(v);
                                    setFilterData({...filterData, requirementType: v, page: 1});
                                }}
                            >
                                <TabComponent label="Per Diem" value={"Open Shift"}/>
                                <TabComponent label="Home Health / Hospice" value={"Home Health"}/>
                                <TabComponent label="Contract / Travel" value={"Contract Travel"}/>
                            </TabsComponent>
                            <TabContentComponent selectedTab={activeTab} value={"Open Shift"}>
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"Home Health"}>

                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"Contract Travel"}>

                            </TabContentComponent>
                        </TabsWrapperComponent>
                    </div>
                    <div className={'d-flex'}>
                        <SelectDropdownComponent noDataFoundText={"No Facility Found"}
                                                 navigation={() => {
                                                 }} buttonRequired={false}
                                                 placeholder={"Select Facility"}
                                                 searchMode={"clientSide"}
                            //   label={'Select The Facility*'}
                                                 value={filterData.facility_id || null}
                                                 options={facilityList}
                                                 valueExtractor={item => item.code}
                                                 searchable={true}
                                                 onUpdate={
                                                     (value: any) => {
                                                         setFilterData({
                                                             ...filterData,
                                                             facility_id: value,
                                                         });
                                                     }
                                                 }/>
                        <ButtonComponent className={'mrg-right-10 mrg-left-10'} color={'download-button'}
                                         isLoading={isDownloadingBulkShiftIsLoading}
                                         onClick={() => {
                                             downloadBulkShift()
                                         }}>
                            Download {activeTab === "Open Shift" && "Per Diem"} {activeTab === "Home Health" && "Home Health / Hospice"} {activeTab === "Contract Travel" && "Contract / Travel"} Template
                        </ButtonComponent>
                        <ButtonComponent
                            onClick={() => {
                                setBulkUploadDialog(true)
                            }}>
                            Upload File
                        </ButtonComponent>
                    </div>
                </div>
                <div className={'bulk-upload-table-wrapper'}>
                    <div className={'bulk-upload-list-screen'}>
                        <div className='bulk-upload-list-screen-content'>
                            <TableWrapperComponent
                                url={APIConfig.SHIFT_BULK_UPLOAD_LIST.URL}
                                method={APIConfig.SHIFT_BULK_UPLOAD_LIST.METHOD}
                                isPaginated={true}
                                extraPayload={filterData}
                                noDataText={
                                    filterData.search
                                        ? 'Oops! It seems like there are no shifts available for the name you have searched.'
                                        : 'No Data'
                                }
                                columns={ShiftBulkUploadListColumn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShiftBulkUploadComponent
