import "./TableWrapperComponent.scss";

import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {IAPIResponseType} from "../../models/api.model";
import {ITableComponentProps} from "../../models/table.model";
import {CommonService} from "../../services";
import _ from "lodash";
import TableComponent from "../table/TableComponent";
import PaginationComponent from "../pagination/PaginationComponent";
import {AXIOS_REQUEST_CANCELLED} from "../../services/api.service";

export interface TableComponentProps extends ITableComponentProps {
    moduleName?: string;
    url: string,
    method: "get" | "post" | string,
    isPaginated?: boolean,
    extraPayload?: any;
    autoHeight?: boolean;
    refreshToken?: string;
    onDataLoad?: (data: { total: number }) => void;
    isContainingInnerArray?: boolean;
    selectedQuantity?: any;
    downLoadButton?: any;
}

const TableWrapperComponent = (props: TableComponentProps) => {

    const {
        refreshToken,
        moduleName,
        isContainingInnerArray,
        autoHeight,
        id,
        url,
        method,
        extraPayload,
        onDataLoad,
        ...otherProps
    } = props;
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    // const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const [isDataLoadingFailed, setIsDataLoadingFailed] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const pageNumRef = useRef<number>(0);
    const totalResultsRef = useRef<number>(0);
    const pageSizeRef = useRef<number>(10);
    const isPaginated = props.isPaginated !== undefined ? props.isPaginated : true;
    const APICallSubscription = useRef<any>(null);

    const getListData = useCallback(() => {
        const cancelTokenSource = CommonService.getCancelToken();
        const payload = _.cloneDeep({limit: pageSizeRef.current, ...extraPayload, page: pageNumRef.current + 1});
        if (payload?.sort && payload?.sort?.key) { // TODO to make sort more consistent
            payload.sort[payload.sort.key] = payload?.sort?.order;
            delete payload.sort.key;
            delete payload.sort.order;
        } else {
            delete payload.sort;
        }
        let apiCall;
        if (method === "post") {
            apiCall = CommonService._api.post;
        } else {
            apiCall = CommonService._api.get;
        }
        if (APICallSubscription && APICallSubscription.current) {
            APICallSubscription.current.cancel();
        }
        APICallSubscription.current = cancelTokenSource;
        setIsDataLoading(true);
        //  setIsDataLoaded(false);
        setIsDataLoadingFailed(false);
        let listData: any[] = [];
        apiCall(url, payload, {}, {cancelToken: cancelTokenSource.token}).then((response: IAPIResponseType<any>) => {
            if (response.data) {
                if (isPaginated) {
                    listData = response?.data?.docs || [];
                    totalResultsRef.current = response?.data?.total;
                    pageNumRef.current = response?.data?.page - 1;
                    onDataLoad && onDataLoad({total: totalResultsRef.current});
                } else {
                    listData = isContainingInnerArray ? response?.data?.shift_timings : response?.data;
                    onDataLoad && onDataLoad({total: response?.data?.length});
                }
            }
            setData(listData);
            setIsDataLoading(false);
            //   setIsDataLoaded(true);
            setIsDataLoadingFailed(false);
        }).catch((error) => {
            if (error.reason !== AXIOS_REQUEST_CANCELLED) { // if previous request got cancelled do not close loading state
                setData(listData);
                setIsDataLoading(false);
                //   setIsDataLoaded(false);
                setIsDataLoadingFailed(true);
            }
        });
    }, [extraPayload, method, url, isPaginated, onDataLoad, isContainingInnerArray]);

    const handlePageNumberChange = useCallback((event: unknown, newPage: number) => {
        pageNumRef.current = newPage;
        getListData();
    }, [getListData]);

    const handlePageSizeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        pageSizeRef.current = +event.target.value;
        pageNumRef.current = 0;
        getListData();
    }, [getListData]);

    useEffect(() => {
        if (extraPayload?.page) {
            pageNumRef.current = extraPayload.page - 1;
        }
        getListData();
    }, [extraPayload, getListData, refreshToken]);

    useEffect(() => {
        const sub = CommonService._communications.TableWrapperRefreshSubject.subscribe((data) => {
            if (data.moduleName === moduleName) {
                getListData();
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [getListData, moduleName]);

    return (
        <>
            <TableComponent
                loading={isDataLoading}
                errored={isDataLoadingFailed}
                data={data}
                id={id}
                sort={extraPayload?.sort}
                {...otherProps}
            />
            {
                ((data && data?.length) > 0 && isPaginated) && <PaginationComponent
                    paginationOptions={[10, 25, 100]}
                    totalResults={totalResultsRef.current}
                    limit={pageSizeRef.current}
                    page={pageNumRef.current}
                    onPageChange={handlePageNumberChange}
                    onRowsPerPageChange={handlePageSizeChange}
                    id={id + "_pagination"}
                    selectedQuantity={props.selectedQuantity}
                    downLoadButton={props.downLoadButton}
                />
            }
        </>
    );
};

export default TableWrapperComponent;
