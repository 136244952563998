const LOCAL_STORAGE_LOGGED_IN_USER_DATA = "currentUser";
const LOCAL_STORAGE_JWT_TOKEN = "jwtToken";
const IS_SYSTEM_LOCKED = "isSystemLocked";
const SYSTEM_LOCK_REASON = "SYSTEM_LOCK_REASON";
const APP_NAME = "vars-health";
const API_RESPONSE_MESSAGE_KEY = "message";
const API_ERROR_MESSAGE_KEY="error"
const CURRENCY_SYMBOL = "$";
export {
    APP_NAME,
    LOCAL_STORAGE_LOGGED_IN_USER_DATA,
    LOCAL_STORAGE_JWT_TOKEN,
    API_RESPONSE_MESSAGE_KEY,
    IS_SYSTEM_LOCKED,
    SYSTEM_LOCK_REASON,
    CURRENCY_SYMBOL,
    API_ERROR_MESSAGE_KEY
}
