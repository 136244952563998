import {ApiService} from "../index";
import {APIConfig} from "../../../constants";


//vars-health


const addNewShiftAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_NEW_SHIFT.METHOD](APIConfig.ADD_NEW_SHIFT.URL, payload);
};

const addNewContractShiftApiCall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_CONTRACT_SHIFT.METHOD](APIConfig.ADD_CONTRACT_SHIFT.URL, payload);
};

const approveHomeHealthApplications = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.APPROVE_HOME_HEALTH_APPLICATION.METHOD](APIConfig.APPROVE_HOME_HEALTH_APPLICATION.URL, payload);
};

const approveContractTravelApplications = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.APPROVE_TRAVEL_CONTRACT_APPLICATION.METHOD](APIConfig.APPROVE_TRAVEL_CONTRACT_APPLICATION.URL, payload);
};

const addNewOpenRequirementShiftAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_NEW_OPEN_SHIFT.METHOD](APIConfig.ADD_NEW_OPEN_SHIFT.URL, payload);
};

const bulkUploadShiftRequirementAPICallOpenShift = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.BULK_UPLOAD_OPEN_REQUIREMENT.METHOD](APIConfig.BULK_UPLOAD_OPEN_REQUIREMENT.URL, payload);
};

const bulkUploadShiftRequirementAPICallHomeShift = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.BULK_UPLOAD_HOME_REQUIREMENT.METHOD](APIConfig.BULK_UPLOAD_HOME_REQUIREMENT.URL, payload);
};
const bulkUploadShiftRequirementAPICallContractTravelShift = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.BULK_UPLOAD_CONTRACT_REQUIREMENT.METHOD](APIConfig.BULK_UPLOAD_CONTRACT_REQUIREMENT.URL, payload);
};

const addNewHomeHealthHospiceRequirementShiftAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_NEW_HOME_HEALTH_HOSPICE_SHIFT.METHOD](APIConfig.ADD_NEW_HOME_HEALTH_HOSPICE_SHIFT.URL, payload);
};


const addApprovedNewShiftAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_APPROVED_NEW_SHIFT.METHOD](APIConfig.ADD_APPROVED_NEW_SHIFT.URL, payload);
};


const getShiftsManagementListAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFT_MANAGEMENT_LIST.METHOD](APIConfig.SHIFT_MANAGEMENT_LIST.URL, payload);
};

const getShiftDetailsAPICall = (shiftRequirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFT_REQUIREMENT_DETAILS.METHOD](APIConfig.SHIFT_REQUIREMENT_DETAILS.URL(shiftRequirementId, payload));
};
const getPendingShiftDetailsAPICall = (shiftRequirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFT_MANAGEMENT_PENDING_VIEW.METHOD](APIConfig.SHIFT_MANAGEMENT_PENDING_VIEW.URL(shiftRequirementId, payload));
};

const approveShiftRequirementApplicationAPICall = (shiftRequirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.APPROVE_SHIFT_REQUIREMENT_APPLICATION.METHOD](APIConfig.APPROVE_SHIFT_REQUIREMENT_APPLICATION.URL(shiftRequirementId), payload);
};

const rejectShiftRequirementApplicationAPICall = (shiftRequirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.REJECT_SHIFT_REQUIREMENT_APPLICATION.METHOD](APIConfig.REJECT_SHIFT_REQUIREMENT_APPLICATION.URL(shiftRequirementId), payload);
};

const uploadTimesheetAPICall = (shiftRequirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.UPLOAD_TIMESHEET.METHOD](APIConfig.UPLOAD_TIMESHEET.URL(shiftRequirementId), payload);
};

const markApprovedShiftToCompletedAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.MARK_APPROVED_SHIFT_TO_COMPLETED.METHOD](APIConfig.MARK_APPROVED_SHIFT_TO_COMPLETED.URL, payload);
};
const markApplicationToApprovedAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.MARK_APPLICATION_AS_APPROVE.METHOD](APIConfig.MARK_APPLICATION_AS_APPROVE.URL, payload);
};
const downloadShiftDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_SHIFT_DATA_CSV.METHOD](APIConfig.DOWNLOAD_SHIFT_DATA_CSV.URL, payload);
};
const downloadBulkUploadCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_BULK_UPLOAD_DATA_CSV.METHOD](APIConfig.DOWNLOAD_BULK_UPLOAD_DATA_CSV.URL, payload);
};
const downloadOpenShiftDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_OPEN_SHIFT_DATA_CSV.METHOD](APIConfig.DOWNLOAD_OPEN_SHIFT_DATA_CSV.URL, payload);
};
const markCompletedShiftToConfirmedAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.MARK_COMPLETED_SHIFT_TO_CONFIRMED.METHOD](APIConfig.MARK_COMPLETED_SHIFT_TO_CONFIRMED.URL, payload);
};
const cancelApprovedShiftToCancelledAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.CANCEL_APPROVED_SHIFT_TO_CANCELLED.METHOD](APIConfig.CANCEL_APPROVED_SHIFT_TO_CANCELLED.URL, payload);
};
const addNewApplicationsToShiftAPICall = (shiftRequirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_NEW_APPLICATIONS_TO_SHIFT.METHOD](APIConfig.ADD_NEW_APPLICATIONS_TO_SHIFT.URL(shiftRequirementId), payload);
};

const addNewApprovedShiftsForSelectedInternalStaff = (requirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_NEW_APPROVED_SHIFTS_FOR_INTERNAL_STAFFS.METHOD](APIConfig.ADD_NEW_APPROVED_SHIFTS_FOR_INTERNAL_STAFFS.URL(requirementId), payload);
};

const cancelShiftDetails = (requirementId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.CANCEL_SHIFT_DETAILS.METHOD](APIConfig.CANCEL_SHIFT_DETAILS.URL(requirementId), payload);
};
const getShiftsCalendarList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFTS_CALENDAR_LIST.METHOD](APIConfig.SHIFTS_CALENDAR_LIST.URL, payload);
};
const getShiftsCalenderWeekList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFTS_CALENDAR_WEEK_LIST.METHOD](APIConfig.SHIFTS_CALENDAR_WEEK_LIST.URL, payload);
};
const getShiftsCalenderDayList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFTS_CALENDAR_DAY_LIST.METHOD](APIConfig.SHIFTS_CALENDAR_DAY_LIST.URL, payload);
};
const getInternalShiftsCalendarList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.INTERNAL_SHIFTS_CALENDAR_LIST.METHOD](APIConfig.INTERNAL_SHIFTS_CALENDAR_LIST.URL, payload);
};
const getInternalShiftListByDate = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.INTERNAL_SHIFT_CALENDAR_LIST_BY_DATE.METHOD](APIConfig.INTERNAL_SHIFT_CALENDAR_LIST_BY_DATE.URL, payload);
};
const getShiftListByDate = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.SHIFT_CALENDAR_LIST_BY_DATE.METHOD](APIConfig.SHIFT_CALENDAR_LIST_BY_DATE.URL, payload);
};


const getAssignedShiftDetailsAPICall = (shiftId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.SHIFT_ASSIGNED_DETAILS.METHOD](APIConfig.SHIFT_ASSIGNED_DETAILS.URL(shiftId, payload));
};
const getInternalAssignedShiftDetailsAPICall = (shiftId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.INTERNAL_SHIFT_ASSIGNED_DETAILS.METHOD](APIConfig.INTERNAL_SHIFT_ASSIGNED_DETAILS.URL(shiftId, payload));
};

const markShiftAsRejectedAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.MARK_APPLICATION_AS_REJECT.METHOD](APIConfig.MARK_APPLICATION_AS_REJECT.URL, payload);
};

const preApprovedOpenShiftAPICall=(payload:any)=>{
    //@ts-ignore
    return  ApiService[APIConfig.ADD_PRE_APPROVED_OPEN_SHIFT.METHOD](APIConfig.ADD_PRE_APPROVED_OPEN_SHIFT.URL, payload);
}
const preApprovedHomeShiftAPICall=(payload:any)=>{
    //@ts-ignore
    return  ApiService[APIConfig.ADD_PRE_APPROVED_HOME_SHIFT.METHOD](APIConfig.ADD_PRE_APPROVED_HOME_SHIFT.URL, payload);
}
const preApprovedContractShiftAPICall=(payload:any)=>{
    //@ts-ignore
    return  ApiService[APIConfig.ADD_PRE_APPROVED_CONTRACT_SHIFT.METHOD](APIConfig.ADD_PRE_APPROVED_CONTRACT_SHIFT.URL, payload);
}

const ShiftManagementService = {
    addNewShiftAPICall,
    getShiftsManagementListAPICall,
    getShiftDetailsAPICall,
    approveShiftRequirementApplicationAPICall,
    rejectShiftRequirementApplicationAPICall,
    uploadTimesheetAPICall,
    markApprovedShiftToCompletedAPICall,
    markCompletedShiftToConfirmedAPICall,
    cancelApprovedShiftToCancelledAPICall,
    addNewApplicationsToShiftAPICall,
    getShiftsCalendarList,
    getShiftListByDate,
    cancelShiftDetails,
    getAssignedShiftDetailsAPICall,
    downloadShiftDataCSVAPICall,
    downloadOpenShiftDataCSVAPICall,
    getPendingShiftDetailsAPICall,
    markApplicationToApprovedAPICall,
    markShiftAsRejectedAPICall,
    getInternalShiftsCalendarList,
    getInternalShiftListByDate,
    getInternalAssignedShiftDetailsAPICall,
    addApprovedNewShiftAPICall,
    addNewApprovedShiftsForSelectedInternalStaff,
    addNewOpenRequirementShiftAPICall,
    getShiftsCalenderWeekList,
    getShiftsCalenderDayList,
    addNewContractShiftApiCall,
    approveHomeHealthApplications,
    addNewHomeHealthHospiceRequirementShiftAPICall,
    approveContractTravelApplications,
    downloadBulkUploadCSVAPICall,
    bulkUploadShiftRequirementAPICallOpenShift,
    bulkUploadShiftRequirementAPICallHomeShift,
    bulkUploadShiftRequirementAPICallContractTravelShift,
    preApprovedOpenShiftAPICall,
    preApprovedHomeShiftAPICall,
    preApprovedContractShiftAPICall
};

export default ShiftManagementService;
