import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import {useParams} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import {ListItemText, MenuItem, Select} from "@mui/material";
import {CommonService} from "../../../../../shared/services";
import {IAPIResponseType} from "../../../../../shared/models/api.model";
import {ImageConfig, Misc} from "../../../../../constants";
import LabelComponent from "../../../../../shared/components/label/LabelComponent";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";

// import FormDebuggerComponent from "../../../shared/components/form-debugger/FormDebuggerComponent";

interface EditAgencyComponentProps {
    HcpRatesAgencyDetails: any;
    closeEditDrawer: any;
    getAgencyDetails: any;
    agencyId: any;
    selectedFacility: any;
}

// const BootstrapInput = styled(InputBase)(({theme}) => ({
//     'label + &': {
//         marginTop: 20,
//     },
//     '& .MuiInputBase-input': {
//         borderRadius: 8,
//         position: 'relative',
//         backgroundColor: "#FFFFFF",
//         border: '1px solid #CCCCCC',
//         fontSize: 14,
//         //padding: '10px 26px 10px 12px',
//         // Use the system font instead of the default Roboto font.
//         '&:focus': {
//             borderRadius: 4,
//             borderColor: '#182A39',
//         },
//     },
// }));

const EditAgencyValidationSchema = Yup.object().shape({
        bill_payment_frequency: Yup.string(),
        mode_of_contact: Yup.string(),
        // admin_first_name: Yup.string().required('Admin first name is required'),
        // admin_last_name: Yup.string().required('Admin last name is required'),
        // admin_contact_number: Yup.string()
        //     .matches(/^\d{10}$/, 'Contact number must be a 10-digit number')
        //     .required('Admin Contact number is required'),
        // admin_email: Yup.string().email('Invalid admin email address').required('Admin email is required'),

    }
);
const SelectBillPaymentFrequency = [
    {
        name: "Weekly",
        _id: "weekly"
    },
    {
        name: "Bi-Weekly",
        _id: "bi-weekly"
    },
];

const selectModeOfContactOption = [
    {
        name: "E-Mail",
        _id: "email"
    },
    {
        name: "SMS",
        _id: "sms"
    },
    {
        name: "Both SMS and Email",
        _id: "both"
    }
];

interface EditAgencyFormInitialValuesFormSchema {

}

const EditFacilityFormInitialValues: EditAgencyFormInitialValuesFormSchema = {
    mode_of_contact: "",
    bill_payment_frequency: ""
};


const EditPreferenceComponent = (props: EditAgencyComponentProps) => {

    const {HcpRatesAgencyDetails, closeEditDrawer, getAgencyDetails, selectedFacility} = props;
    const {agencyId} = useParams();
    const [initialValues, setInitialValues] = useState<EditAgencyFormInitialValuesFormSchema>(EditFacilityFormInitialValues);
    const [selectedContact, setSelectedContact] = useState<any>(null);
    const [selectedBill, setSelectedBill] = useState<any>(null);

    useEffect(() => {
        const basicDetails = {
            bill_payment_frequency: HcpRatesAgencyDetails?.preferences?.bill_payment_frequency,
            mode_of_contact: HcpRatesAgencyDetails?.preferences?.mode_of_contact,
        };
        setInitialValues(basicDetails);
        setSelectedContact(HcpRatesAgencyDetails?.preferences?.mode_of_contact);
        setSelectedBill(HcpRatesAgencyDetails?.preferences?.bill_payment_frequency);
    }, [HcpRatesAgencyDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload: any = {
            preferences: values
        };
        CommonService._agency.EditFacilityAgencyRates(selectedFacility, agencyId, payload)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getAgencyDetails();
                closeEditDrawer();
            }).catch((error: any) => {
        }).finally(() => {
            setSubmitting(false);
            closeEditDrawer();
        });
    }, [selectedFacility, agencyId, getAgencyDetails, closeEditDrawer]);

    return (
        <>
            <div className={'edit-facility-component'}>
                <div className={'edit-facility-header'}>
                    <div className={'form-header-text'}>Edit Agency Details</div>
                    <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                </div>
                <div className={'edit-facility-form-wrapper'}>
                    <Formik
                        validationSchema={EditAgencyValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="t-form scroll-content form-holder" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'add-form-holder'}>
                                        <div className={'add-form-input-container'}>
                                            <div className="edit-component-content">
                                                <div className={"mrg-bottom-15"}>
                                                    <LabelComponent title={'Preferred Mode of Contact'}/>
                                                    <Field name={'mode_of_contact'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormControl sx={{m: 1, width: 500}}>
                                                                    {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                    {!selectedContact &&
                                                                        <div
                                                                            className={'material-select-box-placeholder-custom '}>
                                                                            Select Preferred Mode of Contact
                                                                        </div>}
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        inputProps={{
                                                                            name: 'mode_of_contact',
                                                                            id: 'uncontrolled-native',
                                                                        }}
                                                                      //  input={<BootstrapInput/>}
                                                                        value={selectedContact}
                                                                        onChange={(event: any) => {
                                                                            setSelectedContact(event.target.value);
                                                                            setFieldValue('mode_of_contact', event.target.value);
                                                                        }}
                                                                        // label="Select Facility"
                                                                    >
                                                                        {selectModeOfContactOption.map((item: any) => (
                                                                            <MenuItem key={item.name}
                                                                                      value={item?._id}>
                                                                                <ListItemText
                                                                                    primary={item.name}/>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div>
                                                    <LabelComponent title={'Billing Every (Weekly, Bi-weekly, Etc)'}/>
                                                    <Field name={'bill_payment_frequency'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormControl sx={{m: 1, width: 500}}>
                                                                    {!selectedBill &&
                                                                        <div
                                                                            className={'material-select-box-placeholder-custom '}>
                                                                            Select Bill Type
                                                                        </div>}
                                                                    {/*<InputLabel id="demo-simple-select-label">Select Facility</InputLabel>*/}
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        inputProps={{
                                                                            name: 'bill_payment_frequency',
                                                                            id: 'uncontrolled-native',
                                                                        }}
                                                                        // input={<BootstrapInput/>}
                                                                        value={selectedBill}
                                                                        onChange={(event: any) => {
                                                                            setSelectedBill(event.target.value);
                                                                            setFieldValue('bill_payment_frequency', event.target.value);
                                                                        }}
                                                                        // label="Select Facility"
                                                                    >
                                                                        {SelectBillPaymentFrequency.map((item: any) => (
                                                                            <MenuItem key={item.name}
                                                                                      value={item?._id}>
                                                                                <ListItemText
                                                                                    primary={item.name}/>
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'ts-justify-content-center d-flex mrg-bottom-10 mrg-top-40'}>
                                        <ButtonComponent
                                            disabled={isSubmitting || !isValid}
                                            isLoading={isSubmitting}
                                            color={'secondary'}
                                            type={'submit'}
                                        >
                                            Save Details
                                        </ButtonComponent>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditPreferenceComponent;


