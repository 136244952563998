import "./AddAgencyComponent.scss";
import AddAgencyScreenComponent from "./add-agency-screen/AddAgencyScreenComponent";

interface AddAgencyComponentProps {

}

const AddAgencyComponent = (props: AddAgencyComponentProps) => {
    return (
        <div className={'add-agency-component'}>
            <>
                <AddAgencyScreenComponent/>
            </>
        </div>);
};

export default AddAgencyComponent;
