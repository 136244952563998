import "./AccordionComponent.scss";
import React, {useCallback, useState} from "react";
import {ImageConfig} from "../../../constants";


interface AccordionComponentProps {
    className?: string;
    disabled?: boolean;
    isExpand?: boolean;
    onClick?: (value:any) => void;
    disableGutters?: boolean;
    title: string;
    actions?: React.ReactNode;
    isAccordionOpen?: boolean;
}


const AccordionComponent = (props: React.PropsWithChildren<AccordionComponentProps>) => {
    const {className, title, children, onClick, actions, isAccordionOpen} = props;
    const [isActive, setIsActive] = useState(isAccordionOpen);

    const onAccordionClick = useCallback(() => {
        setIsActive(!isActive);
        if (onClick) {
            onClick(isActive);
        }
    }, [isActive,onClick]);

    return (
        <div className={`accordion-component ${className}`}>
            <div className="">
                <div className="accordion-item"  onClick={onAccordionClick}>
                    <div className="accordion-title">{title}</div>
                    <div className="accordion-actions">
                        {actions && <div className={'accordion-action-buttons'}>
                            {actions}
                        </div>
                        }
                        <div className={'accordion-arrows'}>{isActive ? <ImageConfig.DownArrow/> : <ImageConfig.UpArrow/>}</div>
                    </div>
                </div>
                {isActive && <div className="accordion-content">{children}</div>}
            </div>
        </div>
    );

};

export default AccordionComponent;