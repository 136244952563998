import "./HcpEducationStepComponent.scss";
import {Field, FieldArray, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useEffect, useRef} from "react";
import * as Yup from "yup";
import ButtonComponent from "../../../../../shared/components/button/ButtonComponent";
import AccordionComponent from "../../../../../shared/components/accordion/AccordionComponent";
import {ImageConfig} from "../../../../../constants";
import FormikInputComponent from "../../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import LabelComponent from "../../../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";


interface HcpEducationStepComponentProps {
    onSubmit: (values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => void;
    setFormTab?: any;
    setHcpAddActiveStep?:any;
}

const educationValidationObjSchema = Yup.object({
    degree: Yup.string().required("Degree is required"),
    institute_name: Yup.string().required("Institute Name is required"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
        .required("End Date is required")
        .test('is-greater', 'End Date must be greater than Start Date', function (end_date) {
            const {start_date} = this.parent; // Access the value of start_date
            // Perform the comparison
            if (start_date && end_date) {
                return new Date(end_date) > new Date(start_date);
            }
            // If either value is missing, assume validation passes
            return true;
        }),
    location: Yup.string().required("Location is required"),
});

interface educationFormInitialValuesFormSchema {
    degree: string;
    institute_name: string;
    start_date: string;
    end_date: string;
    location: string;
}

const educationFormInitialValues: educationFormInitialValuesFormSchema = {
    degree: "",
    institute_name: "",
    start_date: "",
    end_date: "",
    location: "",
};
const educationValidationSchema: any = Yup.object({
    education: Yup.array().of(educationValidationObjSchema).required("Education is required"),
});

const educationFormArrayInitialValues: any = {
    education: [educationFormInitialValues]
};

const HcpEducationStepComponent = (props: HcpEducationStepComponentProps) => {
    const {
        onSubmit,
        setFormTab,
        setHcpAddActiveStep
    } = props;
    const addEducationRef: any = useRef(null);

    return (
        <>

                <Formik
                    validationSchema={educationValidationSchema}
                    initialValues={educationFormArrayInitialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                            // console.log("values", values);
                            // console.log("intialvalues", initialValues);
                        }, [validateForm, values]);
                        return (
                            <Form className="t-form scroll-content" noValidate={true}>
                                <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                <div className="form-header-body-wrapper">
                                    <div className="add-component-header-form d-flex">
                                        <div
                                            className={'form-top-header-text justify-content-space-between width-100 align-items-center'}>Education
                                            Details
                                        </div>
                                        <ButtonComponent
                                            onClick={() => {
                                                if (addEducationRef.current) {
                                                    addEducationRef.current.unshift(educationFormInitialValues);
                                                }
                                            }}
                                        >
                                            {/*// onClick={() => arrayHelpers.push(educationFormInitialValues)}>*/}
                                            Add Education
                                        </ButtonComponent>
                                    </div>
                                    <>
                                        <FieldArray
                                            name="education"
                                            render={(arrayHelpers) => {
                                                addEducationRef.current = arrayHelpers;
                                                return (
                                                    <div className="add-component-content">
                                                        {values.education.map((item: any, index: any) => (
                                                            <AccordionComponent
                                                                className={"accordion-action-button"}
                                                                title={""}
                                                                actions={<div className="action-delete"
                                                                              onClick={() => {
                                                                                  arrayHelpers.remove(index);
                                                                              }}>
                                                                    <ImageConfig.DeleteIcon color={"red"}/>
                                                                </div>}
                                                            >
                                                                <>
                                                                    <Field name={`education[${index}].degree`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikInputComponent
                                                                                    label={'Degree'}
                                                                                    placeholder={'Enter Degree'}
                                                                                    type={"text"}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                    <div className="ts-row">
                                                                        <div className="ts-col-6">
                                                                            <Field
                                                                                name={`education[${index}].institute_name`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            label={'Institution Name'}
                                                                                            placeholder={'Ex. Caltech'}
                                                                                            type={"text"}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-6">
                                                                            <Field
                                                                                name={`education[${index}].location`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikInputComponent
                                                                                            label={'Location'}
                                                                                            placeholder={'Enter Location'}
                                                                                            type={"text"}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="ts-row">
                                                                        <div className="ts-col-6">
                                                                            <LabelComponent title={'Start Date*'}/>
                                                                            <Field
                                                                                name={`education[${index}].start_date`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikDatePickerComponent
                                                                                            // minDate={new Date()}
                                                                                            placeholder={'Select Date'}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                        <div className="ts-col-6">
                                                                            <LabelComponent title={'End Date*'}/>
                                                                            <Field
                                                                                name={`education[${index}].end_date`}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikDatePickerComponent
                                                                                            // minDate={new Date()}
                                                                                            placeholder={'Select Date'}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </AccordionComponent>
                                                        ))}
                                                    </div>
                                                );
                                            }}

                                        />
                                    </>
                                </div>
                                </div>
                                <div className={'add-hcp-form-action-element'}>
                                    <ButtonComponent
                                        onClick={() => {
                                            setFormTab("work-experience")
                                            setHcpAddActiveStep(3)
                                        }}
                                        className={"skip-button mrg-left-10"}
                                    >
                                        Skip
                                    </ButtonComponent>
                                    {
                                        <ButtonComponent
                                            className={"next-button"}
                                            type={"submit"}
                                            // suffixIcon={
                                            //     <ImageConfig.ArrowRight/>
                                            // }
                                        >
                                            Next
                                        </ButtonComponent>
                                    }

                                </div>
                            </Form>
                        );
                    }}

                </Formik>

        </>
    );

};

export default HcpEducationStepComponent;
