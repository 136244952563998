import "./EditUserComponent.scss";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import React, {useCallback, useEffect, useState} from "react";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {ImageConfig, Misc} from "../../../constants";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import * as Yup from "yup";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import PhoneInputComponent from "../../../shared/components/phoneInput/PhoneInputComponent";
import FormikCheckBoxComponent from "../../../shared/components/form-controls/formik-check-box/FormikCheckBoxComponent";

interface EditUserComponentProps {
    userDetails: any;
    closeEditDrawer?: any;
    setFilterData?: any;
    filterData?: any;
    selectedUserType?: any;
}

const EditUserValidationSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(3, 'First Name should be at least 3 characters')
            .max(50, 'First Name should not exceed 50 characters')
            .required('First Name is required'),
        last_name: Yup.string()
            .min(3, 'Last Name should be at least 3 characters')
            .max(50, 'Last Name should not exceed 50 characters')
            .required('Last Name is required'),
        contact_number: Yup.string().min(12, "min 10 digits").required("Phone number is required"),
        email: Yup.string().email('Invalid email address').required('Email Address is required'),
    }
);

interface EditUserFormInitialValuesFormSchema {
    first_name: string;
    last_name: string;
    contact_number: string;
    email: string;
    notification_preferences: {
        isSmsRequired: any;
        isEmailRequired: any;
        isPush_notificationRequired: any;
    };
}

const EditUserFormInitialValues: EditUserFormInitialValuesFormSchema = {
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    notification_preferences: {
        isSmsRequired: true,
        isEmailRequired: true,
        isPush_notificationRequired: true,
    }
};
const EditUserComponent = (props: EditUserComponentProps) => {
    const {userDetails, closeEditDrawer, setFilterData, filterData, selectedUserType} = props;
    const [initialValues, setInitialValues] = useState<EditUserFormInitialValuesFormSchema>(EditUserFormInitialValues);

    useEffect(() => {
        const basicDetails = {
            first_name: userDetails?.first_name,
            last_name: userDetails?.last_name,
            contact_number: userDetails?.contact_number,
            email: userDetails?.email,
            notification_preferences: {
                isSmsRequired: userDetails?.notification_preferences?.isSmsRequired,
                isEmailRequired: userDetails?.notification_preferences?.isEmailRequired,
                isPush_notificationRequired: userDetails?.notification_preferences?.isPush_notificationRequired,
            }
        };
        setInitialValues(basicDetails);
    }, [userDetails]);

    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
        };
        let apiCall: any = {};
        if (selectedUserType === "facility-user") {
            apiCall = CommonService._facility.EditFacilityAdminAPICall(userDetails?._id, payload);
        } else if (selectedUserType === "agency-user") {
            apiCall = CommonService._agency.EditAgencyAdminAPICall(userDetails?._id, payload);
        }
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            closeEditDrawer();
            setFilterData({...filterData, search: "", page: 1});
        })
            .catch((error: any) => {
                CommonService._alert.showToast(error?.error, "error");
                CommonService.handleErrors(setErrors, error);
                closeEditDrawer();
                // setFilterData({...filterData, search: "", page: 1});
            }).finally(() => {
            setSubmitting(false);
        });
    }, [selectedUserType, userDetails?._id, closeEditDrawer, setFilterData, filterData]);

    return (
        <>
            <div className={'edit-user-component'}>
                <div className={'edit-user-header d-flex'}>
                    <div className={'header-title-text center-element'}>
                        Edit User Details
                    </div>
                    <div className={'header-close-icon icon-button'} onClick={closeEditDrawer}>
                        <ImageConfig.CrossIcon width={24} height={24}/>
                    </div>
                </div>
                <HorizontalLineComponent/>
                <div className={'edit-user-form-container'}>
                    <Formik
                        validationSchema={EditUserValidationSchema}
                        initialValues={initialValues}
                        validateOnChange={false}
                        validateOnBlur={true}
                        enableReinitialize={true}
                        validateOnMount={true}
                        onSubmit={onSubmit}
                    >
                        {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEffect(() => {
                                validateForm();
                            }, [validateForm, values]);
                            return (
                                <Form className="edit-user-form" noValidate={true}>
                                    {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                    <div className={'edit-user-form-content'}>
                                        <div className={'form-fields-wrapper pdd-25'}>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    <Field name={'first_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'First Name'}
                                                                    placeholder={'Ex : John'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'last_name'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Last Name'}
                                                                    placeholder={'Ex : Doe'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="ts-row">
                                                <div className="ts-col-6">
                                                    {/*<Field name={'contact_number'}>*/}
                                                    {/*    {*/}
                                                    {/*        (field: FieldProps) => (*/}
                                                    {/*            <FormikInputComponent*/}
                                                    {/*                label={'Phone Number'}*/}
                                                    {/*                placeholder={'000-0000-000'}*/}
                                                    {/*                type={"text"}*/}
                                                    {/*                required={true}*/}
                                                    {/*                formikField={field}*/}
                                                    {/*                fullWidth={true}*/}
                                                    {/*            />*/}
                                                    {/*        )*/}
                                                    {/*    }*/}
                                                    {/*</Field>*/}
                                                    <LabelComponent title={'Phone Number*'}/>
                                                    <Field name={'contact_number'}>
                                                        {(field: FieldProps) => {
                                                            return <PhoneInputComponent field={field}
                                                                                        placeholder={"Enter Phone number"}/>;
                                                        }}
                                                    </Field>
                                                </div>
                                                <div className="ts-col-6">
                                                    <Field name={'email'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Email Address'}
                                                                    placeholder={'example@xyz.com'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={'form-top-header-text'}>Notification Preferences</div>
                                            <div className="ts-row">
                                                <div className="ts-col-4">
                                                    <Field name={'notification_preferences.isSmsRequired'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"SMS"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field name={'notification_preferences.isEmailRequired'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"EMail"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                                <div className="ts-col-4">
                                                    <Field
                                                        name={'notification_preferences.isPush_notificationRequired'}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikCheckBoxComponent
                                                                    formikField={field}
                                                                    label={"In App"}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'form-button'}>
                                            <ButtonComponent
                                                disabled={isSubmitting || !isValid}
                                                isLoading={isSubmitting}
                                                color={'secondary'}
                                                type={'submit'}
                                            >
                                                Save
                                            </ButtonComponent>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </>
    );
};
export default EditUserComponent;