import "./AddCustomerDetailsComponent.scss";
import * as Yup from "yup";
import React, {useCallback, useEffect, useState} from "react";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
//import FormDebuggerComponent from "../../../../shared/components/form-debugger/FormDebuggerComponent";
import {useNavigate} from "react-router-dom";
import {CommonService} from "../../../shared/services";
import {ImageConfig, Misc} from "../../../constants";
import FormikInputComponent from "../../../shared/components/form-controls/formik-input/FormikInputComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {addCustomerSteps} from "../../../data";
import {CUSTOMER_MANAGEMENT} from "../../../constants/RoutesConfig";
import StepperComponent from "../../../shared/components/stepper-component/StepperComponent";
import FilePickerComponent from "../../../shared/components/file-picker/FilePickerComponent";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import FormikRadioButtonGroupComponent
    from "../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent";
import PhoneInputComponent from "../../../shared/components/phoneInput/PhoneInputComponent";
import FormikSelectDropdownComponent
    from "../../../shared/components/form-controls/formik-select-dropdown/FormikSelectDropdownComponent";
// import {v4 as uuidv4} from "uuid";
// import LinkComponent from "../../../shared/components/link/LinkComponent";
import {useSelector} from "react-redux";
import {IRootReducerState} from "../../../store/reducers";

interface UserDetailsComponentProps {
    prev: () => void;
    formTab:any;
    setFormTab:any;
}

const UserValidationSchema = Yup.object().shape({
        customer_name: Yup.string()
            .min(2, 'Customer Name should be at least 2 characters')
            .max(50, 'Customer Name should not exceed 50 characters')
            .required('Customer Name is required'),
        email: Yup.string().email('Invalid email address').required('Customer Email Address is required'),
        nc_email: Yup.string().email('Invalid email address').required('NC Email Address is required'),
        customer_unique_id: Yup.string()
            .min(2, 'Code should be at least 2 characters')
            .max(50, 'Code should not exceed 50 characters')
            .required('Coupon Code is required'),
        address: Yup.object().shape({
            street: Yup.string().required('Address Line is required').min(3, 'Address Line should be at least 3 characters'),
            city: Yup.string().required('City is required').min(2, 'City should be at least 2 characters'),
            state: Yup.string().required('State is required').min(2, 'State should be at least 2 characters'),
            region: Yup.string().required('Region is required'),
            country: Yup.string().required('Country is required').min(2, 'Country should be at least 2 characters'),
            zip_code: Yup.string().required('ZIP Code is required').min(5, 'ZIP Code should be at least 5 characters').max(6, 'ZIP Code should be maximum 6 characters'),
        }),
        support_contact_number: Yup.string().min(12, "min 10 digits").required("Support Phone Number is required"),
    }
);

interface formInitialValuesFormSchema {
    email: string;
    customer_name: any;
    customer_unique_id: any
    nc_email:string;
    is_acc_module_req:boolean;
    support_contact_number:any;
    address: {
        street: any,
        city: any,
        state: any,
        region: any,
        country: any,
        zip_code: any,
    };
}

const formInitialValues: formInitialValuesFormSchema = {
    email: "",
    customer_name: "",
    customer_unique_id: "",
    nc_email:"",
    is_acc_module_req:true,
    support_contact_number:"",
    address: {
        street: "",
        city: "",
        state: "",
        region: "",
        country: "",
        zip_code: "",
    }
};


const AddCustomerDetailsComponent = (props: UserDetailsComponentProps) => {
    const {formTab,setFormTab}=props;
    const [initialValues] = useState<formInitialValuesFormSchema>(formInitialValues);
    const navigate = useNavigate();
    const [customerAddActiveStep,setCustomerAddActiveStep] = React.useState(0);
    const [logoFile, setLogoFile] = React.useState<any>([]);
    const {regionList} = useSelector((state: IRootReducerState) => state.staticData);

    const handleNext = useCallback(() => {
        setCustomerAddActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, []);

    const handleBack = useCallback(() => {
        setCustomerAddActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);


    const onSubmit = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const formData = new FormData();
        logoFile.forEach((file: any, index: number) => {
            // Append each file with the same key ('image')
            formData.append('file', file);
        });
        formData.append('email', values.email);
        formData.append('nc_email',values.nc_email);
        formData.append('customer_name', values.customer_name);
        formData.append('customer_unique_id', values.customer_unique_id);
        formData.append("is_acc_module_req",values.is_acc_module_req);
        formData.append('support_contact_number',values.support_contact_number);
        formData.append('address.street',values.address.street || '');
        formData.append('address.city',values.address.city || '');
        formData.append('address.state',values.address.state || '');
        formData.append('address.country',values.address.country || '');
        formData.append('address.zip_code',values.address.zip_code || '');
        formData.append('address.region',values.address.region || '');


        if (values.logo_name) {
            formData.append('logo_name', values.logo_name || '');
        }

        let apiCall: any = {};
        apiCall =
            CommonService._user_management.customerAddAPICall(
                formData,
            );
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            navigate(CommonService._routeConfig.CustomerManagementRoute());
        })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
                CommonService._alert.showToast(error?.error, "error");
            }).finally(() => {
            setSubmitting(false);
        });

    }, [navigate, logoFile]);

    const removeFile = (index: number) => {
        // Create a new array excluding the file at the given index
        const newLogoFile = [...logoFile];
        newLogoFile.splice(index, 1);
        // Update the timesheetFile state with the new array
        setLogoFile(newLogoFile);
    };

    const isCustomerDetailsValidated=useCallback((errors:any)=>{
        const fieldsToValidate=[
            "email",
            "customer_name",
            "customer_unique_id",
            "nc_email",
            "is_acc_module_req",
            "support_contact_number"
        ]
        return fieldsToValidate.every((field:any)=>!errors[field])
    },[])

    return (
        <div className={'add-user-details-component'}>
            <div className={'add-user-sidebar-steper'}>
                <div className={'add-facility-sidebar-steper-wrapper pdd-20'}>
                    <div className={'flex-1'}>
                        <div className={'adding-module-branding-wrapper'}>
                            <div className="menu-item-icon">
                                <ImageConfig.VarsShortLogoIcon/>
                            </div>

                            <div className={'vars-health-logo-text'}>
                                Work OS
                            </div>
                        </div>
                        <div className={'form-top-header-text mrg-top-40'}>
                            Customer Onboarding
                        </div>
                        <StepperComponent activeStep={customerAddActiveStep} steps={addCustomerSteps}/>
                    </div>
                    <div>
                        <ButtonComponent
                            id={"exit_btn"}
                            suffixIcon={<ImageConfig.ExitIcon/>}
                            color={"exit-onboarding-navigation"}
                            onClick={() => {
                                // navigate(FACILITY_lIST);
                                CommonService.onExitFlowHandling(navigate, CUSTOMER_MANAGEMENT);
                            }}
                        >
                            Exit Customer Onboarding
                        </ButtonComponent>
                    </div>
                </div>
            </div>
            <div className={'add-user-formik-wrapper mrg-top-20'}>

                <Formik
                    validationSchema={UserValidationSchema}
                    initialValues={initialValues}
                    validateOnChange={false}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useEffect(() => {
                            validateForm();
                        }, [validateForm, values]);
                        return (
                            <Form noValidate={true}>
                                {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={false}/>*/}
                                <div className="add-component-content">
                                    <div className={'add-form-wrapper-box pdd-left-25 pdd-right-25 pdd-top-10'}>
                                        {formTab === 'Customer' &&
                                            <>
                                                <div className={'form-top-header-text'}>Customer Details</div>
                                                <>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={'customer_name'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Customer Name'}
                                                                            placeholder={'Ex: Google'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <Field name={'customer_unique_id'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Code'}
                                                                            placeholder={'Ex: XXXXXXX'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={'email'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Customer Email Address'}
                                                                            placeholder={'example@xyz.com'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <Field name={'nc_email'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Default NC Email Address'}
                                                                            placeholder={'example@xyz.com'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className={'ts-col-6'}>
                                                            <LabelComponent
                                                                title={'Do you want Accounting module to show?'}
                                                                isBold={false}
                                                            />

                                                            <Field name={'is_acc_module_req'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikRadioButtonGroupComponent
                                                                            formikField={field}
                                                                            className={"mrg-top-10"}
                                                                            valueExtractor={(option: any) => option.code}
                                                                            displayWith={(option: any) => option.title}
                                                                            options={
                                                                                [
                                                                                    {
                                                                                        title: "Yes",
                                                                                        code: true
                                                                                    },
                                                                                    {
                                                                                        title: "No",
                                                                                        code: false
                                                                                    },
                                                                                ]
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <LabelComponent title={'Support Phone Number*'}/>
                                                            <Field name={'support_contact_number'}>
                                                                {(field: FieldProps) => {
                                                                    return <PhoneInputComponent field={field}
                                                                                                placeholder={"Enter Phone number"}/>;
                                                                }}
                                                            </Field>
                                                        </div>
                                                    </div>

                                                    <div className={'form-top-header-text'}>Upload customer logo</div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-12">
                                                            <Field name={'logo_name'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Logo Name (We recommend providing the company name if it\'s not already included in the uploaded image.)'}
                                                                            placeholder={'Ex: Google'}
                                                                            type={"text"}
                                                                            //required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    {logoFile?.length === 0 &&
                                                        <div>
                                                            <FilePickerComponent
                                                                maxFileCount={3}
                                                                height={'208px'}
                                                                id={'sc_upload_btn'}
                                                                //  btnText={'Select Files'}
                                                                disabled={logoFile?.length === 3}
                                                                multiple={true}
                                                                showDropZone={true}
                                                                onFilesDrop={(
                                                                    acceptedFiles: any,
                                                                    rejectedFiles: any,
                                                                ) => {
                                                                    if (acceptedFiles && acceptedFiles.length > 0) {
                                                                        const file = acceptedFiles[0];
                                                                        //   setTimesheetFile(file);
                                                                        setLogoFile([...logoFile, file]);
                                                                    }
                                                                }}
                                                                acceptedFileTypes={['png', 'jpeg', 'jpg']}
                                                                uploadDescription={'(upload only one file)'}
                                                                acceptedFilesText={
                                                                    'Only .png, .jpg, .jpeg files are supported'
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    'd-flex justify-content-end mrg-top-5 file-upload-supported-text'
                                                                }
                                                            >
                                                                Max upload : 1
                                                            </div>
                                                        </div>}
                                                    <div className={'d-flex-wrap mrg-top-10'}>
                                                        {logoFile &&
                                                            logoFile?.map((file: any, index: number) => {
                                                                return (
                                                                    <>
                                                                        <div
                                                                            className={
                                                                                'd-flex justify-content-space-between file-holder-wrapper mrg-right-10'
                                                                            }
                                                                        >
                                                                            <div> {file?.name}</div>
                                                                            <div
                                                                                className={'icon-button'}
                                                                                onClick={() => {
                                                                                    removeFile(index);
                                                                                }}
                                                                            >
                                                                                <ImageConfig.DeleteIcon/>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                </>
                                            </>
                                        }
                                        {
                                            formTab === "Address" && <>
                                                <div className="form-top-header-text">
                                                    Customer Address Details
                                                </div>
                                                <div className={'ts-row'}>
                                                    <div className={'ts-col-6'}>
                                                        <Field name={'address.street'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Address Line'}
                                                                        placeholder={'Enter address here'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'address.city'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'City'}
                                                                        placeholder={'Enter City'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'address.state'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'State'}
                                                                        placeholder={'Enter State'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'address.country'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'Country'}
                                                                        placeholder={'Enter the Country'}
                                                                        type={"text"}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                                <div className="ts-row">
                                                    <div className="ts-col-6">
                                                        <Field name={'address.zip_code'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikInputComponent
                                                                        label={'ZIP Code*'}
                                                                        placeholder={'Enter ZIP Code'}
                                                                        type={"number"}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        maxLength={6}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                    <div className="ts-col-6">
                                                        <Field name={'address.region'}>
                                                            {
                                                                (field: FieldProps) => (
                                                                    <FormikSelectDropdownComponent
                                                                        options={regionList}
                                                                        displayWith={(option: any) => option.title}
                                                                        valueExtractor={(option: any) => option.code}
                                                                        label={'Region'}
                                                                        placeholder={'Select Region'}
                                                                        required={true}
                                                                        formikField={field}
                                                                        fullWidth={true}
                                                                        searchable={true}
                                                                        noDataMessage={"No region available"}
                                                                    />
                                                                )
                                                            }
                                                        </Field>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className={'add-user-form-action-element'}>
                                        {formTab === 'Customer' &&
                                            <div></div>
                                        }
                                        {formTab === 'Customer' &&
                                            <ButtonComponent
                                                disabled={!isCustomerDetailsValidated(errors)}
                                                isLoading={isSubmitting}
                                                type={'submit'}
                                                onClick={() => {
                                                    setFormTab("Address");
                                                    handleNext();
                                                    setCustomerAddActiveStep(1);
                                                }}
                                                style={{color: "#202937"}}
                                            >
                                                Next
                                            </ButtonComponent>
                                        }
                                        {formTab === "Address" &&
                                            <div className={"mrg-left-20"}>
                                                <ButtonComponent
                                                    className={'back-button'}
                                                    prefixIcon={<ImageConfig.BackFormButton/>}
                                                    onClick={() => {
                                                        setFormTab("Customer");
                                                        setCustomerAddActiveStep(0)
                                                        handleBack();
                                                    }}
                                                >
                                                    Back
                                                </ButtonComponent>
                                            </div>
                                        }
                                        {
                                            formTab === 'Address' &&
                                            <div>
                                                <ButtonComponent
                                                    disabled={isSubmitting || !isValid}
                                                    isLoading={isSubmitting}
                                                    type={'submit'}
                                                    suffixIcon={<ImageConfig.ArrowRightCircleIcon/>}
                                                >
                                                    Save Details
                                                </ButtonComponent>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );

};

export default AddCustomerDetailsComponent;
