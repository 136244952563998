import {call, put, takeEvery} from "redux-saga/effects";

import {
    GET_USER_ROLE_LIST,
    setUserRoleList,
    setFacilityListLite,
    GET_FACILITY_LIST_LITE,
    setAgencyListLite,
    GET_AGENCY_LIST_LITE,
    setWingsListLite,
    GET_WINGS_LIST_LITE,
    GET_REGION_LIST_API,
    setRegionList,
    setFacilityAgencyListLite,
    GET_FACILITY_AGENCY_LIST_LITE
} from "../actions/static-data.action";

import {CommonService} from "../../shared/services";


function* getFacilityList() {
    let payload: any = {};
    payload.is_active = true;
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.FacilityListLiteAPICall, payload);
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

            return {
                ...rest,     // Keep the other properties unchanged
                title: name,
                code: _id,// Add the new key "newName" with the value of the old "name"
            };
        });

        yield put(setFacilityListLite(modifiedData));
    } catch (error: any) {
        yield put(setFacilityListLite([]));
    }
}

function* getAgencyList(action: any) {
    let payload: any = {};
    payload.agency_ids = action.selectedAgencies;
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.AgencyListLiteAPICall, payload);
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, name, ...rest} = item; // Destructure the "name" property and keep the rest of the object

            return {
                ...rest,     // Keep the other properties unchanged
                title: name,
                code: _id,// Add the new key "newName" with the value of the old "name"
            };
        });

        yield put(setAgencyListLite(modifiedData));
    } catch (error: any) {
        yield put(setAgencyListLite([]));
    }
}

function* getFacilityAgencyList(action: any) {
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.facilityAgencyListLiteAPICall, action.facilityId);
        console.log("success");
        const modifiedData = resp?.data?.map((item: any) => {
            const {_id, name, ...rest} = item; // Destructure the "name" property and keep the rest of the object
            return {
                ...rest,     // Keep the other properties unchanged
                title: name,
                code: _id,// Add the new key "newName" with the value of the old "name"
            };
        });
        yield put(setFacilityAgencyListLite(modifiedData));
    } catch (error: any) {
        yield put(setFacilityAgencyListLite([]));
        console.log("error");
    }
}

function* getWingsList(action: any) {
    let payload: any = {};
    payload.facility_id = action?.payload?.facilityId;
    payload.is_active = true;
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.WingsListLiteAPICall, payload);
        yield put(setWingsListLite(resp?.data || []));
    } catch (error: any) {
        yield put(setWingsListLite([]));
    }
}

function* getRegionList() {
    // let payload:any={}
    // payload.facility_id=action?.payload?.facilityId
    try {
        //@ts-ignore
        const resp = yield call(CommonService._staticData.RegionListAPICall);
        yield put(setRegionList(resp?.data || []));
    } catch (error: any) {
        yield put(setRegionList([]));
    }
}


function* getUserRoleList() {
    try {
        // @ts-ignore
        const resp = yield call(CommonService._staticData.getUserRoleList);
        yield put(setUserRoleList(resp?.data));
    } catch (error: any) {
        yield put(setUserRoleList([]));
    }
}

export default function* staticDataSaga() {
    yield takeEvery(GET_USER_ROLE_LIST, getUserRoleList);
    yield takeEvery(GET_FACILITY_LIST_LITE, getFacilityList);
    yield takeEvery(GET_AGENCY_LIST_LITE, getAgencyList);
    yield takeEvery(GET_WINGS_LIST_LITE, getWingsList);
    yield takeEvery(GET_REGION_LIST_API, getRegionList);
    yield takeEvery(GET_FACILITY_AGENCY_LIST_LITE, getFacilityAgencyList);
}


