import {ApiService} from "../index";
import {APIConfig} from "../../../constants";

const AgencyDetailsAPICall = (agencyId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.AGENCY_DETAILS.METHOD](APIConfig.AGENCY_DETAILS.URL(agencyId), payload);
};

const AgencyLinkedFacilityDetails = (agencyId: any) => {
    //@ts-ignore
    return ApiService[APIConfig.GET_LINKED_FACILITY_AGENCY.METHOD](APIConfig.GET_LINKED_FACILITY_AGENCY.URL(agencyId));
};

const EditAgencyAPICall = (agencyId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_AGENCY.METHOD](APIConfig.EDIT_AGENCY.URL(agencyId), payload);
};
const EditFacilityAgencyRates = (facilityId: any, agencyId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_FACILITY_AGENCY_RATES.METHOD](APIConfig.EDIT_FACILITY_AGENCY_RATES.URL(facilityId, agencyId), payload);
}
const LinkAgencyToFacilityAPICall = (facilityId: any, agencyId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.LINK_AGENCY_FACILITY.METHOD](APIConfig.LINK_AGENCY_FACILITY.URL(facilityId, agencyId), payload);
};

const AddAgencyAPICall = (facilityId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_AGENCY.METHOD](APIConfig.ADD_AGENCY.URL(facilityId), payload);
};

const AgencyDetailsHCPRatesAPICall = (facilityId: any, agencyId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_AGENCY_RATE_WITH_FACILITY_ID.METHOD](APIConfig.GET_AGENCY_RATE_WITH_FACILITY_ID.URL(facilityId, agencyId), payload);
};

const EditHcpTypesRateAPICall = (facilityId: any, agencyId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_HCP_TYPES_RATE.METHOD](APIConfig.EDIT_HCP_TYPES_RATE.URL(facilityId, agencyId), payload);
};

const AgencyFacilityContractUploadAPICall = (facilityId: any, agencyId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.CONTRACT_UPLOAD_AGENCY_FACILITY.METHOD](APIConfig.CONTRACT_UPLOAD_AGENCY_FACILITY.URL(facilityId, agencyId), payload);
};
const EditAgencyAdminAPICall = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_AGENCY_ADMIN_DETAILS.METHOD](APIConfig.EDIT_AGENCY_ADMIN_DETAILS.URL(facilityId), payload);
};
const HCPContractUploadAPICall = (hcpId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.CONTRACT_UPLOAD_HCP.METHOD](APIConfig.CONTRACT_UPLOAD_HCP.URL(hcpId), payload);
};

const ActivateDeactivateAgency = (agency_admin_id: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_AGENCY.METHOD](APIConfig.TOGGLE_AGENCY.URL(agency_admin_id), payload);
};

const downloadAgencyDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_AGENCY_DATA_CSV.METHOD](APIConfig.DOWNLOAD_AGENCY_DATA_CSV.URL, payload);
};

const ActivateDeactivateAgencyAdmin = (agencyId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_AGENCY_ADMIN.METHOD](APIConfig.TOGGLE_AGENCY_ADMIN.URL(agencyId), payload);
};

const ActivateDeactivateAgencyFromList = (agency_id: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_LIST_AGENCY.METHOD](APIConfig.TOGGLE_LIST_AGENCY.URL(agency_id), payload);
};


const AgencyService = {
    AgencyDetailsAPICall,
    EditAgencyAPICall,
    LinkAgencyToFacilityAPICall,
    AddAgencyAPICall,
    AgencyDetailsHCPRatesAPICall,
    EditHcpTypesRateAPICall,
    AgencyFacilityContractUploadAPICall,
    AgencyLinkedFacilityDetails,
    EditFacilityAgencyRates,
    EditAgencyAdminAPICall,
    HCPContractUploadAPICall,
    ActivateDeactivateAgency,
    downloadAgencyDataCSVAPICall,
    ActivateDeactivateAgencyAdmin,
    ActivateDeactivateAgencyFromList
};

export default AgencyService;
