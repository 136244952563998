import {useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import OpenShiftDetailsComponent from "./open-shift-details/OpenShiftDetailsComponent";
import "./ShiftRequirementViewDetailsComponent.scss";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";

const ShiftRequirementViewDetailsComponent = () => {
    const {requirementId, shift_type} = useParams();
    const [shiftType] = useState<any>(shift_type);
    const [shiftRequirementDetails, setShiftRequirementDetails] = useState<any>(null);
    const [isShiftDetailsLoaded, setIsShiftDetailsLoaded] = useState<any>(false);
    const [isShiftDetailsLoading, setIsShiftDetailsLoading] = useState<any>(false);
    const [recall, setRecall] = useState<any>(false);

    const getShiftRequirementDetails = useCallback(() => {
        setIsShiftDetailsLoading(true);
        const payload = {};
        CommonService._shift_management.getShiftDetailsAPICall(requirementId, payload)
            .then((response: IAPIResponseType<any>) => {
                console.log(response.data);
                setShiftRequirementDetails(response.data);
                setIsShiftDetailsLoaded(true);
                setIsShiftDetailsLoading(false);
                setRecall(false);
            }).catch((error: any) => {
            setIsShiftDetailsLoaded(false);
            setIsShiftDetailsLoading(true);
        }).finally(() => {
            setIsShiftDetailsLoading(false);
        });

    }, [requirementId]);

    useEffect(() => {
        getShiftRequirementDetails();
    }, [getShiftRequirementDetails, recall]);

    return (
        <>
            <div className={"shift-requirement-details-screen"}>
                {
                    shiftType === "open" && <>
                        <OpenShiftDetailsComponent isShiftDetailsLoaded={isShiftDetailsLoaded}
                                                   isShiftDetailsLoading={isShiftDetailsLoading}
                                                   shiftRequirementDetails={shiftRequirementDetails}
                                                   recall={setRecall}
                        />
                    </>
                }
            </div>
        </>
    );
};

export default ShiftRequirementViewDetailsComponent;
