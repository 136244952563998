import {useLocation, useNavigate} from "react-router-dom";
import {useCallback, useState} from "react";
import {CommonService} from "../../../shared/services";
import AddHcpDetailsComponent from "./internal-add-hcp-details/AddHcpDetailsComponent";
import "./AddInternalHcpManagementComponent.scss";

const AddInternalHcpComponent = () => {
    const navigate = useNavigate();
    const [formTab, setFormTab] = useState<any>("Hcp");
    const location: any = useLocation();
    const path = location.pathname;


    const handleBack = useCallback(() => {
        if (path.includes('/hcp/add')) {
            navigate(CommonService._routeConfig.HcpManagementRoute());
        }
    }, [navigate, path]);


    const renderComponent = useCallback(() => {
        return <AddHcpDetailsComponent
            prev={handleBack}
            // propertyDetails={facilityDetails}
            //  mode={facilityDetails ? 'edit' : 'add'}
            formTab={formTab}
            setFormTab={setFormTab}
        />;
    }, [formTab, handleBack]);

    return (
        <>
            <div className={'add-internal-hcp-component'}>
                <>
                    {renderComponent()}
                </>
            </div>
        </>
    );
};
export default AddInternalHcpComponent;
