import {Outlet} from "react-router-dom";

interface UserManagementComponentProps {

}

const CustomerManagementComponent = (props: UserManagementComponentProps) => {

    return (
        <Outlet/>
    );

};

export default CustomerManagementComponent;
