import {ApiService} from "../index";
import {APIConfig} from "../../../constants";


//vars-health

const OnboardHcpAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ONBOARD_HCP.METHOD](APIConfig.ONBOARD_HCP.URL, payload);
};

const uploadHcpDocumentAPICall = (hcpId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.UPLOAD_HCP_DOCUMENT.METHOD](APIConfig.UPLOAD_HCP_DOCUMENT.URL(hcpId), payload);
};

const HcpDetailsAPICall = (agencyId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DETAILS.METHOD](APIConfig.HCP_DETAILS.URL(agencyId), payload);
};

const DeleteHcpDocumentAPICall = (hcpId: any, documentId: any, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DOCUMENT_DELETE.METHOD](APIConfig.HCP_DOCUMENT_DELETE.URL(hcpId, documentId), payload);
};

const DeleteDocumentAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.DOCUMENT_DELETE.METHOD](APIConfig.DOCUMENT_DELETE.URL, payload);
};
const HcpDocumentAPICall = (hcpId: string, payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DOCUMENTS.METHOD](APIConfig.HCP_DOCUMENTS.URL(hcpId), payload);
}
const DownloadHcpDocumentAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_DOCUMENT_DOWNLOAD.METHOD](APIConfig.HCP_DOCUMENT_DOWNLOAD.URL, payload);
};

const HcpListRequirementBasedAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.HCP_LIST_REQUIREMENT_BASED.METHOD](APIConfig.HCP_LIST_REQUIREMENT_BASED.URL, payload);
}

const EditHCPDetailsAPICall = (hcpId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_HCP_DETAILS.METHOD](APIConfig.EDIT_HCP_DETAILS.URL(hcpId), payload);
};

const downloadHCPDataCSVAPICall = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DOWNLOAD_HCP_DATA_CSV.METHOD](APIConfig.DOWNLOAD_HCP_DATA_CSV.URL, payload);
};

const addHcpEducation = (hcpId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_HCP_EDUCATION.METHOD](APIConfig.ADD_HCP_EDUCATION.URL(hcpId), payload);
}

const getHcpEducation = (hcpId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_HCP_EDUCATION.METHOD](APIConfig.GET_HCP_EDUCATION.URL(hcpId));
};
const editHcpEducation = (hcpId: any, educationId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_HCP_EDUCATION.METHOD](APIConfig.EDIT_HCP_EDUCATION.URL(hcpId, educationId), payload);
};

const deleteHcpEducation = (hcpId: any, educationId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DELETE_HCP_EDUCATION.METHOD](APIConfig.DELETE_HCP_EDUCATION.URL(hcpId, educationId));
};

const addHcpExperience = (hcpId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.ADD_HCP_EXPERIENCE.METHOD](APIConfig.ADD_HCP_EXPERIENCE.URL(hcpId), payload);
};
const editHcpExperience = (hcpId: any, experienceId: any, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EDIT_HCP_EXPERIENCE.METHOD](APIConfig.EDIT_HCP_EXPERIENCE.URL(hcpId, experienceId), payload);
};
const deleteHcpExperience = (hcpId: any, experienceId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.DELETE_HCP_EXPERIENCE.METHOD](APIConfig.DELETE_HCP_EXPERIENCE.URL(hcpId, experienceId));
};
const getHcpExperience = (hcpId: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GET_HCP_EXPERIENCE.METHOD](APIConfig.GET_HCP_EXPERIENCE.URL(hcpId));
};

const ActivateDeactivateHCP = (facilityId: string, payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.TOGGLE_HCP.METHOD](APIConfig.TOGGLE_HCP.URL(facilityId), payload);
};

const HcpManagementService = {
    OnboardHcpAPICall,
    uploadHcpDocumentAPICall,
    HcpDetailsAPICall,
    DeleteHcpDocumentAPICall,
    DownloadHcpDocumentAPICall,
    HcpListRequirementBasedAPICall,
    HcpDocumentAPICall,
    EditHCPDetailsAPICall,
    downloadHCPDataCSVAPICall,
    addHcpEducation,
    getHcpEducation,
    editHcpEducation,
    deleteHcpEducation,
    addHcpExperience,
    editHcpExperience,
    deleteHcpExperience,
    getHcpExperience,
    ActivateDeactivateHCP,
    DeleteDocumentAPICall
};


export default HcpManagementService;
