import "./PasswordInputComponent.scss";
import InputComponent from "../input/InputComponent";
import {useCallback, useState} from "react";
import {IPasswordFieldProps} from "../../../models/form-controls.model";
import ToolTipComponent from "../../tool-tip/ToolTipComponent";

export interface PasswordInputComponentProps extends IPasswordFieldProps {
    name: string;
    canToggle?: boolean;
    value?: string;
    errorMessage?: any;
    hasError?: boolean;
}

const PasswordInputComponent = (props: PasswordInputComponentProps) => {

    const {
        canToggle,
        ...otherProps
    } = props;

    const [inputType, setInputType] = useState<any>("password");

    const handleClickShowPassword = useCallback(() => {
        setInputType(inputType === "password" ? "text" : "password");
    }, [inputType]);

    return (
        <div className="password-component">
            <InputComponent
                type={inputType}
                {...otherProps}
            />
            {
                canToggle
                && <ToolTipComponent
                    tooltip={
                        inputType === "password" ? "Show Password" : "Hide Password"
                    }
                >
                <span
                    onClick={handleClickShowPassword}
                    className={"input-suffix-password-toggler"}
                >
                {inputType === "password" ?
                    // <ImageConfig.EyeIcon/>
                    "Show" : "Hide"
                    // : <ImageConfig.EyeOffIcon/>
                }
                </span>
                </ToolTipComponent>
            }
        </div>
    )
        ;

}

export default PasswordInputComponent;
