import React, {useCallback, useEffect, useState} from "react";
// import "./ApprovedShiftDetailsComponent.scss";
import "./HCPDetailsComponent.scss";
import {ImageConfig} from "../../../../constants";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {CommonService} from "../../../../shared/services";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import CardComponent from "../../../../shared/components/card/CardComponent";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../../shared/components/status-card/StatusCardComponent";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";
import LinkComponent from "../../../../shared/components/link/LinkComponent";


interface HCPDetailsComponentProps {
    setOpenDrawer: any;
    shiftHcpDetails: any;
}

const HCPDetailsComponent = (props: HCPDetailsComponentProps) => {
    const {setOpenDrawer, shiftHcpDetails} = props;
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [isHcpDetailsLoadingFailed, setIsHcpDetailsLoadingFailed] = useState<boolean>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>(null);


    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (shiftHcpDetails?.hcp_details?._id) {
            CommonService._hcp.HcpDetailsAPICall(shiftHcpDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [shiftHcpDetails?.hcp_details?._id]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (shiftHcpDetails?.hcp_details?._id) {
            CommonService._hcp.HcpDocumentAPICall(shiftHcpDetails?.hcp_details?._id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [shiftHcpDetails?.hcp_details?._id]);


    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    useEffect(() => {
        getHcpDocuments();
    }, [getHcpDocuments]);

    return (
        <>
            <div className={"day-month-open-shift-hcp-view-details"}>
                <div className={"approved-header"}>
                    <div className={'form-header-text'}>Staff Details</div>
                    <div className={'icon-button'} onClick={() => {
                        setOpenDrawer(false);
                    }}><ImageConfig.CrossIcon/></div>
                </div>
                <HorizontalLineComponent/>
                <div className={"non-header-container"}>
                    <div className={'hcp-details-wrapper'}>
                        <CardComponent size={"sm"} className={" mrg-top-10"}>
                            {
                                isHcpDetailsLoading && <div style={{minWidth: "400px"}}>
                                    <div style={{marginLeft: "45%"}}>
                                        <LoaderComponent type={'spinner'}/>
                                    </div>
                                </div>
                            }
                            {
                                isHcpDetailsLoadingFailed &&
                                <div className="h-v-center">
                                    <StatusCardComponent title={'Failed to Load'}/>
                                </div>
                            }
                            {
                                isHcpDetailsLoaded && <>

                                    <div className={'details-screen'}>
                                        <div className={"pdd-left-15 pdd-right-25 pdd-top-25"}>
                                            <div className={'ts-row'}>
                                                <div className={'mrg-left-10'}>
                                                    <div className={'d-flex'}>
                                                        <div className={'mrg-right-10'}>
                                                            {
                                                                hcpDetails?.gender === "male" ? <div>
                                                                    <ImageConfig.MaleIcon/>
                                                                </div> : <div>
                                                                    <ImageConfig.FemaleIcon/>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <div className={'HCPName'}>
                                                                {hcpDetails?.first_name + " " + hcpDetails?.last_name || '-'}
                                                            </div>
                                                            <div className={'HCPType'}
                                                                 style={{marginTop: "10px", marginBottom: "20px"}}>
                                                                {`${hcpDetails?.hcp_type_details?.title || '-'} | ${hcpDetails?.agency_details?.name || '-'}`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-4'}>
                                                    <DataLabelValueComponent label={'Gender'}>
                                                        {hcpDetails?.gender || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-4'}>
                                                    <DataLabelValueComponent label={'Staff Speciality'}>
                                                        {hcpDetails?.hcpSpeciality || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-4'}>
                                                    <DataLabelValueComponent label={'Total Shifts '}>
                                                        {hcpDetails?.hcp_shifts_count || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-12'}>
                                                    <DataLabelValueComponent label={'About the Staff'}>
                                                        {hcpDetails?.about || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                        </CardComponent>
                        {(hcpDocumentsList?.length > 0 && isHcpDetailsLoaded) &&
                            <CardComponent size={"sm"} className={"mrg-top-10"}>
                                <div className={'details-screen'}>
                                    <div className="details-screen-title-with-image-wrapper pdd-10">
                                        <div className={'details-screen-title-wrapper center-right-element'}>
                                            <div className={'details-screen-title'}>Documents
                                            </div>
                                        </div>
                                    </div>
                                    <HorizontalLineComponent/>
                                    {
                                        hcpDocumentsList?.map((document: any, index: any) => {
                                            let transformedName = "";
                                            //  CommonService.transformName(item?.attachment_type);
                                            if (document?.attachment_type === "tb_test") {
                                                transformedName = "TB Test";
                                                //  CPR BLS Card
                                            } else if (document?.attachment_type === "cpr_bls_card") {
                                                transformedName = "CPR BLS Card";
                                            } else {
                                                transformedName = CommonService.transformName(document?.attachment_type);
                                            }
                                            return (
                                                <>
                                                    <div className={'document-list-wrapper'}>
                                                        <div>
                                                            {transformedName}
                                                        </div>
                                                        <div>
                                                            <LinkComponent onClick={() => {
                                                                window.open(document?.url, '_blank');
                                                            }}>
                                                                <ImageConfig.ArrowRightOutlinedIcon color={"#4E535F"}/>
                                                            </LinkComponent>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </CardComponent>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HCPDetailsComponent;
