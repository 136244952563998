import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import {configureStore} from "@reduxjs/toolkit";
import rootSaga from "./sagas";
import {logger} from "redux-logger";
import {ENV} from "../constants";

const sagaMiddleware = createSagaMiddleware();
const middlewares: any = [sagaMiddleware];

if (ENV.ENABLE_REDUX_LOGS) {
    middlewares.push(logger);
}

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false}).concat(middlewares),
    devTools: true,
});

sagaMiddleware.run(rootSaga);
