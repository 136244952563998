import "./AddHcpTypeComponent.scss";
import {Misc} from "../../../../constants";
import {CommonService} from "../../../../shared/services";
import React, {useCallback, useState} from "react";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {getHcpTypesList} from "../../../../store/actions/meta.action";
import {useDispatch} from "react-redux";
import AddNewTypeComponent from "../../../../shared/components/new-type-creation/AddNewTypeComponent";


interface HcpTypesProps {
    setOpenDialog: any;
    setRateInnerNavigation: any;
    getSelectedAgencyDetails: any;
}


const AddHcpTypeComponent = (props: HcpTypesProps) => {
    const dispatch = useDispatch();
    const {setOpenDialog, getSelectedAgencyDetails, setRateInnerNavigation} = props;
    const [hcpTypeName,setHcpTypeName]=useState<any>(null);
    const [isHcpTypeLoading,setIsHcpTypeLoading]=useState<any>(false)

    const handleHcpTypeSubmit = useCallback(() => {
        setIsHcpTypeLoading(true)
        const payload: any = {};
        payload.name = hcpTypeName;
        CommonService._meta.addNewHcpTypeAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                setIsHcpTypeLoading(false)
                CommonService._staticData?.openDialogPopup(setOpenDialog, false);
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                dispatch(getHcpTypesList());
                getSelectedAgencyDetails();
                setRateInnerNavigation("hcp-rate-form");
                // @ts-ignore
            }).catch((error: any) => {
            CommonService._staticData?.openDialogPopup(setOpenDialog, false);
            CommonService._alert.showToast(error[Misc.API_ERROR_MESSAGE_KEY], "error");
        }).finally(() => {
            CommonService._staticData?.openDialogPopup(setOpenDialog, false);
            setRateInnerNavigation("hcp-rate-form");
            setIsHcpTypeLoading(false)
        });
    }, [dispatch, getSelectedAgencyDetails, setOpenDialog,setRateInnerNavigation,hcpTypeName]);

    return (
        <>
            <AddNewTypeComponent addType={"Staff type"} onSubmit={handleHcpTypeSubmit} setOpenDialog={setOpenDialog} setTypeName={setHcpTypeName} typeName={hcpTypeName} isLoading={isHcpTypeLoading}/>
        </>
    );
};
export default AddHcpTypeComponent;