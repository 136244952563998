import {ApiService} from "../index";
import {APIConfig} from "../../../constants";
import moment from "moment";

const today = moment();
const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const yesNoOptions = [{
    title: "Yes",
    code: true
}, {
    title: "No",
    code: false
}];

const resultOptions = [{
    title: "Positive",
    code: "Positive"
}, {
    title: "Negative",
    code: "Negative"
}];

const visitorTypeOptions = [
    {
        title: "Planned",
        code: "Planned"
    },
    {
        title: "Un Planned",
        code: "UnPlanned"
    }
];

const GenderList = [
    {
        title: "Male",
        code: "male"
    },
    {
        title: "Female",
        code: "female"
    },
    {
        title: "Others",
        code: "others"
    }
];


const unitsOfCare = [1, 2, 3, 4, 5, 6, 7, 8];

const getConsultationDurationList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.CONSULTATION_DURATION_LIST.METHOD](APIConfig.CONSULTATION_DURATION_LIST.URL, payload);
};

const getGenderList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.GENDER_LIST.METHOD](APIConfig.GENDER_LIST.URL, payload);
};

const getPhoneTypeList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.PHONE_TYPE_LIST.METHOD](APIConfig.PHONE_TYPE_LIST.URL, payload);
};

const getLanguageList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.LANGUAGE_LIST.METHOD](APIConfig.LANGUAGE_LIST.URL, payload);
};

const getEmployeeStatusList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.EMPLOYMENT_STATUS_LIST.METHOD](APIConfig.EMPLOYMENT_STATUS_LIST.URL, payload);
};

const getRelationshipStatusList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.RELATIONSHIP_LIST.METHOD](APIConfig.RELATIONSHIP_LIST.URL, payload);
};

const getUserRoleList = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.USER_ROLE_LIST.METHOD](APIConfig.USER_ROLE_LIST.URL, {ignoreAdminRole: true});
};

const getPollTypes = (payload: any) => {
    // @ts-ignore
    return ApiService[APIConfig.POLL_TYPES.METHOD](APIConfig.POLL_TYPES.URL, payload);
};


const FacilityListLiteAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.FACILITY_LIST_LITE.METHOD](APIConfig.FACILITY_LIST_LITE.URL, payload);
};

const AgencyListLiteAPICall = (payload:any) => {
    //@ts-ignore
    return ApiService[APIConfig.AGENCY_LIST_LITE.METHOD](APIConfig.AGENCY_LIST_LITE.URL,payload);
};

const facilityAgencyListLiteAPICall = (facilityId: any) => {
    console.log(facilityId);
    //@ts-ignore
    return ApiService[APIConfig.FACILITY_AGENCY_LIST_LITE.METHOD](APIConfig.FACILITY_AGENCY_LIST_LITE.URL(facilityId));
};

const staffTypeSpecialityListAPICall = (hcpTypeId: any) => {
    return ApiService[APIConfig.STAFF_SPECIALITY_LIST.METHOD](APIConfig.STAFF_SPECIALITY_LIST.URL(hcpTypeId));
}

const WingsListLiteAPICall = (payload: any) => {
    //@ts-ignore
    return ApiService[APIConfig.WINGS_LIST_LITE.METHOD](APIConfig.WINGS_LIST_LITE.URL, payload);
};

const RegionListAPICall = () => {
    //@ts-ignore
    return ApiService[APIConfig.REGION_LIST.METHOD](APIConfig.REGION_LIST.URL);
};

const openDialogPopup = (setState: any, isopen: boolean) => {
    return setState(isopen);
};

const openDrawerComponent = (setState: any, isopen: boolean) => {
    return setState(isopen);
};

const StaticDataService = {
    today,
    weekDays,
    yesNoOptions,
    resultOptions,
    unitsOfCare,
    visitorTypeOptions,
    getConsultationDurationList,
    getGenderList,
    getPhoneTypeList,
    getEmployeeStatusList,
    getLanguageList,
    getRelationshipStatusList,
    GenderList,
    getUserRoleList,
    getPollTypes,
    FacilityListLiteAPICall,
    AgencyListLiteAPICall,
    openDialogPopup,
    WingsListLiteAPICall,
    RegionListAPICall,
    openDrawerComponent,
    facilityAgencyListLiteAPICall,
    staffTypeSpecialityListAPICall
};
export default StaticDataService;
