export const GET_HCP_TYPES = "GET_HCP_TYPES";
export const SET_HCP_TYPES = "SET_HCP_TYPES";
export const GET_ADDITIONAL_RATES = "GET_ADDITIONAL_RATES";
export const SET_ADDITIONAL_RATES = "SET_ADDITIONAL_RATE";
export const GET_STAFF_SPECIALITY_LIST = 'GET_STAFF_SPECIALITY_LIST';
export const SET_STAFF_SPECIALITY_LIST = 'SET_STAFF_SPECIALITY_LIST'


export const getHcpTypesList = () => {
    return {type: GET_HCP_TYPES};
};

export const setHcpTypesList = (hcpTypesList: []) => {
    return {
        type: SET_HCP_TYPES, payload: {
            hcpTypesList
        }
    };
};

export const getAdditionalRates = () => {
    return {type: GET_ADDITIONAL_RATES};
};

export const setAdditionalRates = (additionalRatesList: []) => {
    return {
        type: SET_ADDITIONAL_RATES,
        payload: {
            additionalRatesList
        }
    };
};

export const getStaffSpecialityList = (staffTypeId: any) => {
    return {type: GET_STAFF_SPECIALITY_LIST, staffTypeId};
};

export const setStaffSpecialityList = (staffTypeSpecialityList: []) => {
    return {
        type: SET_STAFF_SPECIALITY_LIST, payload: {
            staffTypeSpecialityList
        }
    };
};