import "./HcpWorkExperienceTabComponent.scss";
import {ImageConfig, Misc} from "../../../../constants";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";
import {CommonService} from "../../../../shared/services";
import CardComponent from "../../../../shared/components/card/CardComponent";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import commonService from "../../../../shared/services/common.service";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";
import DrawerComponent from "../../../../shared/components/drawer/DrawerComponent";
import * as Yup from "yup";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import {DeleteOutlineIcon} from "../../../../constants/ImageConfig";
import MenuDropdownComponent from "../../../../shared/components/menu-dropdown/MenuDropdownComponent";
import FormikRadioButtonGroupComponent
    from "../../../../shared/components/form-controls/formik-radio-button-group/FormikRadioButtonGroupComponent";
import moment from "moment";

interface HcpWorkExperienceTabComponentProps {

}

interface workExperienceFormInitialValuesFormSchema {
    facility_name: string,
    position: string,
    location: string,
    still_working: boolean,
    start_date: string,
    end_date: string
}

const workExperienceValidationObjSchema = Yup.object({
    facility_name: Yup.string().required("Facility Name is required"),
    position: Yup.string().required("Position is required"),
    location: Yup.string().required("Location is required"),
    still_working: Yup.boolean(),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date().when('still_working', {
        is: false,
        then: Yup.date().required("End Date is required").test('is-greater', 'End Date must be greater than Start Date', function (end_date) {
            const {start_date} = this.parent;
            if (start_date && end_date) {
                return new Date(end_date) > new Date(start_date);
            }
            return true;
        }),
    }),
});

const workExperienceFormInitialValues: workExperienceFormInitialValuesFormSchema = {
    facility_name: "",
    position: "",
    location: "",
    still_working: false,
    start_date: "",
    end_date: ""
};

const HcpWorkExperienceTabComponent = (props: HcpWorkExperienceTabComponentProps) => {
    const [experienceFormValues, setExperienceFormValues] = React.useState<workExperienceFormInitialValuesFormSchema>(workExperienceFormInitialValues);
    const [hcpExperienceDetails, setHcpExperienceDetails] = React.useState<any[]>([]);
    const [selectedExperienceDetails, setSelectedExperienceDetails] = React.useState<any>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
    const {hcp_id} = useParams();
    const [isExperienceAddLoading, setIsExperienceAddLoading] = useState(false);

    const handleOpenDrawer = useCallback(() => {
        setIsDrawerOpen(true);
    }, []);

    const handleCloseDrawer = useCallback(() => {
        setIsDrawerOpen(false);
    }, []);

    const getExperienceDetails = useCallback(() => {
        if (hcp_id) {
            setIsLoading(true);
            commonService._hcp.getHcpExperience(hcp_id).then((response: IAPIResponseType<any>) => {
                setHcpExperienceDetails(response?.data);
            }).catch((error: any) => {
                console.log(error, "error");
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [hcp_id]);

    const submitExperienceDetails = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        setIsExperienceAddLoading(true);
        const payload = {
            ...values,
            start_date: CommonService.convertDateFormat(values.start_date, "YYYY-MM-DD"),
            end_date: !values.still_working ? CommonService.convertDateFormat(values.end_date, "YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
        };
        let apiCall: any = {};
        if (selectedExperienceDetails?._id) {
            apiCall = CommonService._hcp.editHcpExperience(hcp_id, selectedExperienceDetails._id, payload);
        } else {
            apiCall = CommonService._hcp.addHcpExperience(hcp_id, {experiences: [payload]});
        }
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            getExperienceDetails();
            handleCloseDrawer();
            setIsExperienceAddLoading(false);
        }).catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
            setIsExperienceAddLoading(false);
        }).finally(() => {
            setSubmitting(false);
        });
    }, [selectedExperienceDetails, getExperienceDetails, hcp_id, handleCloseDrawer]);

    const handleDeleteExperienceDetails = useCallback((experienceId: any) => {
        let apiCall: any = {};
        apiCall = CommonService._hcp.deleteHcpExperience(hcp_id, experienceId);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            getExperienceDetails();
        }).catch((error: any) => {
            CommonService.handleErrors(error[Misc.API_ERROR_MESSAGE_KEY], error);
            console.log(error, "error");
        }).finally(() => {
        });
    }, [getExperienceDetails, hcp_id]);

    useEffect(() => {
        if (selectedExperienceDetails) {
            const experienceDetailsToEdit = {
                facility_name: selectedExperienceDetails?.facility_name || "",
                position: selectedExperienceDetails?.position || "",
                location: selectedExperienceDetails?.location || "",
                still_working: selectedExperienceDetails?.still_working || false,
                start_date: selectedExperienceDetails?.start_date || "",
                end_date: selectedExperienceDetails?.end_date || "",
            };
            setExperienceFormValues(experienceDetailsToEdit);
        } else {
            setExperienceFormValues(workExperienceFormInitialValues);
        }
    }, [setSelectedExperienceDetails, selectedExperienceDetails]);

    useEffect(() => {
        getExperienceDetails();
    }, [getExperienceDetails]);

    return (
        <>
            <DrawerComponent
                isOpen={isDrawerOpen}
                onClose={handleCloseDrawer}
                className={"edit-drawer"}
            >
                <div className={'edit-facility-component'}>
                    <div className={'edit-facility-header'}>
                        <div
                            className={'form-header-text'}>{selectedExperienceDetails?._id ? "Edit Experience Details" : "Add Experience Details"}</div>
                        <div className={'icon-button'} onClick={handleCloseDrawer}><ImageConfig.CrossIcon/></div>
                    </div>
                    <div className="edit-facility-form-wrapper">
                        <Formik
                            validationSchema={workExperienceValidationObjSchema}
                            initialValues={experienceFormValues}
                            validateOnChange={true}
                            validateOnBlur={false}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={submitExperienceDetails}
                        >
                            {({values, errors, validateForm, isValid, setFieldValue, isSubmitting}) => {
                                return (
                                    <Form className={"t-form  form-holder scroll-content"} noValidate>
                                        <div className={'add-form-holder'}>
                                            <div className={'add-form-input-container'}>
                                                <div className="edit-component-content">
                                                    <Field name={`facility_name`}>
                                                        {
                                                            (field: FieldProps) => (
                                                                <FormikInputComponent
                                                                    label={'Facility Name'}
                                                                    placeholder={'Enter Facility Name'}
                                                                    type={"text"}
                                                                    required={true}
                                                                    formikField={field}
                                                                    fullWidth={true}
                                                                />
                                                            )
                                                        }
                                                    </Field>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={`location`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Location'}
                                                                            placeholder={'Enter Location'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <Field name={`position`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Position'}
                                                                            placeholder={'Enter Position'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field name={`still_working`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikRadioButtonGroupComponent
                                                                            formikField={field}
                                                                            label={"Still Working"}
                                                                            required={true}
                                                                            displayWith={(option: any) => option.label}
                                                                            options={[
                                                                                {
                                                                                    label: "Yes",
                                                                                    value: true,
                                                                                },
                                                                                {
                                                                                    label: "No",
                                                                                    value: false,
                                                                                },
                                                                            ]}
                                                                            valueExtractor={(option: any) => option.value}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6"></div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <LabelComponent title={"Start Date*"}/>
                                                            <Field name={`start_date`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikDatePickerComponent
                                                                            // minDate={new Date()}
                                                                            placeholder={'Select Date'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            {!values.still_working && (
                                                                <>
                                                                    <LabelComponent title={"End Date*"}/>
                                                                    <Field name={`end_date`}>
                                                                        {
                                                                            (field: FieldProps) => (
                                                                                <FormikDatePickerComponent
                                                                                    // minDate={new Date()}
                                                                                    placeholder={'Select Date'}
                                                                                    required={true}
                                                                                    formikField={field}
                                                                                    fullWidth={true}
                                                                                />
                                                                            )
                                                                        }
                                                                    </Field>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="facility-edit-save-button d-flex mrg-top-10">
                                            <ButtonComponent type={"submit"} isLoading={isExperienceAddLoading}>Save
                                                Details</ButtonComponent>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </DrawerComponent>
        <div className={'hcp-work-experience-tab-component'}>
            <CardComponent
                title={"Work Experience Details"}
                actions={
                    <>
                        <div
                            onClick={
                                () => {
                                    setSelectedExperienceDetails(null);
                                    handleOpenDrawer();
                                }
                            }
                            className={'icon-button'}>
                            <span
                                className={'center-element button-font-text'}><ImageConfig.AddOutlinedIcon
                                className={'mrg-right-10'}/>Add Experience Details
                            </span>
                        </div>
                    </>
                }
            >
                {
                    hcpExperienceDetails?.length > 0 && <div className="experience-card-wrapper card-wrapper">
                        {hcpExperienceDetails?.map((experience: any, index: number) => {
                            return <div
                                key={index}
                                className="experience-details-wrapper details-wrapper">
                                <div className="experience-header details-header">
                                    <div className="header-title">Work Experience Details {index + 1}</div>
                                    <div className="header-action">
                                        <div className="options-icon">
                                            <MenuDropdownComponent menuBase={<ImageConfig.OptionVerticalDotsIcon/>}
                                                                   menuOptions={[
                                                                       <MenuItem
                                                                           onClick={() => {
                                                                               setSelectedExperienceDetails(experience);
                                                                               handleOpenDrawer();
                                                                           }
                                                                           }
                                                                           color={"#FFF"} sx={{width: 120}}>
                                                                           <ListItemIcon sx={{marginLeft: "10px"}}>
                                                                               <ImageConfig.EditIcon/>
                                                                           </ListItemIcon>
                                                                           <ListItemText>Edit</ListItemText>
                                                                       </MenuItem>,
                                                                       <MenuItem
                                                                           onClick={() => {
                                                                               setSelectedExperienceDetails(experience);
                                                                               handleDeleteExperienceDetails(experience?._id);
                                                                           }}
                                                                           sx={{width: 120, color: "#F17F7F"}}>
                                                                           <ListItemIcon sx={{marginLeft: "10px"}}>
                                                                               <DeleteOutlineIcon color={"#F17F7F"}/>
                                                                           </ListItemIcon>
                                                                           <ListItemText>Delete</ListItemText>
                                                                       </MenuItem>
                                                                   ]}/>
                                        </div>
                                    </div>
                                </div>
                                <HorizontalLineComponent/>
                                <div className="experience-details-body details-body-content">
                                    <div className="ts-row">
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Facility Name">
                                                {experience?.facility_name || "-"}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Location">
                                                {experience?.location || "-"}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            <DataLabelValueComponent label={"Position"}>
                                                {experience?.position || "-"}
                                            </DataLabelValueComponent>
                                        </div>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Still Working">
                                                {experience?.still_working ? "Yes" : "No"}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Start Date">
                                                {experience?.start_date?.split('-')?.reverse()?.join('-')}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            {
                                                !experience.still_working && (
                                                    <DataLabelValueComponent label="End Date">
                                                        {experience?.end_date?.split('-')?.reverse()?.join('-')}
                                                    </DataLabelValueComponent>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                }
                {
                    hcpExperienceDetails?.length === 0 && !isLoading &&
                    <div className="no-data-found">
                        <div>
                            No Work Experience Details Found
                        </div>
                    </div>
                }
                {
                    isLoading && (
                        <div className="no-data-found">
                            <LoaderComponent type={'spinner'}/>
                        </div>
                    )
                }
            </CardComponent>
        </div>
        </>
    );

};

export default HcpWorkExperienceTabComponent;
