import "./SwitchComponent.scss";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import {useCallback} from "react";
import {ISwitchProps} from "../../../models/form-controls.model";
import {styled} from '@mui/material/styles';

interface SwitchComponentProps extends ISwitchProps {
    checked?: boolean;
    value?: any;
}

const SwitchComponent = (props: SwitchComponentProps) => {

    const {label, checked, name, className, disabled, id, onChange, required, value} = props;
    // const color = props.color || 'primary';
    const size = props.size || 'medium';
    const labelPlacement = props.labelPlacement || 'end';

    const handleSwitchChange = useCallback((event: any) => {
        const isSwitched = event.target.checked;
        if (onChange) {
            onChange(isSwitched);
        }
    }, [onChange]);


    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({theme}) => ({
        width: 34,
        height: 18,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#20AE64' : '#20AE64',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #FFFFFF',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? "#FFFFFF"
                        : "#FFFFFF",
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 14,
            height: 14,
        },
        '& .MuiSwitch-track': {
            borderRadius: 20 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#9EA2A8' : '#9EA2A8',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <FormControlLabel
            labelPlacement={labelPlacement}
            label={label}
            control={
                <IOSSwitch
                    className={className}
                    // style={checked ? {color: "#F9FAFB"} : {color: "#F9FAFB"}}
                    checked={checked}
                    disabled={disabled}
                    name={name}
                    id={id}
                    onChange={handleSwitchChange}
                    required={required}
                    value={value}
                    size={size}

                />}/>
    );

};

export default SwitchComponent;
