import {useLocation, useNavigate} from "react-router-dom";
import {useCallback,useState} from "react";
import "./AddCustomerComponent.scss";
import {CommonService} from "../../../shared/services";
import AddCustomerDetailsComponent from "./AddCustomerDetailsComponent";

interface AddUserComponentProps {

}

const AddCustomerComponent = (props: AddUserComponentProps) => {
    const navigate = useNavigate();
    const location: any = useLocation();
    const path = location.pathname;
    const [formTab,setFormTab]=useState<string>("Customer")

    const handleBack = useCallback(() => {
        if (path.includes('/user-management/add')) {
            navigate(CommonService._routeConfig.CustomerManagementRoute());
        }
    }, [navigate, path]);

    return (
        <>
            <div className={'add-user-component'}>
                <>
                    <AddCustomerDetailsComponent
                        prev={handleBack}
                        formTab={formTab}
                        setFormTab={setFormTab}
                    />
                </>
            </div>
        </>
    )
}

export default AddCustomerComponent
