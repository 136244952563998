import {useNavigate, useParams} from "react-router-dom";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CommonService} from "../../../shared/services";
import {IAPIResponseType} from "../../../shared/models/api.model";
import LoaderComponent from "../../../shared/components/loader/LoaderComponent";
import StatusCardComponent from "../../../shared/components/status-card/StatusCardComponent";
import CardComponent from "../../../shared/components/card/CardComponent";
// import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import {APIConfig, ImageConfig, Misc,} from "../../../constants";
import "./HcpDetailsComponent.scss";
import HorizontalLineComponent
    from "../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import DataLabelValueComponent from "../../../shared/components/data-label-value/DataLabelValueComponent";
import TabsWrapperComponent, {
    TabComponent,
    TabContentComponent,
    TabsComponent
} from "../../../shared/components/tabs/TabsComponent";
import DrawerComponent from "../../../shared/components/drawer/DrawerComponent";
import EditHcpBasicDetailsComponent from "./edit-hcp-basic-details/EditHcpBasicDetails";
import {HCP_MANAGEMENT_LIST} from "../../../constants/RoutesConfig";
import DetailsHeaderComponent from "../../../shared/components/details-header-component/DetailsHeaderComponent";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import FilePickerComponent from "../../../shared/components/file-picker/FilePickerComponent";
import ErrorComponent from "../../../shared/components/error/ErrorComponent";
import _ from "lodash";
import LabelComponent from "../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import ButtonComponent from "../../../shared/components/button/ButtonComponent";
import HcpDocumentTabComponent from "./hcp-document-tab/HcpDocumentTabComponent";
import HcpEducationDetailsTabComponent from "./hcp-education-details-tab/HcpEducationDetailsTabComponent";
import HcpWorkExperienceTabComponent from "./hcp-work-experience-tab/HcpWorkExperienceTabComponent";
import TableWrapperComponent from "../../../shared/components/table-wrapper/TableWrapperComponent";
import {ITableColumn} from "../../../shared/models/table.model";
import TextWithIconComponent from "../../../shared/components/text-with-icon/TextWithIconComponent";
import ToolTipComponent from "../../../shared/components/tool-tip/ToolTipComponent";
import {shiftTypeLayoutRepresentation} from "../../../data";

const contractValidationSchema = Yup.object({
        contract: Yup.mixed().required("Contract File is required"),
        contract_signed_on: Yup.mixed().required("Contract Date is required"),
    }
);

interface contractFormInitialValuesFormSchema {
    contract: any;
    contract_signed_on: any;
}

const contractFormInitialValues: contractFormInitialValuesFormSchema = {
    contract: "",
    contract_signed_on: "",
};
const HcpDetailsComponent = () => {
    const {hcp_id} = useParams();
    const [filterData, setFilterData] = useState<any>({
        search: '',
        // sort:{},
    });
    const [hcpFacilitShiftFilterData, setHcpFacilitShiftFilterData] = useState({});
    const [hcpDetails, setHcpDetails] = useState<any>(null);
    const [isHcpDetailsLoaded, setIsHcpDetailsLoaded] = useState<boolean>(false);
    const [isHcpDetailsLoading, setIsHcpDetailsLoading] = useState<boolean>(false);
    const [isHcpDetailsLoadingFailed, setIsHcpDetailsLoadingFailed] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<any>("basic-details");
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<any>(false);
    const [hcpDocumentsList, setHcpDocumentsList] = useState<any>([]);
    const navigate = useNavigate();
    const [openedDrawer, setOpenedDrawer] = useState<any>("");
    const [initialValuesContract] = useState<any>(contractFormInitialValues);
    const onFileUploadRef = React.useRef<any>(null);
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

    const getHcpDetails = useCallback(() => {
        setIsHcpDetailsLoading(true);
        const payload = {};
        if (hcp_id) {
            CommonService._hcp.HcpDetailsAPICall(hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    //  console.log(response.data);
                    setHcpDetails(response.data);
                    setIsHcpDetailsLoaded(true);
                    setIsHcpDetailsLoadingFailed(false);
                }).catch((error: any) => {
                setIsHcpDetailsLoaded(false);
                setIsHcpDetailsLoadingFailed(true);
            }).finally(() => {
                setIsHcpDetailsLoading(false);
            });
        }
    }, [hcp_id]);


    useEffect((): void => {
        getHcpDetails();
    }, [getHcpDetails]);

    const getHcpDocuments = useCallback(() => {
        const payload = {};
        if (hcp_id) {
            CommonService._hcp.HcpDocumentAPICall(hcp_id, payload)
                .then((response: IAPIResponseType<any>) => {
                    setHcpDocumentsList(response?.data);
                }).catch((error: any) => {
            }).finally(() => {
            });
        }
    }, [hcp_id]);

    useEffect((): void => {
        getHcpDocuments();
    }, [getHcpDocuments]);


    // const handleRedirect = () => {
    //     // Open the URL in a new browser window/tab
    //     window.open(`${hcpDetails?.location_url}`, '_blank');
    // };

    const deleteHcpDocument = useCallback((id: any, fileType: any) => {
        const payload: any = {};
        // fileType && (payload.file_type = fileType);
        // payload.file_key = hcpDocumentFileKey;
        CommonService._hcp.DeleteHcpDocumentAPICall(hcp_id, id, payload)
            .then((response: IAPIResponseType<any>) => {
                setFilterData({...filterData, page: 1});
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getHcpDetails();
                getHcpDocuments();
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To delete Document", "error");

        });
    }, [filterData, getHcpDetails, getHcpDocuments,hcp_id]);

    const downloadHcpDocument = useCallback((hcpDocumentFileKey: string) => {
        const payload: any = {};
        payload.file_key = hcpDocumentFileKey;
        CommonService._hcp.DownloadHcpDocumentAPICall(payload)
            .then((response: IAPIResponseType<any>) => {
                const link = document.createElement('a');
                link?.setAttribute('href', response?.data?.data);
                document.body.appendChild(link);
                link.click();
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            }).catch((error: any) => {
            CommonService._alert.showToast(error?.error || "Failed To download Document", "error");
        });
    }, []);


    const openEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(true);
    }, []);

    const closeEditDrawer = useCallback(() => {
        setIsEditDrawerOpen(false);
    }, []);

    const onFileUpload = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const formData = CommonService.getFormDataFromJSON(values);
        formData.append("contract_signed_on", CommonService.convertDateFormat(values?.contract_signed_on, "YYYY-MM-DD"));
        CommonService._agency.HCPContractUploadAPICall(hcp_id, formData)
            .then((response: IAPIResponseType<any>) => {
                CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
                getHcpDetails();
                closeEditDrawer();
            })
            .catch((error: any) => {
                CommonService.handleErrors(setErrors, error);
            }).finally(() => {
            setSubmitting(false);
        });
    }, [getHcpDetails, hcp_id, closeEditDrawer]);


    const onHandleDeleteDocument = useCallback((document: string, id?: string) => {
        CommonService.onConfirm(
            {
                confirmationTitle: `Delete ${document}`,
                image: ImageConfig.DeleteIcon,
                confirmationDescription: `Are you sure you want to delete the
    ${document}?`,
                no: {
                    color: "default",
                    text: "No, Go Back",
                    variant: "outlined"
                },
                activationType: true,
                yes: {
                    color: "error",
                    text: "Yes, Delete",
                    variant: "contained",
                }
            }
        ).then((result: any) => {
            if (id) {
                deleteHcpDocument(id, "document");
            } else {
                deleteHcpDocument(hcpDetails?.contract?.key, "contract");
            }
        });
    }, [hcpDetails, deleteHcpDocument]);

    useEffect(() => {
        setHcpFacilitShiftFilterData({
            hcp_id: hcp_id
        });
    }, [hcp_id]);

    const shiftTimingsColumn: ITableColumn[] = useMemo<ITableColumn[]>(() => {
        return [
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.FacilitySidebarIcon/>}>Facility
                    Name</TextWithIconComponent>,
                key: 'facility_name',
                dataIndex: 'name',
                fixed: 'left',
                // sortable: true,
                width: 180,
                render: (item: any) => {
                    return <div className={'facility-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'facility-name-tower-wrapper'}>
                            {
                                (item?.facility_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div style={{cursor: "pointer"}}
                                             className={"ellipses-for-table-data property-name"}>
                                            {item?.facility_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="facility-name">
                                        {item?.facility_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftDate/>}>Shift
                    Date</TextWithIconComponent>,
                key: 'shift_date',
                dataIndex: 'shift_date',
                //fixed: 'left',
                // sortable: true,
                width: 200,
                render: (item: any) => {
                    if (item?.shift_date) {
                        return <>{CommonService.convertDateFormat2(item?.shift_date, "MM-DD-YYYY")}</>;
                    } else {
                        return <>N/A</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftTime/>}>Shift
                    Time</TextWithIconComponent>,
                key: 'shift-time',
                dataIndex: 'shift-time',
                width: 200,
                render: (item: any) => {
                    if (item?.requirement_details?.shift_timings) {
                        return <>{CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.start_time) + "-" + CommonService.convertMinutesToTime(item?.requirement_details?.shift_timings?.end_time)}</>;
                    } else {
                        return <>{CommonService.convertMinutesToTime(item?.expected?.shift_start_time) + "-" + CommonService.convertMinutesToTime(item?.expected?.shift_end_time)}</>;
                    }
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.Wing/>}>Wing</TextWithIconComponent>,
                key: 'wing',
                dataIndex: 'wing',
                width: 200,
                align: 'left',
                // sortable: true,
                // render: (item: any) => {
                //     return <>{item?.fms?.map((item: any) => {
                //         return <>{item?.name}</>
                //     }) || "-"}</>
                // }
                render: (item: any) => {
                    return <div className={'hcp-details-wrapper'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}
                        <div className={'hcp-name-tower-wrapper'}>
                            {
                                (item?.facility_wing_details?.name)?.length > 20 ?
                                    <ToolTipComponent
                                        tooltip={item?.facility_wing_details?.name}
                                        showArrow={true}
                                        position={"top"}
                                    >
                                        <div className={"ellipses-for-table-data property-name"}>
                                            {item?.facility_wing_details?.name}
                                        </div>
                                    </ToolTipComponent> :
                                    <div className="hcp-name">
                                        {item?.facility_wing_details?.name || '-'}
                                    </div>
                            }
                        </div>
                    </div>;
                }
            },
            {
                title: () => <TextWithIconComponent prefix={<ImageConfig.ShiftType/>}>Shift
                    Type</TextWithIconComponent>,
                key: 'shift_type',
                dataIndex: 'shift_type',
                //  fixed: 'left',
                // sortable: true,
                width: 250,
                render: (item: any) => {
                    // console.log(item);
                    return <div className={'d-flex justify-content-center align-items-center'}>
                        {/*<AvatarComponent url={item?.profilePic?.url} size={'xs'}/>*/}

                        {
                            (() => {
                                const foundShiftType = shiftTypeLayoutRepresentation.find(shiftTypeObj => shiftTypeObj.code === item?.shift_type);
                                if (foundShiftType) {
                                    return <>
                                        <div
                                            className={'mrg-right-5 mrg-top-5'}>
                                            {foundShiftType.icon}
                                        </div>
                                        <div className={foundShiftType.color}>
                                            {item?.shift_type}
                                        </div>
                                    </>;
                                } else {
                                    return null; // or some default icon or message if shift type is not found
                                }
                            })()
                        }
                    </div>;
                }
            },

        ];
    }, []);

    return (
        <>
            {/*<DrawerComponent isOpen={isEditDrawerOpen} onClose={closeEditDrawer}>*/}
            {/*    <EditHcpBasicDetailsComponent hcpDetails={hcpDetails} closeEditDrawer={closeEditDrawer}*/}
            {/*                                  getFacilityDetails={getHcpDetails}/>*/}
            {/*</DrawerComponent>*/}
            <DrawerComponent
                isOpen={isEditDrawerOpen}
                onClose={closeEditDrawer}
                className={"edit-drawer"}
                // title={openedDrawer === "hcp-details" ? "Edit HCP Details" : "Upload Contract"}
                //showClose={true}
            >
                {
                    openedDrawer === "hcp-details" && (
                        <>
                            <EditHcpBasicDetailsComponent hcpDetails={hcpDetails} closeEditDrawer={closeEditDrawer}
                                                          getFacilityDetails={getHcpDetails}/>
                        </>
                    )
                }
                {
                    openedDrawer === "contract" && (
                        <>
                            <div className={'edit-hcp-header'}>
                                <div className={'form-header-text'}>Edit Staff Details</div>
                                <div className={'icon-button'} onClick={closeEditDrawer}><ImageConfig.CrossIcon/></div>
                            </div>
                            <HorizontalLineComponent/>
                            <div className="edit-contract-wrapper pdd-10">
                                <div className="edit-content">
                                    <Formik
                                        validationSchema={contractValidationSchema}
                                        initialValues={initialValuesContract}
                                        validateOnChange={false}
                                        validateOnBlur={true}
                                        enableReinitialize={true}
                                        validateOnMount={true}
                                        onSubmit={onFileUpload}
                                        innerRef={onFileUploadRef}
                                    >
                                        {({
                                              values,
                                              errors,
                                              validateForm,
                                              touched,
                                              isValid,
                                              setFieldValue,
                                              isSubmitting
                                          }) => {
                                            // eslint-disable-next-line react-hooks/rules-of-hooks
                                            useEffect(() => {
                                                validateForm();
                                                if (values.contract) {
                                                    setIsFileSelected(true);
                                                } else {
                                                    setIsFileSelected(false);
                                                }
                                            }, [validateForm, values]);
                                            return (
                                                <Form className="t-form" noValidate={true}>
                                                    <div
                                                        className="add-component-content">
                                                        {
                                                            (!values.contract) && <>
                                                                <FilePickerComponent maxFileCount={1}
                                                                                     height={'470px'}
                                                                                     id={"sc_upload_btn"}
                                                                                     onFilesDrop={(acceptedFiles, rejectedFiles) => {
                                                                                         if (acceptedFiles && acceptedFiles.length > 0) {
                                                                                             const file = acceptedFiles[0];
                                                                                             setFieldValue('contract', file);
                                                                                         }
                                                                                     }}
                                                                                     acceptedFileTypes={["pdf"]}
                                                                                     uploadDescription={"(upload only one file)"}
                                                                                     acceptedFilesText={"Only .pdf files are supported"}
                                                                />
                                                                {
                                                                    (_.get(touched, "contract") && !!(_.get(errors, "contract"))) &&
                                                                    <ErrorComponent
                                                                        errorText={(_.get(errors, "contract"))}/>
                                                                }
                                                            </>
                                                        }
                                                        <>
                                                            {
                                                                (values.contract) && <>
                                                                    <div className="file-preview-wrapper">
                                                                        <div className="file-name">
                                                                            {values.contract.name}
                                                                        </div>
                                                                        <div className="action-button">
                                                                            <div
                                                                                className={'center-element mrg-left-25 contract-action-text icon-button delete-button'}
                                                                                onClick={() => {
                                                                                    setFieldValue('contract', '');
                                                                                }}
                                                                            >
                                                                                <ImageConfig.DeleteOutlineIcon/><span
                                                                                className={'mrg-left-10'}>Delete</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <HorizontalLineComponent/>
                                                                    <div className={'d-flex'}>
                                                                        <div className={'flex-1'}>
                                                                            <LabelComponent title={'Contract Signed On'}/>
                                                                            <Field name={'contract_signed_on'}>
                                                                                {
                                                                                    (field: FieldProps) => (
                                                                                        <FormikDatePickerComponent
                                                                                            // minDate={new Date()}
                                                                                            placeholder={'Select Date'}
                                                                                            required={true}
                                                                                            formikField={field}
                                                                                            fullWidth={true}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                                <div className="action-button contract-save-button">
                                    {
                                        isFileSelected && (
                                            <>
                                                {/* eslint-disable-next-line react/jsx-no-undef */}
                                                <ButtonComponent
                                                    className={"save-btn"}
                                                    onClick={() => {
                                                        if (onFileUploadRef.current) {
                                                            onFileUploadRef.current.submitForm();
                                                        }
                                                    }}
                                                >
                                                    Save Details
                                                </ButtonComponent>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    )
                }
            </DrawerComponent>
            <div className={'hcp-details-component-screen'}>
                {
                    isHcpDetailsLoading && <div className="h-v-center">
                        <LoaderComponent type={'spinner'}/>
                    </div>
                }
                {
                    isHcpDetailsLoadingFailed &&
                    <div className="h-v-center">
                        <StatusCardComponent title={'Failed to Load'}/>
                    </div>
                }
                {
                    isHcpDetailsLoaded &&
                    <>
                        <DetailsHeaderComponent navigate={navigate} details={hcpDetails}
                                                backAddress={HCP_MANAGEMENT_LIST}
                                                detailsModule={'Hcp'}/>
                        <TabsWrapperComponent className={'mrg-top-25'}>
                            <TabsComponent
                                value={activeTab}
                                allowScrollButtonsMobile={false}
                                onUpdate={(e: any, v: any) => {
                                    setActiveTab(v);
                                }}
                            >
                                <TabComponent label="Staff Details" value={"basic-details"}/>
                                <TabComponent label={"Shift Attendance"} value={"shift-attendance"}/>
                                <TabComponent label={"Education"} value={"education"}/>
                                <TabComponent label={"Work Experience"} value={"work-experience"}/>
                                <TabComponent label="Documents" value={"hcp-document"}/>
                            </TabsComponent>
                            <TabContentComponent className={'mrg-left-100 mrg-right-100 mrg-top-20'} selectedTab={activeTab}
                                                 value={"basic-details"}>
                                <CardComponent size={"sm"}
                                               title={"Staff Details"}
                                               actions={
                                                   <div
                                                       onClick={
                                                           () => {
                                                               openEditDrawer();
                                                               setOpenedDrawer("hcp-details");
                                                           }
                                                       }
                                                       className={'icon-button'}>
                                                        <span
                                                            className={'center-element button-font-text'}><ImageConfig.EditIcon
                                                            className={'mrg-right-10'}/>Edit Staff Details
                                                        </span>
                                                   </div>
                                               }
                                >
                                    <div className={'details-screen'}>
                                        <div className={"pdd-left-25 pdd-right-25 pdd-top-25"}>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}
                                                     style={{
                                                         display: "flex",
                                                         justifyContent: "flex-start",
                                                         columnGap: "14px",
                                                         marginBottom: "16px",
                                                         alignItems: "center"
                                                     }}>
                                                    <div style={{
                                                        height: "56px",
                                                        width: "56px",
                                                        borderRadius: "50px",
                                                    }}>
                                                        {hcpDocumentsList && Array.isArray(hcpDocumentsList) && hcpDocumentsList.length > 0 ? (
                                                                (() => {
                                                                    const profilePictures = hcpDocumentsList.filter((attachment) => attachment.attachment_type === "profile_picture");
                                                                    return profilePictures.length > 0 ? (
                                                                        profilePictures.map((profilePic, index) => (
                                                                            <img
                                                                                key={index}
                                                                                src={profilePic.url}
                                                                                alt="profile pic"
                                                                                style={{
                                                                                    height: "56px",
                                                                                    width: "56px",
                                                                                    borderRadius: "50px",
                                                                                }}
                                                                            />
                                                                        ))
                                                                    ) : (
                                                                        hcpDetails?.gender === "male" ? (
                                                                            <ImageConfig.MaleIcon height={56} width={56}/>
                                                                        ) : (
                                                                            <ImageConfig.FemaleIcon height={56} width={56}/>
                                                                        ));
                                                                })()
                                                            ) :
                                                            (
                                                                hcpDetails?.gender === "male" ? (
                                                                    <ImageConfig.MaleIcon height={56} width={56}/>
                                                                ) : (
                                                                    <ImageConfig.FemaleIcon height={56} width={56}/>
                                                                ))
                                                        }


                                                    </div>

                                                    <div>
                                                        <div className={'HCPName'}>
                                                            {hcpDetails?.first_name + " " + hcpDetails?.last_name || '-'}
                                                        </div>
                                                        <div className={'HCPType'}>
                                                            {hcpDetails?.hcp_type_details?.title || '-'}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <HorizontalLineComponent className={'mrg-bottom-20'}/>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Phone Number'}>
                                                        {hcpDetails?.contact_number || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Email ID'}>
                                                        {hcpDetails?.email || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Gender'}>
                                                        {hcpDetails?.gender || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Address'}>
                                                        {`${hcpDetails?.address?.street}, ${hcpDetails?.address?.city}, ${hcpDetails?.address?.state}, ${hcpDetails?.address?.zip_code}, ${hcpDetails?.address?.region}, ${hcpDetails?.address?.country}` || '-'}
                                                    </DataLabelValueComponent>
                                                </div>


                                            </div>
                                            <div className={'ts-row'}>

                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Timezone'}>
                                                        {hcpDetails?.timezone || '-'}
                                                        {CommonService.getTimezoneTitleByCode(hcpDetails?.timezone) || '-'}
                                                    </DataLabelValueComponent>
                                                </div>

                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Region'}>
                                                        {
                                                            hcpDetails?.address?.region
                                                        }
                                                    </DataLabelValueComponent>
                                                </div>
                                                <div className={'ts-col-lg-3'}>
                                                    <DataLabelValueComponent label={'Staff ID'}>
                                                        {
                                                            hcpDetails?.hcp_uniqueId || "-"
                                                        }
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>
                                            <div className={'ts-row'}>
                                                <div className={'ts-col-lg-6'}>
                                                    <DataLabelValueComponent label={'About the Staff'}>
                                                        {hcpDetails?.about || '-'}
                                                    </DataLabelValueComponent>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                            <TabContentComponent className={'mrg-left-100 mrg-right-100  mrg-top-20'} selectedTab={activeTab}
                                                 value={"shift-attendance"}>
                                <CardComponent size={"sm"}
                                >
                                    <div className={'details-screen'}>
                                        <div className={""}>
                                            <TableWrapperComponent url={APIConfig.FACILITY_HCP_SHIFTS.URL}
                                                                   method={APIConfig.FACILITY_HCP_SHIFTS.METHOD}
                                                                   isPaginated={true}
                                                                   extraPayload={hcpFacilitShiftFilterData}
                                                //    noDataText={filterData.search ? 'Oops! It seems like there are no Facilities Available for the name you have searched.' : 'No Data'}
                                                                   columns={shiftTimingsColumn}
                                            />
                                        </div>
                                    </div>
                                </CardComponent>
                            </TabContentComponent>
                            <TabContentComponent className={"tab-content"} value={"education"} selectedTab={activeTab}>
                                <div
                                    className={' mrg-top-10 mrg-left-100 mrg-right-100  mrg-top-20'}>
                                    <HcpEducationDetailsTabComponent/>
                                </div>
                            </TabContentComponent>
                            <TabContentComponent className={"tab-content"} value={"work-experience"}
                                                 selectedTab={activeTab}>
                                <div
                                    className={' mrg-top-10 mrg-left-100 mrg-right-100  mrg-top-20'}>
                                    <HcpWorkExperienceTabComponent/>
                                </div>
                            </TabContentComponent>
                            <TabContentComponent selectedTab={activeTab} value={"hcp-document"}>
                                <div
                                    className={' mrg-top-20 mrg-left-100 mrg-right-100'}>
                                    <HcpDocumentTabComponent
                                        onDownload={downloadHcpDocument}
                                        onDelete={onHandleDeleteDocument}
                                        documentList={hcpDocumentsList}
                                        getDocumentLists={getHcpDocuments}
                                    />
                                </div>

                            </TabContentComponent>
                        </TabsWrapperComponent>
                    </>
                }

            </div>

        </>
    );
};
export default HcpDetailsComponent;
