import "./HcpEducationDetailsTabComponent.scss";
import DataLabelValueComponent from "../../../../shared/components/data-label-value/DataLabelValueComponent";
import React, {useCallback, useEffect, useState} from "react";
import CardComponent from "../../../../shared/components/card/CardComponent";
import {ImageConfig, Misc} from "../../../../constants";
import HorizontalLineComponent
    from "../../../../shared/components/horizontal-line/horizontal-line/HorizontalLineComponent";
import {useParams} from "react-router-dom";
import commonService from "../../../../shared/services/common.service";
import {IAPIResponseType} from "../../../../shared/models/api.model";
import {CommonService} from "../../../../shared/services";
import LoaderComponent from "../../../../shared/components/loader/LoaderComponent";
import MenuDropdownComponent from "../../../../shared/components/menu-dropdown/MenuDropdownComponent";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import * as Yup from "yup";
import {Field, FieldProps, Form, Formik, FormikHelpers} from "formik";
import DrawerComponent from "../../../../shared/components/drawer/DrawerComponent";
import FormikInputComponent from "../../../../shared/components/form-controls/formik-input/FormikInputComponent";
import LabelComponent from "../../../../shared/components/label/LabelComponent";
import FormikDatePickerComponent
    from "../../../../shared/components/form-controls/formik-date-picker/FormikDatePickerComponent";
import ButtonComponent from "../../../../shared/components/button/ButtonComponent";

interface HcpEducationDetailsTabComponentProps {

}

const educationValidationObjSchema = Yup.object({
    degree: Yup.string().required("Degree is required"),
    institute_name: Yup.string().required("Institute Name is required"),
    start_date: Yup.date().required("Start Date is required"),
    end_date: Yup.date()
        .required("End Date is required")
        .test('is-greater', 'End Date must be greater than Start Date', function (end_date) {
            const {start_date} = this.parent;
            if (start_date && end_date) {
                return new Date(end_date) > new Date(start_date);
            }
            return true;
        }),
    location: Yup.string().required("Location is required"),
});

interface educationFormInitialValuesFormSchema {
    degree: string;
    institute_name: string;
    start_date: any;
    end_date: any;
    location: any;
}

const educationFormInitialValues: educationFormInitialValuesFormSchema = {
    degree: "",
    institute_name: "",
    start_date: "",
    end_date: "",
    location: "",
};

const HcpEducationDetailsTabComponent = (props: HcpEducationDetailsTabComponentProps) => {
    const [educationFormValues, setEducationFormValues] = React.useState<educationFormInitialValuesFormSchema>(educationFormInitialValues);
    const [hcpEducationDetails, setHcpEducationDetails] = React.useState<any[]>([]);
    const [selectedEducationDetails, setSelectedEducationDetails] = React.useState<any>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
    const {hcp_id} = useParams();
    const [isEductionAddLoading, setIsEducationLoading] = useState(false);

    const handleOpenDrawer = useCallback(() => {
        setIsDrawerOpen(true);
    }, []);

    const handleCloseDrawer = useCallback(() => {
        setIsDrawerOpen(false);
    }, []);


    const getEducationDetails = useCallback(() => {
        if (hcp_id) {
            setIsLoading(true);
            commonService._hcp.getHcpEducation(hcp_id).then((response: IAPIResponseType<any>) => {
                setHcpEducationDetails(response?.data);
            }).catch((error: any) => {
                console.log(error, "error");
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [hcp_id]);

    const submitEducationDetails = useCallback((values: any, {setErrors, setSubmitting}: FormikHelpers<any>) => {
        setSubmitting(true);
        const payload = {
            ...values,
            start_date: CommonService.convertDateFormat(values.start_date, "YYYY-MM-DD"),
            end_date: CommonService.convertDateFormat(values.end_date, "YYYY-MM-DD")
        };
        let apiCall: any = {};
        if (selectedEducationDetails?._id) {
            setIsEducationLoading(true);
            apiCall = CommonService._hcp.editHcpEducation(hcp_id, selectedEducationDetails._id, payload);
        } else {
            setIsEducationLoading(true);
            apiCall = CommonService._hcp.addHcpEducation(hcp_id, {educations: [payload]});
        }
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            getEducationDetails();
            handleCloseDrawer();
            setIsEducationLoading(false);
        }).catch((error: any) => {
            CommonService.handleErrors(setErrors, error);
            setIsEducationLoading(false);
        }).finally(() => {
            setSubmitting(false);
        });
    }, [selectedEducationDetails, getEducationDetails, hcp_id, handleCloseDrawer]);

    const handleDeleteEducationDetails = useCallback((educationId: any) => {
        let apiCall: any = {};
        apiCall = CommonService._hcp.deleteHcpEducation(hcp_id, educationId);
        apiCall.then((response: IAPIResponseType<any>) => {
            CommonService._alert.showToast(response[Misc.API_RESPONSE_MESSAGE_KEY], "success");
            getEducationDetails();
        }).catch((error: any) => {
            CommonService.handleErrors(error[Misc.API_ERROR_MESSAGE_KEY], error);
            console.log(error, "error");
        }).finally(() => {
        });
    }, [hcp_id, getEducationDetails]);

    useEffect(() => {
        if (selectedEducationDetails) {
            const educationDetailsToEdit = {
                degree: selectedEducationDetails?.degree || "",
                institute_name: selectedEducationDetails?.institute_name || "",
                start_date: selectedEducationDetails?.start_date || "",
                end_date: selectedEducationDetails?.end_date || "",
                location: selectedEducationDetails?.location || "",
            };
            setEducationFormValues(educationDetailsToEdit);
        } else {
            setEducationFormValues(educationFormInitialValues);
        }
    }, [setSelectedEducationDetails, selectedEducationDetails]);

    useEffect(() => {
        getEducationDetails();
    }, [getEducationDetails]);

    return (
        <>
            <DrawerComponent
                isOpen={isDrawerOpen}
                onClose={handleCloseDrawer}
                // title={selectedEducationDetails?._id ? "Edit Education Details" : "Add Education Details"}
                // className={"edit-drawer"}
                className={"edit-drawer"}

            >
                <div className={'edit-facility-component'}>
                    <div className={'edit-facility-header'}>
                        <div
                            className={'form-header-text'}>{selectedEducationDetails?._id ? "Edit Education Details" : "Add Education Details"}</div>
                        <div className={'icon-button'} onClick={handleCloseDrawer}><ImageConfig.CrossIcon/></div>
                    </div>
                    <div className={'edit-facility-form-wrapper'}>
                        <Formik
                            validationSchema={educationValidationObjSchema}
                            initialValues={educationFormValues}
                            validateOnChange={false}
                            validateOnBlur={true}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={submitEducationDetails}
                        >
                            {({values, errors, validateForm, touched, isValid, setFieldValue, isSubmitting}) => {
                                // eslint-disable-next-line react-hooks/rules-of-hooks
                                useEffect(() => {
                                    validateForm();
                                }, [validateForm, values]);
                                return (
                                    <Form className={"t-form form-holder scroll-content"} noValidate>
                                        {/*<FormDebuggerComponent values={values} errors={errors} showDebugger={true}/>*/}
                                        <div className={'add-form-holder'}>
                                            <div className={'add-form-input-container'}>
                                                <div className="edit-component-content">
                                                    <Field name={"degree"} label={"Degree"}>
                                                        {
                                                            (field: FieldProps) => {
                                                                return <FormikInputComponent
                                                                    formikField={field}
                                                                    label={"Degree"}
                                                                    type={"text"}
                                                                    required={true}
                                                                    fullWidth={true}/>;
                                                            }
                                                        }
                                                    </Field>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <Field
                                                                name={`institute_name`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Institution Name'}
                                                                            placeholder={'Ex. Caltech'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <Field
                                                                name={`location`}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikInputComponent
                                                                            label={'Location'}
                                                                            placeholder={'Enter Location'}
                                                                            type={"text"}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="ts-row">
                                                        <div className="ts-col-6">
                                                            <LabelComponent title={'Start Date*'}/>
                                                            <Field
                                                                name={"start_date"}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikDatePickerComponent
                                                                            // minDate={new Date()}
                                                                            placeholder={'Select Date'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                        <div className="ts-col-6">
                                                            <LabelComponent title={'End Date*'}/>
                                                            <Field name={'end_date'}>
                                                                {
                                                                    (field: FieldProps) => (
                                                                        <FormikDatePickerComponent
                                                                            // minDate={new Date()}
                                                                            placeholder={'Select Date'}
                                                                            required={true}
                                                                            formikField={field}
                                                                            fullWidth={true}
                                                                        />
                                                                    )
                                                                }
                                                            </Field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="facility-edit-save-button d-flex mrg-top-10">
                                            <ButtonComponent type={"submit"} isLoading={isEductionAddLoading}>Save
                                                Details</ButtonComponent>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </DrawerComponent>
        <div className={'hcp-education-details-tab-component'}>
            <CardComponent
                title={"Education Details"}
                actions={
                    <>
                        <div
                            onClick={
                                () => {
                                    setSelectedEducationDetails(null);
                                    handleOpenDrawer();
                                }
                            }
                            className={'icon-button'}>
                            <span
                                className={'center-element button-font-text'}><ImageConfig.AddOutlinedIcon
                                className={'mrg-right-10'}/>Add Education Details
                            </span>
                        </div>
                    </>
                }
            >
                {
                    hcpEducationDetails?.length > 0 && <div className="education-card-wrapper card-wrapper">
                        {hcpEducationDetails?.map((education: any, index: number) => {
                            return <div
                                key={index}
                                className="education-details-wrapper details-wrapper">
                                <div className="education-header details-header">
                                    <div className="header-title">Education Details {index + 1}</div>
                                    <div className="header-action">
                                        <div className="options-icon">
                                            <MenuDropdownComponent menuBase={<ImageConfig.OptionVerticalDotsIcon/>}
                                                                   menuOptions={[
                                                                       <MenuItem
                                                                           onClick={() => {
                                                                               setSelectedEducationDetails(education);
                                                                               handleOpenDrawer();
                                                                           }
                                                                           }
                                                                           color={"#FFF"} sx={{width: 120}}>
                                                                           <ListItemIcon sx={{marginLeft: "10px"}}>
                                                                               <ImageConfig.EditIcon/>
                                                                           </ListItemIcon>
                                                                           <ListItemText>Edit</ListItemText>
                                                                       </MenuItem>,
                                                                       <MenuItem
                                                                           onClick={() => {
                                                                               //  setSelectedEducationDetails(education);
                                                                               handleDeleteEducationDetails(education?._id);
                                                                           }}
                                                                           sx={{width: 120, color: "#F17F7F"}}>
                                                                           <ListItemIcon sx={{marginLeft: "10px"}}>
                                                                               <ImageConfig.DeleteOutlineIcon
                                                                                   color={"#F17F7F"}/>
                                                                           </ListItemIcon>
                                                                           <ListItemText>Delete</ListItemText>
                                                                       </MenuItem>
                                                                   ]}/>
                                        </div>
                                    </div>
                                </div>
                                <HorizontalLineComponent/>
                                <div className="education-details-body details-body-content">
                                    <div className="ts-row">
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Institute Name">
                                                {education?.institute_name || "-"}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Location">
                                                {education?.location || "-"}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            <DataLabelValueComponent label={"Degree"}>
                                                {education?.degree || "-"}
                                            </DataLabelValueComponent>
                                        </div>
                                    </div>
                                    <div className="ts-row">
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="Start Date">
                                                {education?.start_date?.split('-')?.reverse()?.join('-')}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col">
                                            <DataLabelValueComponent label="End Date">
                                                {education?.end_date?.split('-')?.reverse()?.join('-')}
                                            </DataLabelValueComponent>
                                        </div>
                                        <div className="ts-col"></div>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                }
                {
                    hcpEducationDetails?.length === 0 && !isLoading &&
                    <div className="no-data-found">
                        <div>
                            No Education Details Found
                        </div>
                    </div>
                }
                {
                    isLoading && (
                        <div className="no-data-found">
                            <LoaderComponent type={'spinner'}/>
                        </div>
                    )
                }
            </CardComponent>
        </div>
        </>
    );

};

export default HcpEducationDetailsTabComponent;
