import {IActionModel} from "../../shared/models/action.model";
import {
    GET_ADDITIONAL_RATES,
    GET_HCP_TYPES,
    GET_STAFF_SPECIALITY_LIST,
    SET_ADDITIONAL_RATES,
    SET_HCP_TYPES, SET_STAFF_SPECIALITY_LIST
} from "../actions/meta.action";


export interface IMetaDataReducerState {
    isHcpTypesListLoading: boolean,
    isHcpTypesListLoaded: boolean,
    HcpTypesList: any[],

    isAdditionalRatesListLoading: boolean,
    isAdditionalRatesListLoaded: boolean,
    AdditionalRatesList: any[],

    isStaffSpecialityListLoading: boolean,
    isStaffSpecialityListLoaded: boolean,
    staffSpecialityList: any[],
}

const initialData: IMetaDataReducerState = {
    isHcpTypesListLoading: false,
    isHcpTypesListLoaded: false,
    HcpTypesList: [],

    isAdditionalRatesListLoading: false,
    isAdditionalRatesListLoaded: false,
    AdditionalRatesList: [],

    isStaffSpecialityListLoading: false,
    isStaffSpecialityListLoaded: false,
    staffSpecialityList: [],
};


const MetaDataReducer = (state = initialData, action: IActionModel): IMetaDataReducerState => {
    switch (action.type) {
        //vars -health
        case GET_HCP_TYPES:
            state = {
                ...state,
                isHcpTypesListLoaded: false,
                isHcpTypesListLoading: true
            };
            return state;

        case SET_HCP_TYPES:
            state = {
                ...state,
                isHcpTypesListLoaded: true,
                isHcpTypesListLoading: false,
                HcpTypesList: action.payload.hcpTypesList
            };
            return state;

        case GET_ADDITIONAL_RATES:
            state = {
                ...state,
                isAdditionalRatesListLoaded: false,
                isAdditionalRatesListLoading: true,
            };
            return state;
        case SET_ADDITIONAL_RATES:
            state = {
                ...state,
                isAdditionalRatesListLoading: false,
                isAdditionalRatesListLoaded: true,
                AdditionalRatesList: action.payload.additionalRatesList
            };
            return state;

        case GET_STAFF_SPECIALITY_LIST:
            state = {
                ...state,
                isStaffSpecialityListLoaded: false,
                isStaffSpecialityListLoading: true
            };
            return state;

        case SET_STAFF_SPECIALITY_LIST:
            state = {
                ...state,
                isStaffSpecialityListLoaded: true,
                isStaffSpecialityListLoading: false,
                staffSpecialityList: action.payload.staffTypeSpecialityList
            };
            return state;

        default:
            return state;
    }
};

export default MetaDataReducer;